import React from 'react'
import styles from '../styles/home.module.scss'
import Text from 'Components/Text'
import assets from 'Assets'
import { Link } from 'react-router-dom'

const SecondCard = () => {
    return (
        <div className={`${styles.secondCard} ${styles.flex_div}`}>
            <Link to={'/campaigns'} className={`${styles.card} h-[250px] ${styles.cardOne}`}>
                <img src={assets.images.megaphone} className={styles.icon} alt="" />
                <Text className={styles.text}>
                    Watch Ads. Earn <br /> rewards
                </Text>
            </Link>
            <div className={`${styles.card} h-[250px] ${styles.cardTwo}`}>
                <img src={assets.images.gamepad} className={styles.icon} alt="" />
                <Text className={styles.text}>
                    Play Quiz Game.Earn <br />Bonuses
                </Text>
            </div>
        </div>
    )
}

export default SecondCard