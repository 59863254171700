// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal_modal__2S6Ci {
  position: fixed !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
  z-index: 10000;
  overflow: auto;
  min-width: 400px;
  padding: 20px;
}
@media screen and (max-width: 576px) {
  .modal_modal__2S6Ci {
    padding: 12px;
    width: 90%;
  }
}`, "",{"version":3,"sources":["webpack://./src/Components/Modal/modal.module.scss"],"names":[],"mappings":"AAAA;EAEI,0BAAA;EACA,mBAAA;EACA,oBAAA;EACA,gCAAA;EACA,cAAA;EACA,cAAA;EACA,gBAAA;EACA,aAAA;AAAJ;AACI;EAVJ;IAWQ,aAAA;IACA,UAAA;EAEN;AACF","sourcesContent":[".modal {\n\n    position: fixed !important;\n    top: 50% !important;\n    left: 50% !important;\n    transform: translate(-50%, -50%);\n    z-index: 10000;\n    overflow: auto;\n    min-width: 400px;\n    padding: 20px;\n    @media screen and (max-width:576px) {\n        padding: 12px;\n        width: 90%;\n        \n    }\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": `modal_modal__2S6Ci`
};
export default ___CSS_LOADER_EXPORT___;
