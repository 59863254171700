import { Checkbox, FormControlLabel } from '@mui/material'
import { TickSquare } from 'iconsax-react'
import React from 'react'

const CheckInput = ({ name, onChange, label, unCheckedIcon, checkedIcon, ...rest }) => {
    return (
        <>
            {/* <input type="checkbox" name="" id="" /> */}
            <FormControlLabel control={<Checkbox icon={unCheckedIcon} checkedIcon={checkedIcon ? checkedIcon : <TickSquare variant='Bold' />} name={name} onChange={onChange} {...rest} />} label={label} />
        </>
    )
}

export default CheckInput