

import { Input, RAForm } from 'Components'
import FullPageLayout from 'Components/layout/FullPageLayout'
import { formatPrice } from 'helpers'
import BlueScreen from 'Partials/BlueScreen'
import InvoiceCard from 'Partials/InvoiceCard'
// import Text from 'Components/Text'
import UseCoins from 'Partials/UseCoins'
import React, { useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { closeModal, openModal } from 'Store/slices/modalSlice'

const FreePower = () => {
    // const 
    const dispatch = useDispatch()
    const [blueScreen, setBlueScreen] = useState(false)
    const onPurchase = () => {
        dispatch(closeModal())
        setBlueScreen(true)
    }
    const invoicelist = useMemo(() => {
        return [
            { field: 'Dis. Co', value:'KANO' },
            { field: 'Packages', value:'Prepaid' },
            { field: 'Meter Number', value:1234567890},
            { field: 'Amount', value:formatPrice(2000) },
            { field: 'Coins Used', value:'15,000' },
            { field: 'Discount', value:formatPrice(0) }
        ]
    }, [])
    const submit = () => {
        dispatch(openModal({ title: "", component: <InvoiceCard onClick={onPurchase} list={invoicelist} /> }))
    }
    return (
        <>
            <BlueScreen
                title='Reward received!'
                bodyText='₦ 2,000 KANO power has been successfully purchased for smart card number 2345678543 345643'
                onClickFillBtn={console.log}
                show={blueScreen}
                onDismiss={() => setBlueScreen(false)}
            />
            <FullPageLayout headerText={'Buy Power'} className='w-full' >
                <RAForm
                    className={'mx-auto max-w-[400px] mt-[50px]'}
                    inputsContainer='flex flex-col gap-[25px]'
                    btnText={'Claim Rewards'}
                    btnClass={'!rounded-[20px] mt-[60px] w-full'}
                    onSubmit={submit}
                >
                    <Input label={'Amount (₦)'} />
                    <Input label={'Dis. Co'} type='select' />
                    <Input label={'Packages'} type='select' />
                    <Input label={'Meter number'} placeholder="Eg 345 5678 543456 44" />
                    <UseCoins />

                </RAForm>
            </FullPageLayout></>
    )
}

export default FreePower