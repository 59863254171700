// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PhoneInputCountry{
    display: flex;
    border: .1px solid grey;
    width: 50px;
    border-radius: 12px;
}
.PhoneCountryCode{
    position: absolute;
    left: 0px;
}
.PhoneCountryImg{
    position: absolute;

    left: 40px !important;
}
.PhoneInputCountryIcon{
    box-shadow: none !important;
    background-color: none !important;
    display: block;
    border: none !important;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Input/phoneInput.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,WAAW;IACX,mBAAmB;AACvB;AACA;IACI,kBAAkB;IAClB,SAAS;AACb;AACA;IACI,kBAAkB;;IAElB,qBAAqB;AACzB;AACA;IACI,2BAA2B;IAC3B,iCAAiC;IACjC,cAAc;IACd,uBAAuB;AAC3B","sourcesContent":[".PhoneInputCountry{\n    display: flex;\n    border: .1px solid grey;\n    width: 50px;\n    border-radius: 12px;\n}\n.PhoneCountryCode{\n    position: absolute;\n    left: 0px;\n}\n.PhoneCountryImg{\n    position: absolute;\n\n    left: 40px !important;\n}\n.PhoneInputCountryIcon{\n    box-shadow: none !important;\n    background-color: none !important;\n    display: block;\n    border: none !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
