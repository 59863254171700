import { MdHome } from "react-icons/md";
// import { FaHouse } from "react-icons/fa6";
import { TbArrowsDownUp } from "react-icons/tb";
import { PiChartBar } from "react-icons/pi";
import { LuWallet2 } from "react-icons/lu";
import { PiHeadsetDuotone } from "react-icons/pi";
import { PiLightning } from "react-icons/pi";
import { AiOutlineUser } from "react-icons/ai";
import { BsShare } from "react-icons/bs";
import { GoGear } from "react-icons/go";

export const sidebarIcons = {
    Home: <MdHome />,
    Transactions: <TbArrowsDownUp />,
    Tracker: <PiChartBar />,
    Payment: <LuWallet2 />,
    HelpCenter: <PiHeadsetDuotone />,
    Subscription: <PiLightning />,
    Profile: <AiOutlineUser />,
    Invite: <BsShare />,
    Settings: <GoGear />,
}