import React, { useState, useEffect } from "react";
import { setComponentData } from 'Store/slices/components';
import { useDispatch } from 'react-redux';
import { FaPlus } from "react-icons/fa";
import { PiDeviceMobileCameraBold, PiBank } from "react-icons/pi";
import assets from 'Assets';
import { Button } from "Components";
import EmptyState from "Components/EmptyState";
import { openModal } from 'Store/slices/modalSlice';
import AddPaymentForm from './AddPaymentForm'; 

const Payment = () => {
  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(setComponentData({ component: 'headerTitle', data: 'Payment' }));
  }, [dispatch]);

  const [showPaymentMethods, setShowPaymentMethods] = useState(false);

  const handleAddPaymentClick = () => {
    setShowPaymentMethods(true);
  };

  const handleEditMobileMoney = () => {
    dispatch(openModal({
      component: (
        <div className="p-4 w-full max-w-md bg-white rounded-lg shadow-lg">
          <h2 className="text-xl font-semibold text-center mb-4">Mobile Money</h2>
          
          <label className="block mb-2">Account Number</label>
          <input
            type="text"
            placeholder="+234 55 123 4567"
            className="w-full p-2 mb-4 border rounded"
            onKeyPress={(e) => {
              if (!/[0-9]/.test(e.key)) {
                e.preventDefault();
              }
            }}
          />
          
          <label className="block mb-2">Account Name</label>
          <input
            type="text"
            placeholder="Sunday Okafor"
            className="w-full p-2 mb-4 border rounded"
            onKeyPress={(e) => {
              if (!/[a-zA-Z\s]/.test(e.key)) {
                e.preventDefault();
              }
            }}
          />
          
          <label className="block mb-2">Provider</label>
          <select className="w-full p-2 mb-4 border rounded">
            <option value="MTN">MTN</option>
            <option value="Glo">Glo</option>
            <option value="Airtel">Airtel</option>
            <option value="9mobile">9mobile</option>
          </select>
          
          <div className="flex justify-between mt-6">
            <Button r={24} bgcolor="#FFDAD6" color="Schemes/Error" className="w-48 px-6 py-2">
              Delete
            </Button>
            <Button r={24} bgcolor="#1976D2" color="white" className="w-48 px-6 py-2">
              Save
            </Button>
          </div>
        </div>
      ),
      title: 'Mobile Money'
    }));
  };

  const handleEditBankCard = () => {
    dispatch(openModal({
      component: (
        <div className="p-4 w-full max-w-md bg-white rounded-lg shadow-lg">
          
          <label className="block mb-2">Card Number</label>
          <input
            type="text"
            placeholder="1234 5678 9012 3456"
            className="w-full p-2 mb-4 border rounded"
            onKeyPress={(e) => {
              if (!/[0-9]/.test(e.key)) {
                e.preventDefault();
              }
            }}
          />
          
          <label className="block mb-2">Expiry Date</label>
          <input
            type="text"
            placeholder="12/10/2025"
            className="w-full p-2 mb-4 border rounded"
            onKeyPress={(e) => {
              if (!/[0-9/]/.test(e.key)) {
                e.preventDefault();
              }
            }}
          />

          <label className="block mb-2">CSV</label>
          <input
            type="text"
            placeholder="872"
            maxLength={3}
            className="w-full p-2 mb-4 border rounded"
            onKeyPress={(e) => {
              if (!/[0-9]/.test(e.key)) {
                e.preventDefault();
              }
            }}
          />
          
          <div className="flex justify-between mt-6">
            <Button r={24} bgcolor="#FFDAD6" color="Schemes/Error" className="w-48 px-6 py-2">
              Delete
            </Button>
            <Button r={24} bgcolor="#1976D2" color="white" className="w-48 px-6 py-2">
              Save
            </Button>
          </div>
        </div>
      ),
      title: 'Bank Card'
    }));
  };

  const handleAddPaymentMethod = () => {
    dispatch(openModal({
      component: <AddPaymentForm />, 
      title: 'Add Payment Method'
    }));
  };

  return (
    <div>
      {!showPaymentMethods && (
        <EmptyState
          icon={<img src={assets.images.Switch} alt="Switch" />}
          text="There are no payment methods"
        >
          <Button
            r={24}
            className="px-8 gap-6"
            onClick={handleAddPaymentClick}
          >
            <FaPlus />
            Add Payment
          </Button>
        </EmptyState>
      )}

      {showPaymentMethods && (
        <div className="flex flex-col gap-4 mt-4 w-1/2 mx-auto">
         
          <div className="flex justify-between items-center border p-4 rounded-lg shadow-sm">
            <div className="flex items-center gap-4">
              <PiDeviceMobileCameraBold />
              <div>
                <p>+233 55 555 555</p>
                <p className="text-lg">Mobile Money</p>
              </div>
            </div>
            <Button
              r={24}
              bgcolor="#C3D9FF"
              color="black"
              className="px-6 py-2 gap-2"
              onClick={handleEditMobileMoney}
            >
              <FaPlus />
              Edit
            </Button>
          </div>

          <div className="flex justify-between items-center border p-4 rounded-lg shadow-sm">
            <div className="flex items-center gap-4">
              <PiBank />
              <div>
                <p>**** 3456</p>
                <p className="text-lg">Bank Card</p>
              </div>
            </div>
            <Button
              r={24}
              bgcolor="#C3D9FF"
              color="black"
              className="px-6 py-2 gap-2"
              onClick={handleEditBankCard}
            >
              <FaPlus />
              Edit
            </Button>
          </div>

          <div 
            className="flex items-center gap-4 border-2 border-blue-500 p-4 rounded-lg shadow-sm cursor-pointer"
            onClick={handleAddPaymentMethod}
          >
            <div className="flex items-center justify-center w-8 h-8 bg-blue-500 text-white rounded-full">
              <FaPlus />
            </div>
            <p className="text-blue-500">Add Payment Method</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Payment;
