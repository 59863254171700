import FullPageLayout from 'Components/layout/FullPageLayout'
import React, { useState } from 'react'
import styles from '../ourvendors.module.scss'
import assets from 'Assets'
import { Button, Text } from 'Components'
import { BsBell, BsStarFill, } from 'react-icons/bs'
import ButtonStack from 'Components/Button/ButtonStack'

import { PiMapPinLineFill } from 'react-icons/pi'
import { BiSolidCoupon } from "react-icons/bi";
import NearYou from './NearYou'
import About from './About'
import Rating from './Rating'

import { useDispatch } from 'react-redux'
import { openModal } from 'Store/slices/modalSlice'
import NotifyMe from './NotifyMe'

const VendorDetails = () => {
  const [currTab, setCurrTab] = useState('near-you')
  const dispatch = useDispatch()
  const btns = [
    { text: 'Near you', onClick: setCurrTab, value: "near-you" },
    { text: 'About', onClick: setCurrTab, value: "about" },
    { text: 'Rating', onClick: setCurrTab, value: "rating" }
  ]
  return (
    <FullPageLayout headerText={'Vendor Shop Detail'}>
      <div className={styles.vendorDetails}>
        <header>
          <div className={styles.header}>
            <img src={assets.images.bgimg1} className={styles.bgimg} alt="" />
            <img src={assets.images.vendorIcon} className={styles.vendorIcon} alt="" />
            <Button width={'130px'} className={styles.notifyme} r={20} color={'#294265'} bgcolor='#C3D9FF' onClick={()=>dispatch(openModal({component:<NotifyMe />, title:'Stay Updated on Vendor Offers!  '}))}> <BsBell size={22} />  Notify Me</Button>
          </div>
        </header>
        <main>
          <div className='flex flex-col'>
            <Text weight={600} color={'#181C21'} size={24}>
              Burger King
            </Text>
            <Text display={'flex'} className={'gap-2 items-center'} weight={600} color={'#181C21'} size={14}>
              <BsStarFill size={15} />  4.4 (123,933)
            </Text>
            <Text display={'flex'} className={'gap-2 items-center'} weight={600} color={'#181C21'} size={14}>
              <PiMapPinLineFill size={15} />  12.3 km away • Closed
            </Text>
            <Text display={'flex'} className={'gap-2 items-center'} weight={600} color={'#181C21'} size={14}>
              <BiSolidCoupon size={15} />  14 coupons available
            </Text>
          </div>

          <ButtonStack className='my-5' active={currTab} btns={btns} />

          <div>
            {
              currTab === 'near-you' && <NearYou />
            }
            {
              currTab === 'about' && <About />
            }
            {
              currTab === 'rating' && <Rating />
            }
          </div>
        </main>
      </div>
    </FullPageLayout>
  )
}

export default VendorDetails