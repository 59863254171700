import React  from 'react'
import styles from './sidenav.module.scss'
import assets from 'Assets'
import { Link, useLocation } from 'react-router-dom'
import { sidebarIcons } from './sidenavicons'

const Sidenav = () => {
  const pages = [
    { path: '/', name: 'Home', icon: 'Home' },
    { path: '/Transactions', name: 'Transactions', icon: 'Transactions' },
    { path: '/Tracker', name: 'Tracker', icon: 'Tracker' },
    { path: '/Payment', name: 'Payment', icon: 'Payment' },
    { path: '/Help-Center', name: 'Help Center', icon: 'HelpCenter' },
    { path: '/Subscription', name: 'Subscription', icon: 'Subscription' },
    { path: '/Profile', name: 'Profile', icon: 'Profile' },
    { path: '/Invite', name: 'Invite', icon: 'Invite' },
    { path: '/Settings', name: 'Settings', icon: 'Settings' },
  ]
  const { pathname } = useLocation()
  // const selected = useMemo(() => pathname === )
  return (
    <div className={styles.body} >
      <header>
        <img src={assets.images.logo} width={168} alt="logo" />
      </header>
      <div className={styles.listbody}>
        <div className={styles.upperList}>
          {
            pages.map(page => <Link key={page.name} to={page.path.toLowerCase()} className={`${styles.listitem} ${pathname === page.path.toLowerCase() ? styles.selected : ''}`}>
              <span className={`${styles.icon}`}>  {sidebarIcons[page.icon]} </span>
              <span className={styles.text}>{page.name}</span>
            </Link>)
          }
        </div>
        <div className={styles.lowerList}></div>
      </div>
    </div >
  )
}

export default Sidenav