import Signin from "Pages/Auth/signin";
import Signup from "Pages/Auth/signup";
import Home from "Pages/Home";
import Shop from "Pages/Home/Shop";
import BuyAirtime from "Pages/Home/Shop/BuyAirtime";
import BuyPower from "Pages/Home/Shop/BuyPower";
import Freebies from "Pages/Home/Freebies";
import WatchAds from "Pages/Home/WatchAds";
import Transactions from "Pages/Transactions";
import FreeAirtime from "Pages/Home/Freebies/FreeAirtime";
import FreeData from "Pages/Home/Freebies/FreeData";
import FreePower from "Pages/Home/Freebies/FreePower";
import FreeCable from "Pages/Home/Freebies/FreeCable";
import NotFound from "Pages/NotFound";
import Payment from "Pages/Payment";
import BuyData from "Pages/Home/Shop/BuyData";
import MobileMoney from "Partials/MobileMoney";
import BuyCableTV from "Pages/Home/Shop/BuyCableTv";
import TollBooth from "Pages/Home/Shop/TollBooth";
import Movies from "Pages/Home/Shop/Movie";
import Broadband from "Pages/Home/Shop/BroadBand";
import BuyEducation from "Pages/Home/Shop/Education";
import PayVendors from "Pages/Home/PayVendors";
import GiftCard from "Pages/Home/GiftCard";
import AddGiftCard from "Pages/Home/GiftCard/AddGiftCard";
import ReviewAndConfirmGiftCard from "Pages/Home/GiftCard/ReviewAndConfirmGiftCard";
import OurVendor from "Pages/Home/OurVendors";
import VendorDetails from "Pages/Home/OurVendors/VendorDetails";
import Subscription from "Pages/Subscription";
import Settings from "Pages/Settings";
import TermsAndCondition from "Pages/Settings/TermsAndCondition";
import PrivacyPolicy from "Pages/Settings/PrivacyPolicy";
import FAQs from "Pages/Settings/FAQs";



const publicRoutes = [
    { Component: <Signup />, path: '/signup', layout: false },
    { Component: <Signin />, path: '/signin', layout: false },
    { Component: <Signin />, path: '/login', layout: false },
    { Component: <NotFound />, path: '*', layout: false },
]
const privateRoutes = [
    { Component: <Home />, path: '/', layout: true },
    { Component: <Transactions />, path: '/transactions', layout: true },
    { Component: <Payment />, path: '/payment', layout: true },
    { Component: <WatchAds />, path: '/campaigns', layout: true },
    { Component: <MobileMoney />, path: '/mobile-money', layout: false },
    { Component: <PayVendors />, path: '/pay-vendors', layout: false },
    { Component: <OurVendor />, path: '/our-vendors', layout: false },
    { Component: <VendorDetails />, path: '/our-vendors/:vendor', layout: false },
    { Component: <PayVendors />, path: '/pay-vendors/:page', layout: false },
    { Component: <Subscription />, path: '/subscription', layout: true },

    { Component: <Settings />, path: 'settings', layout: true },
    { Component: <TermsAndCondition />, path: 'settings/terms-and-condition', layout: false },
    { Component: <PrivacyPolicy />, path: 'settings/privacy-policy', layout: false },
    { Component: <FAQs />, path: 'settings/faqs', layout: false },
    {
        path: 'giftcard', layout: false, children: [
            { Component: <GiftCard />, path: '', layout: false },
            { Component: <AddGiftCard />, path: 'add', layout: false },
            { Component: <ReviewAndConfirmGiftCard />, path: 'add/review', layout: false },
        ]
    },
    {
        path: 'freebies', layout: false, children: [

            { Component: <Freebies />, path: '', layout: false },
            { Component: <FreeAirtime />, path: 'airtime', layout: false },
            { Component: <FreeData />, path: 'data', layout: false },
            { Component: <FreePower />, path: 'power', layout: false },
            { Component: <FreeCable />, path: 'cable', layout: false },

        ]
    },
    {
        path: 'shop', layout: false, children: [
            { Component: <Shop />, path: '', layout: false },
            { Component: <BuyAirtime />, path: 'airtime', layout: false },
            { Component: <BuyAirtime />, path: 'airtime/:type', layout: false },
            { Component: <BuyPower />, path: 'power', layout: false },
            { Component: <BuyData />, path: 'data', layout: false },
            { Component: <BuyCableTV />, path: 'cable-tv', layout: false },
            { Component: <TollBooth />, path: 'tollbooth', layout: false },
            { Component: <Movies />, path: 'movies', layout: false },
            { Component: <Broadband />, path: 'broadband', layout: false },
            { Component: <BuyEducation />, path: 'education', layout: false },
        ]
    },
]


export const routes = [
    ...publicRoutes.map(route => ({ ...route, isPublic: true })),
    ...privateRoutes.map(route => ({ ...route, isPublic: false }))
]

export default routes;