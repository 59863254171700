import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal } from 'Store/slices/modalSlice';
import styles from './modal.module.scss';
import Text from 'Components/Text';
import { FaTimes } from 'react-icons/fa';

const Modal = () => {
    const { component, title } = useSelector(state => state.modal);
    const dispatch = useDispatch();

    const handleModalClick = (event) => {
        event.stopPropagation(); 
    };

    return (
        component && (
            <>
                <div
                    className="h-[100vh] bg-[black] z-[10] w-[100vw] top-0 left-0 opacity-[.3] fixed"
                    onClick={() => dispatch(closeModal())}
                />
                <div
                    className={`${styles.modal} rounded-[12px] !bg-[white] relative`}
                    onClick={handleModalClick}
                >
                    <div className="absolute top-4 right-4 absolute flex items-center">
                        <button
                            className="p-2 rounded-full bg-gray-200 hover:bg-gray-300"
                            onClick={() => dispatch(closeModal())}
                        >
                            <FaTimes className="text-gray-600" />
                        </button>
                    </div>
                    <div className="flex justify-center mb-4">
                        <Text size={20} weight={600}>
                            {title}
                        </Text>
                    </div>
                    <div className=''>
                        {component}
                    </div>
                </div>
            </>
        )
    );
};

export default Modal;
