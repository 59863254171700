import React, { useMemo } from 'react'
import styles from './fullpagelayout.module.scss'
import { ArrowBack } from '@mui/icons-material'
import Text from 'Components/Text'
import { Link, useLocation } from 'react-router-dom'

const FullPageLayout = ({ children, backPath, onBack = () => null, headerText, className = '', rightComponent }) => {
  const { pathname } = useLocation()
  const goback = useMemo(() => {
    let paths = pathname.split('/').filter(path => path)
    paths.pop()
    return ['', ...paths].join('/')
  }, [pathname])


  return (
    <div className={styles.container}>
      <header>
        <Link to={backPath || goback} className={styles.ArrowBack} onClick={onBack}>
          <ArrowBack color='rgba(41, 66, 101, 1)' />
        </Link>
        <Text className={styles.headerTitle}> {headerText}</Text>
        <div className={styles.right}>
          {rightComponent}
        </div>
      </header>
      <div className={`${styles.content} ${className}`}>
        {children}
      </div>
    </div>
  )
}

export default FullPageLayout