import React from 'react'
import assets from 'Assets'

const GiveAwayCard = () => {
    return (
        <div className='w-100 rounded-[20px]'>
            <img src={assets.images.giveaway} alt="" className='w-100 rounded-[20px]' />
            {/* <Text>
                Give away
            </Text> */}
        </div>
    )
}

export default GiveAwayCard