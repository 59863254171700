import assets from 'Assets'
import { Button, Text } from 'Components'
import FullPageLayout from 'Components/layout/FullPageLayout'
import React, { useEffect, useState } from 'react'
import styles from './giftcard.module.scss'
// import { IoIosCheckmarkCircleOutline } from 'react-icons/io'
import { FaCheckCircle } from 'react-icons/fa'
import { PiUserCirclePlusFill, PiUsersFill } from 'react-icons/pi'
import { Avatar } from '@mui/material'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import AddGiftCardForMySelf from './AddGiftCardForMySelf'
import AddGiftCardForBeneficiary from './AddGiftCardForBeneficiary'
import ReviewAndConfirmGiftCard from './ReviewAndConfirmGiftCard'
import { calculateCoins } from 'helpers/shop'
import { useMe, useWalletInfo } from 'hooks'
import useBeneficiaries from 'hooks/useBeneficiaries'
import { apiRequest } from 'Services'
import EnterPin from 'Partials/EnterPin'
import { useDispatch } from 'react-redux'
import { openModal } from 'Store/slices/modalSlice'

//----------------
const SelectGiftCard = ({ onProceed = () => null }) => {
  const images = [
    { name: 'pink', img: assets.images.giftcardimages.pinkselect },
    { name: 'blue', img: assets.images.giftcardimages.blueselect },
    { name: 'violet', img: assets.images.giftcardimages.violetselect },
    { name: 'green', img: assets.images.giftcardimages.greenselect },
    { name: 'orange', img: assets.images.giftcardimages.orangeselect },
  ]

  const [selected, setSelected] = useState(null)
  return <>
    <Text display={'block'} align={'center'} size={20} weight={600}>Choose your preferred card design.</Text>
    <div className='flex gap-5 flex-wrap mx-auto mt-[50px]'>

      {
        images.map(image => <div
          onClick={() => { setSelected(image.name) }} className={`${styles.giftcardSelectImg} ${selected === image.name ? styles.selectedBorder : " "} `}
        >
          {
            selected === image.name && <FaCheckCircle enableBackground size={20} color='white' className={styles.selectedIcon} />
          }
          <img src={image.img} alt="" />
        </div>)
      }

    </div>
    <Button onClick={() => { onProceed(selected) }} disabled={!selected} width={'100%'} r={100} height={'40px'} className={'mt-[50px]'}>Continue</Button>
  </>
}
//----------------

//----------------
const GiftCardFor = ({ theme }) => {
  const { beneficiaries } = useBeneficiaries('')
  const navigate = useNavigate()
  return <>
    <Text display={'block'} align={'center'} size={20} weight={600}>Who would you like to buy gift card for?</Text>
    <div className='flex gap-5 flex-wrap mx-auto mt-[50px] '>

      <Link to={`/giftcard/add?user=myself&theme=${theme}`} className='border border-[#1976D2] w-[100%] h-[64px] flex items-center px-5 text-[#1976D2] gap-3 font-bold rounded-[12px]'>
        <PiUserCirclePlusFill size={20} />   Create Card For Myself
      </Link>
      <Link to={`/giftcard/add?user=beneficiary&theme=${theme}`} className='border border-[#1976D2] w-[100%] h-[64px] flex items-center px-5 mt-[25px] text-[#1976D2] gap-3 font-bold rounded-[12px]'>
        <PiUsersFill size={20} />  Add New Beneficiary
      </Link>
    </div>

    <div className='mt-[40px] border rounded-[12px] '>
      <div className='flex pl-[30px] font-bold w-full h-[44px] items-center bg-[#F2F3FC] rounded-t-[inherit]'>
        SAVED BENEFICIARIES
      </div>
      <div className='flex flex-col  '>
        {
          beneficiaries?.map(beneficiary => {
            return <div
              className='py-4 gap-[30px] items-center border-b flex px-[30px] cursor-pointer hover:!bg-light'
              onClick={() => navigate(`/giftcard/add?user=beneficiary&theme=${theme}&name=${beneficiary?.data?.name}&phone=${beneficiary?.data?.phone}`)}>
              <Avatar
                sx={{ bgcolor: '#25C7A4', color: 'black', textTransform: 'capitalize' }}
                alt="..."
              >{beneficiary?.data?.name?.[0]}</Avatar>
              <div className='flex flex-col h-full justify-between'>
                <Text size={16}> {beneficiary?.data?.name} </Text>
                <Text size={12}> {beneficiary?.data?.phone} </Text>

              </div>
            </div>
          })
        }

      </div>
    </div>
  </>
}
//----------------

const AddGiftCard = () => {
  const SCREENS = {
    SELECT: 'SELECT',
    FOR: 'FOR',
    ADDGIFTCARDFORMYSELF: 'ADDGIFTCARDFORMYSELF',
    ADDGIFTCARDFORBENEFICIARY: 'ADDGIFTCARDFORBENEFICIARY',
    REVIEW: 'REVIEW',
  }
  const [screen, setScreen] = useState(SCREENS.SELECT)
  const dispatch = useDispatch()
  const location = useLocation()

  useEffect(() => {
    const curr = (new URLSearchParams(location.search)).get("user")
    if (curr === 'myself') setScreen(SCREENS.ADDGIFTCARDFORMYSELF)
    if (curr === 'beneficiary') setScreen(SCREENS.ADDGIFTCARDFORBENEFICIARY)
  }, [location.search, SCREENS.ADDGIFTCARDFORMYSELF, SCREENS.ADDGIFTCARDFORBENEFICIARY])
  const { me } = useMe()
  const [giftcardData, setGiftcardData] = useState({
    theme: '',
    message: '',
    amount: 0,
    discount: 0,
    payable: 0,
    type: '',
    coinsUsed: 0,
    beneficiaryName: '',
    phone: ''
  })

  const { walletInfo } = useWalletInfo()
  const onDetails = ({ message, amount, type, usecoins, name, phone }) => {

    const coins = calculateCoins({ userCoins: walletInfo.reward.coins, amount, useCoins: usecoins })
    setGiftcardData(prev => ({
      ...prev, message,
      amount, type,
      payable: coins.payable,
      discount: coins.discount,
      coinsUsed: coins.coinsUsed,
      beneficiaryName: name || me?.firstname + " " + me?.lastname,
      phone: phone || me?.phone
    }))
    setScreen(SCREENS.REVIEW)
  }

  const onSuccess = async () => {
    try {
      await apiRequest({
        method: 'post', url: '/gift-cards', payload: {
          "phone": giftcardData.phone,
          "message": giftcardData.message,
          "theme": giftcardData.theme,
          "amount": giftcardData.amount,
          "payment_details": {
            "currency": "GHS",
            "payment_mode": 3,
            "phone":  giftcardData.phone,
            "provider": "MTN"
          }
        }
      })
    } catch (error) {

    }
  }
  return (
    <FullPageLayout headerText={'Add New Gift Card'}>
      <div className='w-[600px] mx-auto'>

        {
          screen === SCREENS.SELECT && <SelectGiftCard onProceed={(theme) => {
            setGiftcardData(prev => ({ ...prev, theme }))
            setScreen(SCREENS.FOR)
          }} />
        }
        {
          screen === SCREENS.FOR && <GiftCardFor theme={giftcardData.theme} />
        }
        {
          screen === SCREENS.ADDGIFTCARDFORMYSELF && <AddGiftCardForMySelf onProceed={({ message, amount, usecoins }) => {
            onDetails({ message, type: 'my-self', amount, usecoins })
          }} />
        }
        {
          screen === SCREENS.ADDGIFTCARDFORBENEFICIARY && <AddGiftCardForBeneficiary onProceed={({ message, amount, usecoins, beneficiaryName, phone }) => {
            onDetails({ message, type: 'beneficiary', amount, usecoins, name: beneficiaryName, phone })
          }} />
        }
        {
          screen === SCREENS.REVIEW && <ReviewAndConfirmGiftCard
            amount={giftcardData.amount}
            message={giftcardData.message}
            discount={giftcardData.discount}
            payable={giftcardData.payable}
            coinUsed={giftcardData.coinsUsed}
            beneficiaryName={giftcardData.beneficiaryName}
            phone={giftcardData.phone}
            onBack={() => {
              if (giftcardData.type === 'my-self') {
                setScreen(SCREENS.ADDGIFTCARDFORMYSELF)
              } else {
                setScreen(SCREENS.ADDGIFTCARDFORBENEFICIARY)
              }
            }}
            onSuccess={() => {
              onSuccess()
            }
              // dispatch(openModal({ title: '', component: <EnterPin message={`Enter Your PIN to approve a transaction of [NGN ${giftcardData.payable}]`} /> }))
            }
          />
        }
      </div>
    </FullPageLayout>
  )
}

export default AddGiftCard