

import { Input, RAForm } from 'Components'
import FullPageLayout from 'Components/layout/FullPageLayout'
import { formatPrice } from 'helpers'
import BlueScreen from 'Partials/BlueScreen'
import InvoiceCard from 'Partials/InvoiceCard'
// import Text from 'Components/Text'
import UseCoins from 'Partials/UseCoins'
import React, { useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { closeModal, openModal } from 'Store/slices/modalSlice'

const FreeAirtime = () => {
    // const 
    const dispatch = useDispatch()
    const [blueScreen, setBlueScreen] = useState(false)
    const onPurchase = () => {
        dispatch(closeModal())
        setBlueScreen(true)
    }
    const invoicelist = useMemo(() => {
        return [
            { field: 'Network Operator', value:'MTN' },
            { field: 'Beneficiary', value:'Frank Ozorkor' },
            { field: 'Amount', value:formatPrice(2000)},
            { field: 'Coins Used', value:'15,000' },
            { field: 'Discount', value:formatPrice(0.00) },
        ]
    }, [])
    const submit = () => {
        dispatch(openModal({ title: "", component: <InvoiceCard onClick={onPurchase} list={invoicelist} /> }))
    }
    return (
        <>
            <BlueScreen
                title='Reward received!'
                bodyText='₦1000 of airtime sent to +234 45 556 77558 with beneficiary name of Myself '
                onClickFillBtn={console.log}
                show={blueScreen}
                onDismiss={() => setBlueScreen(false)}
            />
            <FullPageLayout headerText={'Buy Airtime'} className='w-full' >
                <RAForm
                    className={'mx-auto max-w-[400px] mt-[50px]'}
                    inputsContainer='flex flex-col gap-[25px]'
                    btnText={'Claim Rewards'}
                    btnClass={'!rounded-[20px] mt-[60px] w-full'}
                    onSubmit={submit}
                >
                    <Input label={'Amount (₦)'} disabled placeholder={400} />
                    <Input label={'Provider'} />
                    <Input label={'Recipient Phone Number'} type='phone' />
                    <Input label={'Beneficiary Name'} />
                    <UseCoins />

                </RAForm>
            </FullPageLayout></>
    )
}

export default FreeAirtime