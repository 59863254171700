import React from 'react'
import Text from 'Components/Text'
import styles from '../styles/home.module.scss'
import assets from 'Assets'
import { Link } from 'react-router-dom'
const Bills = () => {

    const cards = [
        { icon: assets.images.airtimeandbills, path:'/shop',  text: <Text className={styles.billstext}>Airtime, Data <br /> & Bills</Text> },
        { icon: assets.images.getfreebies,  path:'/freebies', text: <Text className={styles.billstext}>Get <br /> Freeebies</Text> },
        { icon: assets.images.buymorecoins, path:'',  text: <Text className={styles.billstext}>Buy More <br /> Coins</Text> }
    ]
    return (
        <div className={`${styles.bills}`}>
            <Text display={'block'} className={styles.headerText}>
                RECHARGE AND BILL PAYMENTS
            </Text>
            <div className={`${styles.boxesContainer} ${styles.flex_div}`}>
                {
                    cards.map(({ text, icon,path }) => <Link to={path} className={styles.billsbox}>

                        <img src={icon} alt="network" />
                        {text}

                    </Link>)
                }
            </div>
        </div>
    )
}

export default Bills