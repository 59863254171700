import React, { useState } from "react";
import { Button } from "Components";
import { IoMdArrowDropdown } from "react-icons/io";

const AddPaymentForm = () => {
  const [formType, setFormType] = useState('Bank Card');
  const [cardNumber, setCardNumber] = useState('');
  const [expiryDate, setExpiryDate] = useState('');
  const [csv, setCsv] = useState('');
  const [countryCode, setCountryCode] = useState('+234');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [accountName, setAccountName] = useState('');
  const [provider, setProvider] = useState('');
  const [showCodeDropdown, setShowCodeDropdown] = useState(false);
  const [selectedFlag, setSelectedFlag] = useState('NG'); 

  const countryCodes = [
    { code: '+234', flag: 'NG', name: 'Nigeria' },
    { code: '+1', flag: 'US', name: 'United States' },
    { code: '+44', flag: 'GB', name: 'United Kingdom' },
    { code: '+233', flag: 'GH', name: 'Ghana' },
    
  ];

  const handleFormTypeChange = (type) => {
    setFormType(type);
  };

  const handleCountryCodeChange = (code, flag) => {
    setCountryCode(code);
    setSelectedFlag(flag);
    setShowCodeDropdown(false);
  };

  const isFormValid = () => {
    if (formType === 'Bank Card') {
      return cardNumber && expiryDate && csv;
    } else if (formType === 'Mobile Money') {
      return countryCode && phoneNumber && accountName && provider !== 'Select Provider';
    }
    return false;
  };

  return (
    <div className="p-4 w-full max-w-md bg-white rounded-lg shadow-lg">
      <div className="flex mb-4 border-2 border-gray-400 rounded-lg">
        <div 
          className={`flex-1 text-center py-2 cursor-pointer ${formType === 'Bank Card' ? 'bg-white font-bold border-gray-400 border-r-2 rounded-l-lg' : 'bg-gray-200 text-black'}`}
          onClick={() => handleFormTypeChange('Bank Card')}
        >
          Bank Card
        </div>
        <div 
          className={`flex-1 text-center py-2 cursor-pointer ${formType === 'Mobile Money' ? 'bg-white font-bold border-gray-400 border-l-2 rounded-r-lg' : 'bg-gray-200 text-black'}`}
          onClick={() => handleFormTypeChange('Mobile Money')}
        >
          Mobile Money
        </div>
      </div>

      {formType === 'Bank Card' && (
        <div>
          <label className="block mb-2">Card Number</label>
          <input
            type="text"
            placeholder="0000 0000 0000 0000"
            className="w-full p-2 mb-4 border rounded"
            value={cardNumber}
            onChange={(e) => setCardNumber(e.target.value)}
            onKeyPress={(e) => {
              if (!/[0-9]/.test(e.key)) {
                e.preventDefault();
              }
            }}
          />
          
          <label className="block mb-2">Expiry Date</label>
          <input
            type="text"
            placeholder="dd/mm/yy"
            className="w-full p-2 mb-4 border rounded"
            value={expiryDate}
            onChange={(e) => setExpiryDate(e.target.value)}
            onKeyPress={(e) => {
              if (!/[0-9/]/.test(e.key)) {
                e.preventDefault();
              }
            }}
          />

          <label className="block mb-2">CSV</label>
          <input
            type="text"
            placeholder="000"
            maxLength={3}
            className="w-full p-2 mb-4 border rounded"
            value={csv}
            onChange={(e) => setCsv(e.target.value)}
            onKeyPress={(e) => {
              if (!/[0-9]/.test(e.key)) {
                e.preventDefault();
              }
            }}
          />
          
          <Button
            r={24}
            bgcolor={isFormValid() ? '#1976D2' : '#90caf9'}
            color="white"
            className="w-full py-2"
            disabled={!isFormValid()}
            onClick={() => {
              
            }}
          >
            Create Method
          </Button>
        </div>
      )}

      {formType === 'Mobile Money' && (
        <div>
          <label className="block mb-2">Account Number</label>
          <div className="flex mb-4 gap-3">
            <div
              className="flex items-center px-2 border rounded-lg border-gray-300 cursor-pointer relative "
              onClick={() => setShowCodeDropdown(!showCodeDropdown)}
            >
              <span className="mr-2">{selectedFlag}</span>
              <span>{countryCode}</span>
              <IoMdArrowDropdown className="ml-2" />
              {showCodeDropdown && (
                <div className="absolute top-full left-0 mt-1 bg-white border border-gray-300 rounded-lg shadow-lg w-full z-10">
                  {countryCodes.map((country) => (
                    <div
                      key={country.code}
                      className="px-4 py-2 cursor-pointer hover:bg-gray-200"
                      onClick={() => handleCountryCodeChange(country.code, country.flag)}
                    >
                      <span className="mr-2">{country.flag}</span>
                      {country.code}
                    </div>
                  ))}
                </div>
              )}
            </div>
            <input
              type="text"
              placeholder="99 999 9999"
              className="flex-1 p-2 border rounded-lg "
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              onKeyPress={(e) => {
                if (!/[0-9]/.test(e.key)) {
                  e.preventDefault();
                }
              }}
            />
          </div>
          
          <label className="block mb-2">Account Name</label>
          <input
            type="text"
            placeholder="Eg. John Doe"
            className="w-full p-2 mb-4 border rounded"
            value={accountName}
            onChange={(e) => setAccountName(e.target.value)}
            onKeyPress={(e) => {
              if (!/[a-zA-Z\s]/.test(e.key)) {
                e.preventDefault();
              }
            }}
          />
          
          <label className="block mb-2">Provider</label>
          <select
            className="w-full p-2 mb-4 border rounded"
            value={provider}
            onChange={(e) => setProvider(e.target.value)}
          >
            <option value="Select Provider">Select Provider</option>
            <option value="MTN">MTN</option>
            <option value="Glo">Glo</option>
            <option value="Airtel">Airtel</option>
            <option value="9mobile">9mobile</option>
          </select>
          
          <Button
            r={24}
            bgcolor={isFormValid() ? '#1976D2' : '#90caf9'} 
            color="white"
            className="w-full py-2"
            disabled={!isFormValid()}
            onClick={() => {
             
            }}
          >
            Create Method
          </Button>
        </div>
      )}
    </div>
  );
};

export default AddPaymentForm;
