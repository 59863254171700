import assets from 'Assets'
import { Button, Text } from 'Components'
import CheckInput from 'Components/Input/CheckInput'
import React, { useState } from 'react'
import { BsCheckCircleFill, BsCircle } from 'react-icons/bs'
import { useDispatch } from 'react-redux'
import { openModal } from 'Store/slices/modalSlice'
import EnterPassword from './EnterPassword'

const CancelSubscription = () => {
    const reasons = [
        'Reachafrika Premium is too expensive', 'I don’t use my premium enough', 'Features ', 'GHS 50.00/monthly billed monthly', 'GHS 50.00/monthly billed monthly'
    ]
    const [selectedReasons, setSelectedReasons] = useState([])
    const selectReason = (reason, checked) => {
        console.log({ reason, checked })
        setSelectedReasons(prev => {
            if (!checked) return prev.filter(item => item !== reason)
            return [...prev, reason]
        })
    }
    const dispatch = useDispatch()
    const onCancel = () => {
        dispatch(openModal({ title: 'Enter password to unsubscribe', component: <EnterPassword /> }))
    }
    return (
        <div className='flex flex-col gap-3 items-center w-full'>
            <Text >Select a reason for cancelling your subscription</Text>
            <div className='flex w-full px-2 flex-col'>
                {
                    reasons.map(reason => <CheckInput
                        unCheckedIcon={<BsCircle size={20} />}
                        checkedIcon={<BsCheckCircleFill color={assets.colors.primary} size={20} />}
                        onChange={(e) => selectReason(reason, e.target.checked)}
                        label={reason} />)
                }
            </div>
            <Button disabled={!selectedReasons.length} onClick={onCancel} bgcolor='#BA1A1A' width={'100%'} r={100}>
                Confirm
            </Button>
        </div>
    )
}

export default CancelSubscription