import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
// import { colors } from 'Assets';
import { FaCheckCircle, FaRegCircle } from "react-icons/fa";
import { FaCircleDot } from "react-icons/fa6";
import assets from 'Assets';


const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: assets.colors.primary,
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: assets.colors.primary,
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        borderColor: theme.palette.mode === 'dark' ? assets.colors.primary : '#eaeaf0',
        borderTopWidth: 3,
        borderRadius: 1
    },
}));

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
    display: 'flex',
    height: 22,
    // alignItems: 'center',
    // border:'1px solid red',
    ...(ownerState.active && {
        color: assets.colors.primary,
    }),
    '& .QontoStepIcon-completedIcon': {
        color: assets.colors.primary,
        zIndex: 1,
        fontSize: 18,
    },
    '& .QontoStepIcon-circle': {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
    },
}));

function QontoStepIcon(props) {
    const { active, completed, className } = props;

    return (
        <QontoStepIconRoot ownerState={{ active }} className={className}>
            {completed && (
                // <FaCircleDot size={24} color={assets.colors.primary}/>
                <FaCheckCircle size={24} className="QontoStepIcon-completedIcon" />
            )}
            {active && (
                <FaCircleDot size={24} />
            )}
            {(!active && !completed) && (
                <FaRegCircle size={24} />
            )}
        </QontoStepIconRoot>
    );
}

QontoStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
};

// const steps = ['Select campaign settings', 'Create an ad group', 'Create an ad', 'Create an ad'];

export default function RAStepper({ activeStep = 1, steps = ['Step 1', 'Step 2', 'Step 3'], noLabel }) {
    return (
        <Stack sx={{ width: '100%' }} justifyContent={'space-between'} className=' px-0 mx-0 '>
            <Stepper alternativeLabel activeStep={activeStep} connector={<QontoConnector />}>
                {/* <QontoConnector sx={{width:'50px'}} /> */}
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel StepIconComponent={QontoStepIcon} > {!noLabel && label}</StepLabel>
                    </Step>
                ))}
                {/* <QontoConnector /> */}
            </Stepper>
        </Stack>
    );
}
