import React, { useState } from 'react'
import Select from 'react-select'


const RadaSelect = ({ name, onChange, options = [], isMulti, defaultValue, ...rest }) => {


    const [value, setValue] = useState(isMulti ? [] : '')

    // useEffect(() => {
    // if (isMulti) { 
    //     const _c = value?.map(x => x?.value)
    //     console.log(_c)
    //     onChange(_c)
    //  }
    // else { onChange(value) }
    // }, [value, onChange])

    //

    return (
        <>
            <input type="hidden" onChange={console.log} name={name} required={rest?.required} key={value} value={isMulti ? value?.map(x => x?.value).join('-sal-,-sal-') + "select-array-list" : value.value} />
            <Select options={options} isMulti={isMulti} name={name}
             onChange={(e) => {
                setValue(e)
                if (isMulti) {
                    const _c = value?.map(x => x?.value)
                    onChange({
                        target: {
                            name,
                            value: _c
                        }
                    })
                }
                else {
                    onChange({
                        target: {
                            name,
                            value: e.value
                        }
                    })
                }
            }}
                isDisabled={rest.disabled}
                defaultValue={options?.find(option => option.value === defaultValue)}
                {...rest}
                styles={{
                    container: (baseStyles) => ({ ...baseStyles, padding: 0, outline: 'none' }),
                    control: (baseStyles, state) => ({
                        ...baseStyles,
                        border: '1px solid rgba(230, 232, 240, 1)',
                        boxShadow: "none",
                        outline: 'none',
                        width: '100%',
                        padding: 0, margin: 0
                        , borderRadius: '12px'
                    }),
                    indicatorSeparator: (baseStyles) => ({ ...baseStyles, display: 'none' }),
                    input: (baseStyles) => ({ ...baseStyles, padding: 0, outline: 'none' }),
                    dropdownIndicator: (baseStyles) => ({ ...baseStyles, padding: 0 }),
                    menu: (baseStyles) => ({ ...baseStyles, padding: "0 !important", width: '100%' }),
                    valueContainer: (baseStyles) => ({ ...baseStyles, paddingLeft: 10, width: '100%' }),
                    menuPortal: (baseStyles) => ({ ...baseStyles, width: '100%' })
                }}
            />
        </>
    )
}
export default RadaSelect