import FullPageLayout from 'Components/layout/FullPageLayout'
import React from 'react'

const TermsAndCondition = () => {
  return (
    <FullPageLayout headerText={'Terms and Condition'}>
      
    </FullPageLayout>
  )
}

export default TermsAndCondition