const assets = {
    images: {
        logo: require('./images/logo.png'),
        logo2: require('./images/logo2.png'),
        email: require('./images/email.png'),
        giveaway: require('./images/giveaway.png'),
        megaphone: require('./images/megaphone.png'),
        gamepad: require('./images/gamepad.png'),
        buymorecoins: require('./images/buymorecoins.png'),
        airtimeandbills: require('./images/airtimeandbills.png'),
        getfreebies: require('./images/getfreebies.png'),
        samplead: require('./images/samplead.png'),
        coinstack: require('./images/coinstack.png'),
        thumbsup: require('./images/thumbsup.png'),
        rewardstars: require('./images/rewardstars.png'),
        success: require('./images/success.png'),
        failiure: require('./images/failiure.png'),
        money: require('./images/money.png'),
        storefront: require('./images/storefront.png'),
        qr: require('./images/qr.png'),
        scanqr: require('./images/scanqr.png'),
        cashiermachine: require('./images/cashiermachine.png'),
        giftbox: require('./images/giftbox.png'),
        testvendorimage: require('./images/testvendorimage.png'),
        giftcard: require('./images/giftcard.png'),
        burgerkingdemo: require('./images/burgerkingdemo.png'),
        vendorbg: require('./images/vendorbg.png'),
        bgimg1: require('./images/bgimg1.png'),
        vendorIcon: require('./images/vendorIcon.png'),
        reachafrkipremium: require('./images/reachafrkipremium.png'),
        shop: {
            airtime: require('./images/shop/airtime.png'),
            data: require('./images/shop/data.png'),
            cabletv: require('./images/shop/cabletv.png'),
            power: require('./images/shop/power.png'),
            movies: require('./images/shop/movies.png'),
            airline: require('./images/shop/airline.png'),
            education: require('./images/shop/education.png'),
            broadband: require('./images/shop/broadband.png'),
            tollbooth: require('./images/shop/tollbooth.png'),
            corporatedata: require('./images/shop/corporatedata.png'),
        },
        giftcardimages: {
            greenselect: require('./images/giftcards/greenselect.png'),
            blueselect: require('./images/giftcards/blueselect.png'),
            pinkselect: require('./images/giftcards/pinkselect.png'),
            violetselect: require('./images/giftcards/violetselect.png'),
            orangeselect: require('./images/giftcards/orangeselect.png'),
            greenbox: require('./images/giftcards/greenbox.png'),
            bluebox: require('./images/giftcards/bluebox.png'),
            pinkbox: require('./images/giftcards/pinkbox.png'),
            violetbox: require('./images/giftcards/violetbox.png'),
            orangebox: require('./images/giftcards/orangebox.png'),
            oval: require('./images/giftcards/oval.png'),
            curvy: require('./images/giftcards/curvy.png'),
            africamap: require('./images/giftcards/africamap.png'),
        },
        Switch: require('./images/Switch.png'),
    },
    colors: {
        primary: 'rgba(25, 118, 210, 1)',
        primary_light: 'rgba(143, 217, 255, 1)',
        primary_light2: 'rgba(195, 217, 255, 1)',
        grey: 'rgba(230, 232, 240, 1)'
    }
}
export default assets