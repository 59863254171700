import { apiRequest } from "Services"

// const { getShopProductsItem } = require("helpers/shop")
const { useState, useEffect } = require("react")
export const useShopItems = (product) => {

    const [items, setItems] = useState([])
    const [country, setCountry] = useState({})
    
    useEffect(() => {

        const getItems = async () => {
            try {
                const {data} = await apiRequest({ method: 'post', url: '/shop/products/items', payload: { product } })
                // console.log(data)
                setItems(data?.items)
                setCountry(data?.country)

            } catch (error) {
                console.log(error)
                return []
            }
        }
        getItems()

    }, [product])
    return {
        items,country
    }
}