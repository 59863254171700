import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setComponentData } from 'Store/slices/components'
import styles from './styles/home.module.scss'
import GiveAwayCard from './components/GiveAwayCard'
import SecondCard from './components/SecondCard'
import Bills from './components/Bills'
import MyPayments from './components/MyPayments'
import Balances from './components/Balances'
import HomeMessageBox from './components/HomeMessageBox'


const Home = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setComponentData({ component: 'headerTitle', data: 'Home' }))
  }, [dispatch])
  return (
    <>
      <HomeMessageBox /> <br />
      <div className={styles.homeBody}>
        <div className={styles.left}>
          <div className={styles.box}>
            <GiveAwayCard />
          </div>
          <div className={styles.box}>
            <SecondCard />
          </div>
          <div className={styles.box}>
            <Bills />
          </div>
          <div className={styles.box}>
            <MyPayments />
          </div>

        </div>

        <div className='w-[30%]'>
          <Balances />
        </div>
      </div>
      {/* </div> */}
    </>

  )
}

export default Home