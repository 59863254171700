import { Button, Text } from 'Components'
import React from 'react'

const NotifyMe = () => {
  return (
    <div className='flex flex-col gap-5 items-center w-full'>
        <Text align={'center'} display={'block'}  className={'w-[95%] '}>By opting in, you'll get notifications about exciting updates, such as new discounts, bonus freebies, and more from this vendor. Are you sure you want to stay informed?  </Text>
        <Button width={'100%'} r={100}>
            Notify Me
        </Button>
    </div>
  )
}

export default NotifyMe