import React from 'react'
import { Input } from 'Components'
import Text from 'Components/Text'
import assets from 'Assets'

const UseGiftCards = () => {
  return (
    <div className='border p-3 grid grid-cols-12 rounded-[12px] items-center h-[100px]'>
    <div className='col-span-2  self-start h-[38px] w-[38px] flex self-center items-center  justify-center !bg-[#F2F3FC] rounded'>
        <img src={assets.images.giftcard} width={24} height={24} alt="" className='!h-[24px]' />
    </div>
    <div className='col-span-8  flex flex-col '>
        <Text color={'#181C21'} weight={600} size={16} className={'opacity-[.7]'}>Use Gift Cards</Text>
        <Text color={'#414752'} weight={400} size={12} className={'opacity-[.7]'}>Redeem your gift card for purchases or payments.</Text>
        {/* <div className='flex gap-2'>
            <img src={assets.images.coinstack} width={10} height={10} alt="" />
            <Text color={'#414752'} weight={400} size={12} className={'opacity-[.7] flex gap-2'}>
                100 coins = NGN 1.00
            </Text>
        </div> */}

    </div>
    <div className='col-span-2 self-center'>
        <Input type='switch' name="usecoins" />
    </div>
</div>
  )
}

export default UseGiftCards