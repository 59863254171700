import { Button, Input, Text } from 'Components'
import React from 'react'

const EnterPin = ({message = 'Enter Your PIN to approve a transaction of [NGN 4000] from [Melcom Merchant]'}) => {

  return (
    <div className=' flex flex-col gap-4 justify-center items-center text p-5'>
      <div className=' w-[300px] t text-center'>
        <Text display={'block'} align={'center'} size={24} weight={600} >Enter Your PIN To Approve</Text>

        <Text className={'ext ' } align={'center'} size={14} weight={400} >
          {message}
        </Text>

      </div>
      <Input type='otp' containerClass='text-center  w-full my-[20px]' numInputs={6} />
      <Button width={'100%'} r={100} >Approve</Button>
    </div>
  )
}

export default EnterPin