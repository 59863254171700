

import { Alert } from '@mui/material'
import assets from 'Assets'
import { Input, RAForm } from 'Components'
import FullPageLayout from 'Components/layout/FullPageLayout'
import RAStepper from 'Components/Stepper'
import { formatPrice } from 'helpers'
import { calculateCoins, purchaseShopProduct, shopTransactionHash } from 'helpers/shop'
import { useWalletInfo } from 'hooks'
import { useShopItems } from 'hooks/useShopItems'
// import { formatPrice } from 'helpers'
import BlueScreen from 'Partials/BlueScreen'
import InvoiceCard from 'Partials/InvoiceCard'
// import SelectPaymentMethod from 'Partials/SelectPaymentMethod'
// import Text from 'Components/Text'
import UseCoins from 'Partials/UseCoins'
import React, { useState } from 'react'
// import { useDispatch } from 'react-redux'
// import { useNavigate, useParams } from 'react-router-dom'
// import { openModal } from 'Store/slices/modalSlice'

const Screens = Object.freeze({ "FORM": 0, "INVOICE": 1, "SUCCESS": 2, "FAILED": 3 });


const BuyEducation = () => {
    // const { type = "Local" } = useParams()
    // const navigate = useNavigate()
    const [currScreen, setCurrScreen] = useState(Screens.FORM)
    // console.log({type})
    // const dispatch = useDispatch()
    const { walletInfo } = useWalletInfo()
    // useEffect(() => {you
    //     const allowed_types = ['', 'Local', 'International']
    //     if (!allowed_types.map(allowed_type => allowed_type.toLowerCase()).includes(type.toLowerCase())) navigate('*')
    // }, [type, navigate])
    const onPurchase = () => {
        submit()
        // dispatch(openModal({ title: '', component: <SelectPaymentMethod onComplete={submit} onError={() => setCurrScreen(Screens.FAILED)} /> }))
    }

    const [invoicelist, setInvoiceList] = useState([])

    const [provider, setProvider] = useState({})
    const [payload, setPayload] = useState({})
    const submit = async () => {
        try {
            const hash = shopTransactionHash({ product: 'education', payable: payload?.payable, amount: payload?.amount })
            await purchaseShopProduct({
                product: 'education',
                beneficiaryName: payload?.beneficiaryName,
                amount: payload?.amount,
                payable: payload?.payable,
                // meter_no: payload?.meter_no,
                // disco_id: payload?.disco_id,
                // vendor_type: payload?.vendor_type,
                product_id: payload?.institution,
                type: payload?.type,
                hash
            })
            setCurrScreen(Screens.SUCCESS)
        } catch (error) {

        }
    }
    const previewInvoice = async (params) => {
        const type = provider?.types?.find(type => type?.name === params?.type)
        const coins = calculateCoins({ userCoins: walletInfo.reward.coins, amount: provider?.price, useCoins: params?.usecoins })
        console.log(coins)
        setPayload({
            institution: provider?.id,
            type: params?.type,
            account: params?.accountNo,
            amount: provider?.price,
            payable: coins.payable
        })
        const jambInvoice = type ? [
            { field: 'Type', value: type?.alias || "N/A" },
            { field: 'Account', value: params?.accountNo || "N/A" },
        ] : []
        setInvoiceList([
            { field: 'Institution', value: provider?.alias },
            { field: 'Amount', value: formatPrice(provider?.price) },
            ...jambInvoice,

            { field: 'Coins Used', value: coins.coinsUsed },
            { field: 'Discount', value: formatPrice(coins.discount) }
        ])
        setCurrScreen(Screens.INVOICE)
    }

    const product = useShopItems('education')

    return (
        <>
            <BlueScreen
                title='Purchase Successful!'
                bodyText='You have successfully bought ₦2000 airtime from MTN for John Doe. Thank you for your purchase!'
                onClickFillBtn={console.log}
                show={currScreen === Screens.SUCCESS}
                icon={assets.images.success}
                onDismiss={() => { setCurrScreen(Screens.FORM) }}
            />
            <BlueScreen
                title='Transaction was not successful. Please try again '
                onClickFillBtn={() => {
                    setCurrScreen(Screens.FORM)
                }}
                fillBtnText='Try again'
                show={currScreen === Screens.FAILED}
                icon={assets.images.failiure}
            />
            <FullPageLayout headerText={`Education`} className='w-full' >


                <div className='w-[510px] !mt-[20px] mx-auto'>
                    <RAStepper noLabel steps={[0, 1, 2]} activeStep={currScreen} />
                </div>

                <div style={currScreen === Screens.INVOICE ? { marginTop: 50 } : { visibility: 'hidden', height: 0 }} className='h-[100%] !'>
                    <InvoiceCard
                        onBack={() => setCurrScreen(Screens.FORM)}
                        onClick={onPurchase} list={invoicelist}
                        total={payload?.payable}
                        rightText={`Purchase ${payload?.coins ? `(${payload?.coins} Coins)` : ''}`}
                    />
                </div>
                <RAForm
                    className={'mx-auto max-w-[400px] '}
                    inputsContainer='flex flex-col gap-[25px]'
                    btnText={'Proceed to checkout'}
                    btnClass={'!rounded-[20px] mt-[60px] w-full'}
                    onSubmit={previewInvoice}
                    style={currScreen === Screens.FORM ? {} : { visibility: 'hidden', height: 0 }}
                >
                    <Alert variant='standard' severity='info' className='p-0' >
                        Info message
                    </Alert>
                    <Input
                        required
                        type='select'
                        name='provider'
                        placeholder='Select Institution'
                        options={product.items.map(item => ({ label: item?.alias, value: item?.id }))}
                        onChange={event => setProvider(product.items?.find(product => product?.id === parseInt(event?.target?.value)))}
                    />
                    {
                        provider?.types?.length ?
                            <>
                                <Input
                                    label={'Select Type'} placeholder='Select type' type='select' name='type'
                                    options={provider?.types?.map(item => ({ label: item?.alias, value: item?.name }))} required
                                />
                                <Input
                                    label={'Account no.'} placeholder='1234567' type='number' name='accountNo' required
                                />
                            </>
                            :
                            <></>
                    }
                    <UseCoins />
                </RAForm>



            </FullPageLayout></>
    )
}

export default BuyEducation