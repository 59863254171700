import React, { useState } from 'react'
import styles from '../styles/watchas.module.scss'
import assets from 'Assets'
import Text from 'Components/Text'
import { useDispatch } from 'react-redux'
import { closeModal, openModal } from 'Store/slices/modalSlice'
import { Button } from 'Components'
import { useFetch } from 'hooks'
import ReactPlayer from 'react-player'


const AdVideo = ({ ad }) => {
    console.log(ad)
    const FollowUs = () => {
        return <div className='w-[400px] flex flex-col items-center'>
            {/* <Text weight={400} color={'Schemes/On Surface Variant'} >You’ve earned for yourself</Text> */}
            <img src={assets.images.thumbsup} height={80} width={80} alt="" />
            <div className='my-4 flex gap-2 text-center items-center'>

                <Text weight={600} size={24} className={'text-center'} color={"rgba(24, 28, 33, 1)"} >
                    Follow us on social media to find out more about this campaign
                </Text>
            </div>
            <div className='flex gap-2 w-[100%] '>
                <Button onClick={() => dispatch(closeModal())} width={'100%'} bgcolor='rgba(195, 217, 255, 1)' color='black' className={'mt-4'} r={20}>
                    Not Now
                </Button>
                <Button width={'100%'} className={'mt-4'} r={20}>
                    Learn more
                </Button>
            </div>
        </div>
    }
    const ThanksForWatching = () => {
        return <div className='w-[400px] flex flex-col items-center'>
            <Text weight={400} color={'Schemes/On Surface Variant'} >You’ve earned for yourself</Text>
            <div className='my-4 flex gap-2 items-center'>
                <img src={assets.images.coinstack} height={50} width={56} alt="" />
                100
            </div>
            <Button onClick={
                () => dispatch(openModal({
                    title: '', component: <FollowUs />
                }))
            } width={'100%'} className={'mt-4'} r={20}>
                Collect Coins
            </Button>
        </div>
    }
    const dispatch = useDispatch()
    const onEnded = () => {
        dispatch(openModal({
            title: 'Thanks for watching', component: <ThanksForWatching />
        }))
    }
    return (
        ad && <div className={styles.adVideo} onClick={onEnded}>
            {/* <VideoJS
                style={{ borderRadius: '20px !important', height: '100%', width: '100%' }}
                options={{
                    autoplay: true,
                    controls: true,
                    responsive: true,
                    fluid: true,
                    sources: [{
                        src: 'https://www.youtube.com/watch?v=LXb3EKWsInQ',
                        type: 'application/x-mpegURL'
                    }]
                }} /> */}
            <ReactPlayer controls onEnded={onEnded} style={{height:'100% !important',width:'100% !important', borderRadius:'inherit'}} url='https://www.youtube.com/watch?v=LXb3EKWsInQ' />
        </div>
    )
}


const WatchAds = () => {
    const [display, setDisplay] = useState(null)
    const { data: campaigns } = useFetch({ url: '/promos/campaigns' })
    console.log(campaigns?.data)
    return (
        <>
            {
                display && <div className={styles.overlay} onClick={() => setDisplay(false)}>

                </div>
            }
            <AdVideo ad={display} />
            <div className={styles.adsContainer}>
                {
                    campaigns?.data?.map(campaign => {

                        return <div className={styles.adCard} onClick={() => setDisplay(campaign)}>
                            <img src={campaign?.thumbnail_stream} alt={""} />
                            <div className={styles.adDetailsOverLay}></div>
                            <div className={styles.adDetails}>
                                <div className={'flex'}>

                                </div>
                                <Text weight={600} size={16}>{campaign?.title}</Text>
                                <div className={'flex justify-between'}>
                                    <Text weight={600} size={16}>{campaign?.description}</Text>
                                    <Text weight={600} size={16}>{campaign?.duration}mins</Text>
                                </div>
                            </div>
                        </div>
                    })
                }


            </div>
        </>
    )
}

export default WatchAds