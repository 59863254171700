import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom"
import { routes } from "./routes";
import './index.css'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Provider } from 'react-redux';
import { persistor, store } from "Store";
import { PersistGate } from 'redux-persist/integration/react';
import { QueryClientProvider } from 'react-query'
import { queryClient } from "Services/queryClient";
import Modal from "Components/Modal";
import Layout from "Components/layout";
import './App.css';
import LoadingScreen from "Components/LoadingScreen";


function App() {
  const PrivateRoute = ({ children }) => {
    const token = (store.getState().auth.user.token)
    if (token) return children
    return <Navigate to={'/signin'} />
  }

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <QueryClientProvider client={queryClient} >
          <BrowserRouter>
            <ToastContainer />
            <Modal />
            <LoadingScreen />
            
            <Routes>
              {routes.map(route => {

                const layout = route.layout
                const path = route.path
                const children = route?.children || []
                const isPublic = route.isPublic
                const Component = layout ? <Layout>{route.Component}</Layout> : route.Component
                // if (!route.isPublic) {
                //   const token = (store.getState().auth.user.token)
                //   if (!token) return <Route path={path} element={<Navigate to={'/signin'} />} key={path} />
                // }
                const Protect = isPublic ? Component : <PrivateRoute>{Component}</PrivateRoute>
                return (
                  children?.length ?
                    <Route path={path} key={path} >
                      {children?.map(child => <Route path={child.path} element={child.Component} key={child.path} />)}
                    </Route>
                    : <Route path={path} element={Protect} key={path} />
                )
              })}
            </Routes>
          </BrowserRouter>
        </QueryClientProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
