import { useEffect, useState } from 'react'
import { apiRequest } from 'Services'

const useWalletInfo = () => {
    const [walletInfo, setWalletInfo] = useState({
        "id": 0,
        "wallet_id": 0,
        "profile_id": 0,
        "type": "Normal",
        "user_id": 0,
        "balance": 0,
        "key": "",
        "created_at": "",
        "updated_at": "",
        "total": "NGN 0",
        "coins": "0",
        "account_balance": "0",
        reward: {
            total: 0,
            coins: 0,
            balance:0
        }
    })
    useEffect(() => {
        const getMe = async () => {
            try {
                const { data } = await apiRequest({ url: '/accounts/normal-account', version: 'v1' })
                const { data: rewardData } = await apiRequest({ url: '/accounts/reward-account', version: 'v1' })
                setWalletInfo({ ...data, reward: rewardData })
            } catch (error) {

            }
        }
        getMe()
    }, [])
    return {
        walletInfo
    }
}

export default useWalletInfo