import React, { useEffect, useState } from 'react'
import PhoneInput from 'react-phone-number-input'
import './phoneInput.css'
import 'react-phone-number-input/style.css'
import Text from 'Components/Text'
import axios from 'axios'
import Select from 'react-select'
import { phonecodes } from './phonecodes'

const RAPhoneInput = ({ onChange = () => null, name, defaultValue,...props }) => {
    // const [value, setValue] = useState('')
    const [country, setCountry] = useState('234')
    const [phoneNumber, setPhoneNumber] = useState(defaultValue)
    const [phoneAndCode, setPhoneAndCode] = useState(defaultValue)
    useEffect(() => {
        // const getPhones = async () => {
        //     try {
        //         const { data } = await axios.get(`https://country.io/phone.json`)
        //         console.log(data)
        //     } catch (error) {

        //     }
        // }
        // getPhones()
    }, [])
    // const [country, seCountry] = useState('NG')
    const CountryFlag = ({ country }) => {
        return <img alt='flag'
            width={23.5} style={{ borderRadius: 4 }} height={23} src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${country}.svg`} />
    }
    const allowed = ['NG', 'TG', 'GH']
    const change = (countryCode, number) => {
        const phone = countryCode + number?.replace(countryCode, '')?.replace("+", '')
        setPhoneAndCode(phone)
        onChange({
            target: {
                name: name,
                value: phone
            }
        })
    }
    return (
        <div className='!w-[100%] flex gap-2'>
            <div className=' h-[100%] flex items-center w-[100px] rounded-[12px]'>
                <Select
                    placeholder=''
                    className='border-none'
                    options={Object.entries(phonecodes).filter(code => allowed.includes(code[0])).map(code => ({ label: <div className='flex justify-center gap-2'> <CountryFlag country={code[0]} /> +{code[1].padStart(1, "+")}</div>, value: code[1] }))}
                    defaultValue={{ 'value': 'NG', label: <div className='flex justify-center gap-2'> <CountryFlag country={'NG'} /> +234</div> }}
                    styles={{
                        container: (baseStyles) => ({ ...baseStyles, padding: 0, outline: 'none' }),
                        control: (baseStyles, state) => ({
                            ...baseStyles,
                            border: '1px solid rgba(230, 232, 240, 1)',
                            boxShadow: "none",
                            outline: 'none',
                            width: '100px',
                            padding: 0, margin: 0
                            , borderRadius: '12px'
                        }),
                        indicatorSeparator: (baseStyles) => ({ ...baseStyles, display: 'none' }),
                        input: (baseStyles) => ({ ...baseStyles, padding: 0, outline: 'none' }),
                        dropdownIndicator: (baseStyles) => ({ ...baseStyles, padding: 0 }),
                        menu: (baseStyles) => ({ ...baseStyles, padding: "0 !important", fontSize: '12px', width: '100px' }),
                        valueContainer: (baseStyles) => ({ ...baseStyles, padding: 0, fontSize: 10, width: '100%' }),
                        menuPortal: (baseStyles) => ({ ...baseStyles, width: '100px' }),

                    }}
                    onChange={(e) => {
                        setCountry(e.value)
                        change(e.value, phoneNumber)
                    }}
                    comp />
            </div>

            <PhoneInput
                flagComponent={(props) => (<div className=' w-[60px] gap-4 !h-[100%] rounded-[12px]'>
                    <Text className={'PhoneCountryCode'} size={10}>+234</Text>
                </div>)}
                
                placeholder="800 000 0000"
                value={phoneNumber}
                withCountryCallingCode
                onChange={(phone) => { setPhoneNumber(phone?.replace('+', '')); change(country, phone) }}
                onCountryChange={console.log}
                defaultCountry={'NG'}
                className='gap-[20px] w-[100%] items-center'
                countrySelectComponent={() => <></>}
            />
            <input type="tel" name={name} value={phoneAndCode} hidden required={props?.required} />
        </div>
    )
}

export default RAPhoneInput