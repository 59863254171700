// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_shopContainer__Fk34p {
  display: grid;
  width: 70%;
  margin: auto;
  grid-template-columns: auto auto auto;
  gap: 20px;
}
@media screen and (max-width: 900px) {
  .styles_shopContainer__Fk34p {
    width: 80vw;
    gap: 15px;
  }
}
@media screen and (max-width: 576px) {
  .styles_shopContainer__Fk34p {
    gap: 8px;
    width: 100%;
    grid-template-columns: auto auto;
  }
}

.styles_shopCard__aNSGE {
  width: 21.2121212121vw;
  height: 196px;
  border-radius: 16px;
  background-color: #F2F3FC;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
@media screen and (max-width: 900px) {
  .styles_shopCard__aNSGE {
    width: 26.6666666667vw;
  }
}
@media screen and (max-width: 576px) {
  .styles_shopCard__aNSGE {
    width: 45.4545454545vw;
  }
}
.styles_shopCard__aNSGE .styles_shopIconCircle__atvvX {
  width: 96px;
  height: 96px;
  border-radius: 100%;
  display: grid;
  place-content: center;
  background-color: white;
}
.styles_shopCard__aNSGE img {
  height: 48px;
  width: 48px;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Home/Shop/styles.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,UAAA;EACA,YAAA;EACA,qCAAA;EACA,SAAA;AACJ;AAAI;EANJ;IAOQ,WAAA;IAAe,SAAA;EAIrB;AACF;AAHI;EATJ;IAUQ,QAAA;IACA,WAAA;IACA,gCAAA;EAMN;AACF;;AAHA;EACI,sBAAA;EAEA,aAAA;EACA,mBAAA;EACA,yBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,sBAAA;AAKJ;AAJI;EAVJ;IAWQ,sBAAA;EAON;AACF;AANI;EAbJ;IAeQ,sBAAA;EAQN;AACF;AANI;EACI,WAAA;EACA,YAAA;EACA,mBAAA;EACA,aAAA;EACA,qBAAA;EACA,uBAAA;AAQR;AAJI;EACI,YAAA;EACA,WAAA;AAMR","sourcesContent":[".shopContainer {\n    display: grid;\n    width: 70%;\n    margin: auto;\n    grid-template-columns: auto auto auto;\n    gap: 20px;\n    @media screen and (max-width:900px) {\n        width: 80vw;   gap: 15px;\n    }\n    @media screen and (max-width:576px) {\n        gap: 8px;\n        width: 100%;\n        grid-template-columns: auto auto;\n    }\n}\n\n.shopCard {\n    width:  calc(70vw/3.3);\n    // max-width: 300px;\n    height: 196px;\n    border-radius: 16px;\n    background-color: #F2F3FC;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-direction: column;\n    @media screen and (max-width:900px) {\n        width: calc(80vw/3); \n    }\n    @media screen and (max-width:576px) {\n\n        width: calc(100vw/2.2);\n    }\n\n    .shopIconCircle {\n        width: 96px;\n        height: 96px;\n        border-radius: 100%;\n        display: grid;\n        place-content: center;\n        background-color: white;\n\n    }\n\n    img {\n        height: 48px;\n        width: 48px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"shopContainer": `styles_shopContainer__Fk34p`,
	"shopCard": `styles_shopCard__aNSGE`,
	"shopIconCircle": `styles_shopIconCircle__atvvX`
};
export default ___CSS_LOADER_EXPORT___;
