import { QuestionMarkRounded } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { BsChevronRight, BsFileLock, BsLock } from 'react-icons/bs'
import { GiNotebook } from 'react-icons/gi'
import { IoMdTrash } from 'react-icons/io'
import { LuLogOut } from 'react-icons/lu'
import { MdPassword } from 'react-icons/md'
import { PiGlobeHemisphereEast } from 'react-icons/pi'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { setComponentData } from 'Store/slices/components'
import { openModal } from 'Store/slices/modalSlice'
import ChangeLanguage from './ChangeLanguage'
// import ForgotPassword from './ForgotPassword'
import ChangePassword from './ChangePassword'
import ConfirmModal from 'Components/Modal/ConfirmModal'
import { logout } from 'Store/slices/auth'
import DeleteAccount from './DeleteAccount'
import ChangePin from './ChangePin'

const Settings = () => {

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(setComponentData({ component: 'headerTitle', data: 'Settings' }))
    }, [dispatch])

    const handleModal = (title, component) => {
        // console.log('---')
        dispatch(openModal({ title, component }))
        // console.log('---')
    }

    const settings1 = [
        { name: 'Terms and Condition', icon: <GiNotebook />, to: 'https://reachafrika.com/terms-&-conditions', onClick: () => null },
        { name: 'Privacy Policy', icon: <BsFileLock />, to: 'https://reachafrika.com/privacy-policy', onClick: () => null },
        { name: 'FAQ', icon: <QuestionMarkRounded />, to: 'https://reachafrika.com/faqs', onClick: () => null  }
    ]
    const settings2 = [
        { name: 'Change App Language', icon: <PiGlobeHemisphereEast />, onClick: () => handleModal('Change App Language', <ChangeLanguage />) },
        { name: 'Change Password', icon: <MdPassword />, onClick: () => handleModal('Change Password', <ChangePassword />) },
        { name: 'Change Pin', icon: <BsLock />, onClick: () => handleModal('Change Pin', <ChangePin />) }
    ]
    const settings3 = [
        { name: 'Log out', icon: <LuLogOut />,  onClick: () => handleModal('Logout', <ConfirmModal rightText='Logout' onProceed={()=>dispatch(logout())}  color='#BA1A1A'/>), color: '#BA1A1A' },
        { name: 'Delete Account', icon: <IoMdTrash />, onClick: () => handleModal('Delete Account', <DeleteAccount />), color: '#BA1A1A' }
    ]

    const Setting = ({ icon, name = 'Setting', onClick = () => null, color = '#181C21', to, target = '_blank' }) => {
        return <Box onClick={onClick} component={to ? Link : 'div'} to={to} target={target} className='!border cursor-pointer  h-[64px] w-full rounded-[12px] items-center px-3 grid grid-cols-12'>

            <div className='col-span-1'>
                {icon}
            </div>
            <Typography color={color} className='col-span-10 ' >
                {name}
            </Typography>
            <div className='col-span-1 justify-self-end'>
                <BsChevronRight color={color} />
            </div>
        </Box>
    }
    return (
        <div className='w-[600px] mx-auto flex flex-col gap-[40px]'>
            <div className='flex flex-col gap-4'>{
                settings1.map(setting => <Setting key={setting.name} {...setting} />)
            }</div>
            <div className='flex flex-col gap-4'>{
                settings2.map(setting => <Setting key={setting.name} {...setting} />)
            }</div>
            <div className='flex flex-col gap-4'>{
                settings3.map(setting => <Setting key={setting.name} {...setting} />)
            }</div>
        </div>
    )
}

export default Settings