import React from 'react'

import { Text } from 'Components'
import assets from 'Assets'
import styles from './payvendor.module.scss'
import { BsChevronRight } from 'react-icons/bs'
import UseCoins from 'Partials/UseCoins'

import { useDispatch } from 'react-redux'
import { openModal } from 'Store/slices/modalSlice'
import QRPopup from './QRPopup'
import UseGiftCards from 'Partials/UseGiftCards'
import { Link } from 'react-router-dom'

const PayVendor = () => {

    const dispatch = useDispatch()
    const popupQR = () => {
      dispatch(openModal({ title: "", component: <QRPopup /> }))
    }
  return (
    <>
        
        <div className='border w-full mt-5 rounded-[24px] h-[150px] flex justify-between items-center px-5'>
          <div className='flex flex-col'>
            <Text weight={600} size={24}>
              Gideon Piesie
            </Text>
            <Text weight={400} size={14}>
              Click on QR code to view
            </Text>
          </div>
          <img src={assets.images.qr} alt='qr' width={108} onClick={popupQR} />
        </div>

        <div className='flex justify-center mt-[40px] gap-4'>
          <div className={styles.scanqrcard}>
            <div className={styles.imgbox}>
              <img width={32} src={assets.images.scanqr} alt="" />
            </div>
            <div className='flex flex-col'>
              <Text weight={600} size={24}>
                Scan QR code
              </Text>
              <Text weight={400} size={14}>
                Scan the QR code with your device to continue.
              </Text>
            </div>
          </div>
          <div className={styles.enterposidcard}>
            <div className={styles.imgbox}>
              <img width={32} src={assets.images.cashiermachine} alt="" />
            </div>
            <div className='flex flex-col'>
              <Text weight={600} size={24}>
                Enter Your POS ID
              </Text>
              <Text weight={400} size={14}>
                Enter your POS ID to complete the setup.
              </Text>
            </div>

          </div>
        </div>

        <Link to={'/giftcard'} className='grid grid-cols-12 mt-[50px] items-center h-[100px] rounded-[24px] w-[100%] border'>
          <div className='col-span-2 flex justify-center'>
            <img src={assets.images.giftbox} alt="" width={71} /></div>
          <div className='col-span-9 flex flex-col'>
            <Text weight={600} size={24}>
              Gift cards
            </Text>
            <Text weight={400} size={14}>
              View all details of your gift cards
            </Text>
          </div>

          <BsChevronRight className='col-span-1' />
        </Link>

        <div className='flex gap-4 mt-[40px]'>
          <UseCoins />
          <UseGiftCards />
        </div>
    </>
  )
}

export default PayVendor