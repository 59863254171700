
import { Box } from '@mui/material'
import assets from 'Assets'
import { Button, Text } from 'Components'
import React, { useEffect, useState } from 'react'
import { BsCheckCircleFill, BsCircle } from 'react-icons/bs'
import { useDispatch } from 'react-redux'
import { setComponentData } from 'Store/slices/components'
import { openModal } from 'Store/slices/modalSlice'
import CancelSubscription from './CancelSubscription'

const Subscription = () => {
    const dispatch = useDispatch()
    const plans = [
        { name: "Annual", message: 'GHS 37.00/monthly billed annually', saved: '26% saved', },
        { name: "Monthly", message: 'GHS 50.00/monthly billed monthly', saved: '' },
    ]
    const [selectedPlan, setSelectedPlan] = useState('')
    useEffect(() => {
        dispatch(setComponentData({ component: 'headerTitle', data: 'Subscription' }))
    }, [dispatch])
    return (
        <div className='w-[600px] mx-auto flex flex-col gap-[25px] '>
            <img src={assets.images.reachafrkipremium} alt="" className='rounded-[12px]' />
            <Text display={'block'} weight={600} size={24}>Save with Reachafrika Premium membership</Text>
            <div className='flex flex-col gap-3'>
                <div className='flex items-center gap-4'>
                    <BsCheckCircleFill color={assets.colors.primary} />
                    <Text display={'block'} weight={400} size={16}>Create an account and move one step closer to making your life easier</Text>
                </div>
                <div className='flex items-center gap-4'>
                    <BsCheckCircleFill color={assets.colors.primary} />
                    <Text display={'block'} weight={400} size={16}>Create an account and move one step closer to making your life easier</Text>
                </div>
                <div className='flex items-center gap-4'>
                    <BsCheckCircleFill color={assets.colors.primary} />
                    <Text display={'block'} weight={400} size={16}>Create an account and move one step closer to making your life easier</Text>
                </div>
            </div>
            <div className='flex flex-col gap-3'>
                <Text display={'block'} weight={600} size={24}>Pick a subscription</Text>
                {
                    plans.map(plan => <Box onClick={() => setSelectedPlan(plan.name)} component={'div'} border={`1px solid ${selectedPlan === plan.name ? assets.colors.primary : '#E6E8F0'}`} className='flex justify-between cursor-pointer rounded-[12px] px-4 py-2 items-center w-full' >
                        <div className='flex flex-col gap-4'>
                            <div className='flex gap-2 items-center'>
                                <Text color={'#181C21'} weight={600} display={'block'} size={16}>{plan.name}</Text>
                                {plan.saved && <Box fontSize={10} display={'block'} className='py-1 px-2 rounded-[12px] bg-[#C3D9FF]'>{plan.saved}</Box>}
                            </div>
                            <Text color='#414752' weight={400} display={'block'} size={16}>{plan.message}</Text>
                        </div>
                        {selectedPlan === plan.name ? <BsCheckCircleFill size={16} color={assets.colors.primary} /> : <BsCircle color='#E6E8F0' />}
                    </Box>)
                }
            </div>
            <Button disabled={!selectedPlan} width={'100%'} r={100} >
                Subscribe GHS 37.00
            </Button>
            <Button disabled={!selectedPlan} bgcolor='#BA1A1A' width={'100%'} r={100} onClick={()=>dispatch(openModal({component:<CancelSubscription />,title:'Cancel subscription?'}))} >
            Cancel Subscription
            </Button>
        </div>
    )
}

export default Subscription