import React from 'react'
import FullPageLayout from 'Components/layout/FullPageLayout'
import DoubleTab from 'Components/tab/DoubleTab'
import { useNavigate, useParams } from 'react-router-dom'
import PayVendor from './PayVendor'
import MyApproval from './MyApproval'
import ApprovalDetails from './ApprovalDetails'


const PayVendors = () => {

  const { page = '' } = useParams()

  const navigate = useNavigate()
  const pages = [
    { name: 'Pay Vendor', key: '', onClick: () => navigate('/pay-vendors') },
    { name: 'My approvals', key: 'my-approvals', onClick: () => navigate('/pay-vendors/my-approvals') },
  ]
  return (
    page !== 'approval-details' ? <FullPageLayout headerText={'Pay Vendor'} >
      <div className='!mx-[auto] !w-[800px]'>
        <div className='w-[350px] mx-auto'>
          <DoubleTab tabs={pages} active={page} />
        </div>

        {(page === '') && <PayVendor />}
        {(page === 'my-approvals') && <MyApproval />}
        {(page === 'approval-details') && <ApprovalDetails />}
      </div>
    </FullPageLayout>

      :
      <FullPageLayout headerText={'Approval Details'} >
        <div className='!mx-[auto] !w-[500px]'>
          <ApprovalDetails />
        </div>
      </FullPageLayout>
  )
}

export default PayVendors