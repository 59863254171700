/**
 * @param {Boolean} enabled
 * @param {Function} onComplete
 */

import { useEffect, useState } from "react"
import { apiRequest } from "Services"

export const useFetch = ({ url, enabled = true, onComplete = () => null, version, useLoadingScreen=false }) => {

    const [data, setData] = useState(null)
    const [error, setError] = useState(null)
    useEffect(() => {
        const fetchData = async () => {
            try {
                const { data: res } = await apiRequest({ url,version, useLoadingScreen })
                setData(res)
                onComplete(res)
            } catch (error) {
                setError(error)
            }
        }
        if (url && enabled) fetchData()
        // eslint-disable-next-line  
    }, [])
    return {
        data, error
    }
}

