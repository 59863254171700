import { createSlice } from '@reduxjs/toolkit';

export const componentSlice = createSlice({
    name: 'component',
    initialState: {
        headerTitle:'',
    },

    reducers: {
        
        setComponentData: (state, { payload }) => {
            state[payload.component] = payload.data
        }
    }
});

// this is for dispatch
export const { setComponentData } = componentSlice.actions;

// this is for configureStore
export default componentSlice.reducer;
