import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setComponentData } from 'Store/slices/components';
// import Header from '../../Components/header';
import img from './Pictures/credit-card.png';
import money from './Pictures/money 1.png';
import vector from './Pictures/Vector.png';
import Table from '../../Components/table';
import EmptyState from 'Components/EmptyState';
import { GoArrowUp } from "react-icons/go";
// import TransactionModal from '../../Components/Modal/TransactionModal'; 
import Modal from 'Components/Modal';
import { useWalletInfo } from 'hooks';
import { useTransactions } from 'hooks/useTransactions';
import { openModal } from 'Store/slices/modalSlice';
import Topup from 'Partials/Topup';

const Transactions = () => {

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(setComponentData({ component: 'headerTitle', data: 'Transactions' }));
  }, [dispatch]);
  
  
  const { walletInfo } = useWalletInfo()
  const { transactions } = useTransactions()
  const columns = [
    { Header: 'Date', accessor: 'date' },
    { Header: 'Coins Used', accessor: 'coinsUsed' },
    { Header: 'Coins Earned', accessor: 'coinsGained' },
    { Header: 'Amount', accessor: 'amount' },
    { Header: 'Status', accessor: 'statusText' },
    { Header: 'Description', accessor: 'description' }
  ];

  return (
    <div>
      {/* <Header name={'Transactions'} /> */}
      <div className="flex flex-wrap gap-6 p- ">
        <div className="flex-1 min-w-[calc(50%-1rem)] max-w-md rounded-lg shadow-lg overflow-hidden relative ">
          <div className="absolute inset-0 bg-[linear-gradient(298.54deg,_#074294_0%,_#529CE4_100%)]"></div>
          <div className="relative p-4 text-white z-10 my-">
            <div className="flex items-center justify-between mb-2">
              <p className="text-xl font-bold flex items-center gap-2">
                <img src={img} alt="Credit Card" />
                BALANCE
              </p>
              <button onClick={() => dispatch(openModal({ title: 'Select method', component: <Topup /> }))} className="flex items-center gap-2 px-4 py-2 font-bold rounded-full text-[#294265] bg-[#C3D9FF] border border-[#294265] hover:bg-[#b3c9ff] hover:text-[#294265]">
                <GoArrowUp className="font-bold text-[#294265] border border-[#294265] p-1 rounded-none" />
                Top up
              </button>
            </div>
            <h2 className="font-bold mt-2  text-5xl">&#8358;{walletInfo?.balance}</h2>
          </div>
        </div>

        <div className="flex-1 min-w-[calc(50%-1rem)] max-w-md bg-[#3082D4] rounded-lg shadow-lg overflow-hidden relative ">
          <div className="relative p-4 text-white z-10">
            <div className="flex items-center justify-between mb-8">
              <p className="text-xl font-bold flex items-center gap-2">
                <img src={money} alt="" />
                REWARDS
              </p>
              <button className="flex items-center gap-2 px-4 text-xs rounded-full text-white bg-[#3082D4] border border-[#1D72D2] shadow-lg hover:bg-[#1D72D2]">
                <img src={vector} alt='' className="" />
                100 COINS = NGN 1.00
              </button>
            </div>
            <h2 className="font-bold mb-2 text-5xl">{walletInfo.reward.coins}</h2>
          </div>
        </div>
      </div>
      <h1 className="font-bold my-5 text-lg">Transactions</h1>
      {
        transactions?.length === 0 ?
          <EmptyState text={'No transaction has been made'} />
          :
          <Table columns={columns} data={transactions} />
      }
      <Modal />
    </div>
  );
};

export default Transactions;
