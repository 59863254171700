import { Text } from 'Components'
import React from 'react'
import { Avatar, Rating as StarRating } from '@mui/material'


const Rating = () => {
    return (
        <div className='w-full flex flex-col'>
            <div className='border-b w-full py-5'>
                <div className='flex gap-3'>
                    <Avatar />
                    <div>
                        <Text size={16} weight={400}>User name</Text>
                        <Text size={12} color={'#414752'} display={'block'} >13 August 2024</Text>
                    </div>
                </div>
                <StarRating className='mt-3' readOnly value={3} /><br />
                <Text>Melcom Limited is Ghana's largest chain of retail department stores. Our Head Office is located at 2nd Palace Link Road, North Industrial Area, Accra, Ghana. </Text>

            </div>
        </div>
    )
}

export default Rating