import { Typography } from '@mui/material'
import React from 'react'

const Text = ({ children, size, color, weight, className, display, align, lH, isLink = false, onClick = () => null, href = "", bgcolor='inherit'}) => {
    // const navigate
    // const onClick = () => {

    // }
    const addLink =  href ? { href } : {}
    return (
        <Typography
        
            component={isLink ? 'a' : 'span'}
            {...addLink}
            sx={{
                color,
                fontSize: size,
                fontWeight: weight,
                cursor: isLink ? "pointer" : 'auto',
                fontFamily: 'Poppins',
                display: display || 'inline',
                textAlign: align || 'left',
                lineHeight: lH,
                bgcolor,
            }}
            className={className}
            onClick={onClick}
        >
            {children}
        </Typography>
    )
}

export default Text