import React from 'react'
import Text from 'Components/Text'
import styles from '../styles/home.module.scss'
import assets from 'Assets'
import { Link } from 'react-router-dom'
const MyPayments = () => {


    return (
        <div className={styles.MyPayments}>
            <Text display={'block'} className={styles.headerText}>
            MY PAYMENTS
            </Text>
            <div className={styles.flex_div}>
                <Link to={'/pay-vendors'} className={`${styles.card} ${styles.payVendor}`}>
                    <img src={assets.images.money} className={styles.icon} alt="" />
                    <Text className={styles.text}>
                    Pay Vendors
                    </Text>
                </Link>
                <Link to={'/our-vendors'} className={`${styles.card} ${styles.viewVedorShop}`}>
                    <img src={assets.images.storefront} className={styles.icon} alt="" />
                    <Text className={styles.text}>
                    View Vendor Shops
                    </Text>
                </Link>
            </div>
        </div>
    )
}

export default MyPayments