import { createSlice } from '@reduxjs/toolkit';

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        user: {
            token:'',
            user:{}
        },
        signupData: {
            firstName: 'dd',
            lastName: '',
            email: '',
            password: '',
            confirmPassworrd: '',
            phone: '',
            otp: ''
        },
        currentSignupForm: null,
        error: null
    },

    reducers: {
        setUser: (state, { payload }) => {
            if (payload) {
                console.log({ payload })
                state.user = payload
                // state.user.name = payload?.firstName + " " + payload?.lastName;
                // state.user.loggedInAt = Date.now()
            }
        },
        setSignupData: (state, { payload }) => {
            state.signupData = { ...state.signupData, ...payload }
        },
        clearSignupData: (state) => {
            state.signupData = {}
        },
        setCurrentSignupForm: (state, { payload }) => {
            state.currentSignupForm = payload
        },
        logout: (state, { payload }) => {
            state.user = {}
            window.location.assign('/login')
        },
        reuse: (state, { payload }) => {
            state.user.lastUsedApp = Date.now()
        },
        refreshTokens: (state, { payload }) => {
            state.user.access_token = payload?.access_token
            state.user.refresh_token = payload?.refresh_token
            state.user.loggedInAt = Date.now()
        },
        setErrors: (state, { payload }) => {
            state.error = payload
        },
        clearErrors: (state) => {
            state.error = []
        }
    }
});

// this is for dispatch
export const { setUser, logout, reuse, refreshTokens, setSignupData, setCurrentSignupForm, clearSignupData } = authSlice.actions;

// this is for configureStore
export default authSlice.reducer;
