
import { Avatar } from '@mui/material'
import { Text } from 'Components'
import ButtonStack from 'Components/Button/ButtonStack'
import FullPageLayout from 'Components/layout/FullPageLayout'
import React from 'react'
import { BsSearch } from 'react-icons/bs'
import { PiSlidersHorizontal } from 'react-icons/pi'
import styles from './ourvendors.module.scss'
import { GiAfrica } from "react-icons/gi";
import assets from 'Assets'
import { Link } from 'react-router-dom'

const OurVendor = () => {

    const btns = [
        { text: 'All', onClick: () => null, value: 'All' },
        { text: 'Restaurant', onClick: () => null, value: 'Restaurant' },
        { text: 'Supermarket', onClick: () => null, value: 'Supermarket' },
        { text: 'School', onClick: () => null, value: 'School' },
        { text: 'Pharmacy', onClick: () => null, value: 'Pharmacy' },
        { text: 'University', onClick: () => null, value: 'University' },
        { text: 'Clinic', onClick: () => null, value: 'Clinic' },
    ]
    const vendorCategories = [
        {
            category: "Restaurants", vendors: [
                { name: 'Burger King', cashback: 50, }
            ]
        },
        {
            category: "Supermarket", vendors: [
                { name: 'Burger King', cashback: 50, }
            ]
        },
        {
            category: "Filling stations", vendors: [
                { name: 'Burger King', cashback: 50, }
            ]
        },
        {
            category: "Others", vendors: [
                { name: 'Burger King', cashback: 50, }
            ]
        }
    ]
    return (
        <FullPageLayout headerText={'Vendor Shops'}

            rightComponent={<>
                <div className='flex gap-2 items-center'>
                    <Avatar sx={{ bgcolor: '#C3D9FF' }}>
                        <BsSearch color='#294265' />
                    </Avatar>
                    <Avatar sx={{ bgcolor: '#C3D9FF' }}>
                        <PiSlidersHorizontal color='#294265' />
                    </Avatar>
                </div>
            </>}>
            <div className='grid mx-auto'>
                <ButtonStack btns={btns} />

                <div className='flex flex-col gap-5 mt-[30px]'>
                    {
                        vendorCategories.map(vendorCategory => {
                            return <Link to={'/our-vendors/burger-king'}>
                                <Text size={20} display={'block'} className={'mb-3 !block'} weight={600}>{vendorCategory.category}</Text>

                                <div className={styles.vendorCards}>
                                    <div className={styles.vendorCard}>
                                        <div className={styles.cashback}>
                                            <Text weight={600} size={12} color={'#05213D'}>
                                                50% CASHBACK
                                            </Text>

                                            <div className={styles.africamap}>
                                                <GiAfrica color='white' />
                                            </div>
                                        </div>
                                        <div className='flex w-[95%] justify-between'>
                                            <div>
                                                <Text display={'block'} color={'white'} weight={600} size={20}>Burger King</Text> 
                                      
                                                <Text display={'block'} color={'white'} weight={600} size={14}>4.4 (123,993)</Text>
                                                <Text display={'block'} color={'white'} weight={600} size={14}>12.3 km away • Closed</Text>
                                            </div>
                                            <img src={assets.images.burgerkingdemo} className='rounded-[12px]' width={65} height={60} alt='vendor' />
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        })
                    }
                </div>
            </div>
        </FullPageLayout>
    )
}

export default OurVendor