// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_mobiledataCard__YG3hA {
  height: 150px;
  padding: 10px 10px;
  width: 45%;
  border-radius: 20px;
  background: rgb(236, 237, 246);
}

.styles_container__9-L93 {
  display: flex;
  width: min(900px, 100%);
  margin: auto;
  gap: 0px;
}
@media screen and (max-width: 700px) {
  .styles_container__9-L93 {
    display: block;
  }
}`, "",{"version":3,"sources":["webpack://./src/Pages/Home/Freebies/styles.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,kBAAA;EACA,UAAA;EACA,mBAAA;EACA,8BAAA;AACJ;;AAGA;EACI,aAAA;EACA,uBAAA;EAKA,YAAA;EACA,QAAA;AAJJ;AADI;EAHJ;IAIQ,cAAA;EAIN;AACF","sourcesContent":[".mobiledataCard {\n    height: 150px;\n    padding: 10px 10px;\n    width: 45%;\n    border-radius: 20px;\n    background: rgba(236, 237, 246, 1);\n    // width: 49%;\n}\n\n.container {\n    display: flex;\n    width: min(900px, 100%);\n    @media screen and (max-width:700px) {\n        display: block;\n    }\n    // width: 70%;\n    margin: auto;\n    gap: 0px;\n    // background-color: black;\n    // border: 1px solid red;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mobiledataCard": `styles_mobiledataCard__YG3hA`,
	"container": `styles_container__9-L93`
};
export default ___CSS_LOADER_EXPORT___;
