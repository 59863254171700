import assets from 'Assets'
import { Button, Input, Text } from 'Components'
import React from 'react'
import { Link } from 'react-router-dom'

const EnterPassword = () => {
    return (
        <div className='flex flex-col items-center justify-center w-[300px] mx-auto h-[200px]  gap-4'>
            <Input label='Password' type='password' />
            <Link to=''>  <Text color={assets.colors.primary}>Forgot Password</Text></Link>
            <Button r={100} width={'100%'}>
                Verify
            </Button>
        </div>
    )
}

export default EnterPassword