import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const getRequestSlice = createApi({
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_BASE_URL_V2,
        prepareHeaders: (headers, { getState }) => {
            const token = getState().auth.user.token

            // Authorization: `Bearer ${token}`,
            // "Content-Type": contentType,
            // "Accept": "application/json",
            // "api-user-agent": "reachafrika.com",
            // "salt": "reachafrika",
            if(token) headers.set('Authorization',`Bearer ${token}`)
            return headers
        }
    }),
    endpoints: (builder) => ({
        getShopProducts: builder.query({
            query: () => `/shop/products?lang=en`,
        }),
    
    }),
});
const { useGetShopProductsQuery } = getRequestSlice


export { getRequestSlice, useGetShopProductsQuery }

