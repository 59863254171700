import React from 'react'
import styles from './button.module.scss'
import { Box } from '@mui/material'
import Assets from 'Assets'
// import { colors } from 'Assets'

const Button = ({ children, r, className, bgcolor = Assets.colors.primary, color, shadow, height, width, onClick, loading = false, disabled, type = 'button', style }) => {
    return (
        <>

            <Box
                className={`${styles.button} px-2 ${className}`}
                component={'button'}
                style={{
                    whiteSpace: 'nowrap',
                    borderRadius: r || '10px',
                    backgroundColor: bgcolor,
                    color: color || 'white',
                    // boxShadow: shadow ? `0px 8px 25px 0px ${shadow || colors.rada_light_blue}` : '',
                    height: height || '41px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width, cursor: loading || disabled ? "auto" : 'pointer',
                    fontWeight: '600',
                    opacity: loading || disabled ? .4 : 1,
                    ...style

                }}
                disabled={disabled}
                onClick={onClick}
                type={type}
            >
                {loading ? 'Loading...' : children}
            </Box></>
    )
}

export default Button