import React from 'react'

const About = () => {
    return (
        <div>
            <p>
                Melcom Limited is Ghana's largest chain of retail department stores. Our Head Office is located at 2nd Palace Link Road, North Industrial Area, Accra, Ghana. The major portion of our business is retail and we are also highly placed in semi-wholesale and wholesale distribution due to the vast range of goods we stock.
                <br /> <br />
                There are several contributing factors that have made Melcom the household name it is today. The Group is committed to providing:‰
            </p>
<br /><br />
            <p>
                <ol>
               <li>
                Great service with a smile</li> 
           <li>  Unbeatable value for money
            </li>   
               <li> Quality merchandise</li>
               <li> The largest variety of goods under one roof.</li>
            </ol>
            </p>
<br /> <br />
            <p>
                By sourcing products from all over the world, we are able to offer shoppers a huge ra. In keeping with the Group's continuous efforts to provide Ghana with the paramount shopping experience, we have also recognized the need to keep up with the shifts in consumer trends.
<br /> <br />
                Today, Melcom maintains its position as the fastest growing chain store in Ghana, and boasts the largest number of outlets spread throughout the country.
            </p>
        </div>
    )
}

export default About