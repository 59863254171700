import Assets from 'Assets'
import { Input, RAForm } from 'Components'
import AuthLayout from 'Components/layout/AuthLayout'
import Text from 'Components/Text'
import React from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { setUser } from 'Store/slices/auth'

const Signin = () => {
  const dispatch = useDispatch()
  const navigate =  useNavigate()
  const signin = (res) => {
    dispatch(setUser(res?.data))
    navigate('/')
  }
  return (
    <AuthLayout>
      {/* <div className='lg:w-[450px] xs:w-[90%]'> */}
      <div className='flex flex-col gap-4 items-center lg:w-[450px] xs:w-[90%]'>
        <Text size={'40px'} weight={600}>
          Sign in
        </Text> <Text size={'14px'} weight={400}>
          Welcome to Reachafrika, we make your lives easier
        </Text>

        <RAForm version='v1' className={'mt-4 gap-[40px] flex flex-col !w-[100%]'} url={'/login'} btnClass={'h-[56px] !rounded-full'}
          onError={(e) => toast.error(e?.response?.data?.error || 'An error occured')} onSuccess={signin}
          btnText={'Login'}>
          <div className='!w-[100%] flex flex-col gap-4'>
            <Input label={'Email'} placeholder="example@company.com" name='email' />
            <Input label={'Password'} placeholder="************" name='password' type='password' />
          </div>
          <div className='w-100 flex justify-center mt-[50px]'>
            <Text isLink href='/forgot-password' weight={600} color={Assets.colors.primary} >
              Forgot password?
            </Text>
          </div>
        </RAForm>

        <div className='flex mt-3 gap-4'>
          <Text isLink weight={600} href='/signup'>
            Don’t have an account?
          </Text>
          <Text isLink href='/signup' weight={600} color={Assets.colors.primary} >
            Create account
          </Text>
        </div>
      </div>
      {/* </div> */}
    </AuthLayout>
  )
}
export default Signin
