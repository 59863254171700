import React from 'react';
import styles from './emptystate.module.scss';
import Text  from '../Text';
 const EmptyState = ({ icon , text, children}) => {
    return (
        <div className={styles.emptystate}>
            <div className={styles.center}>
                <div className={styles.iconCircle}>
                    {icon}
                </div>
                <Text>{text}</Text>
                <div className={styles.children}>
                {children}
            </div>
            </div>
            
        </div>

    )
}

export default EmptyState;