import React, { useState } from 'react';
import styles from './input.module.scss'
import { Box, Switch } from '@mui/material';
import OtpInput from 'react-otp-input';
import RadaSelect from './Select';
import PhoneInput from './PhoneInput';
import { Visibility, VisibilityOff } from '@mui/icons-material';
// import CheckInput from './CheckInput';

const OTPInput = ({ onChange, name, numInputs = 4 }) => {
    const [otp, setOtp] = useState()
    return <>
        {/* <input type="text" hidden name={name} value={otp} /> */}
        <OtpInput
            value={otp}
            onChange={(value) => {
                setOtp(value); onChange({ target: { name, value } })
            }}
            containerStyle={{ width: '100%' }}
            inputStyle={{ width: '50px', textAlign: 'center', border:'.1px grey solid', borderRadius:'4px' }}
            numInputs={numInputs}
            shouldAutoFocus
            skipDefaultStyles
            renderSeparator={<span className='mx-3'></span>}
            renderInput={(inputProps) => <input  {...inputProps} />}
        /></>

}


const Input = ({
    label,
    type = 'text',
    containerClass,
    onChange = () => null,
    getObj = () => null,
    inputClass,
    ...props
}) => {
    const change = (e) => {
        onChange(e)
        getObj({ [e.target.name]: e.target.value })
    }

    const input_id = 'input-id'
    const defaults = ['text', 'password', 'email', 'number']
    const [seePass, setSetPass] = useState()
    return (
        <Box className={`${styles.container} ${containerClass}`}>
            <Box component={'label'} className='block mb-1' htmlFor={input_id}>
                {label}
            </Box>
           
                {type === 'otp' && <OTPInput onChange={onChange} {...props} />}
                {type === 'select' && <RadaSelect {...props} onChange={onChange} />}
                {type === 'phone' && <PhoneInput onChange={onChange}  {...props} />}
                {type === 'switch' && <Switch name={props.name} inputProps={{ "aria-label": label }} defaultChecked onChange={onChange} {...props} />}
                {/* {type === 'checkbox' && <CheckInput {...props} />} */} 

                {defaults.includes(type) &&  <div className={styles.inputBox}><input
                    type={seePass ? 'text' : type}
                    onChange={change}
                    className={inputClass}
                    onKeyPress={(e) => {
                        if (!/[0-9]/.test(e.key) && type === 'number') {
                            e.preventDefault();
                        }
                    }}
                    {...props}
                />   {type === 'password' && <Box className='cursor-pointer' onClick={() => setSetPass(prev => !prev)}>
                {seePass ? <Visibility /> : <VisibilityOff />}
            </Box>}
        </div>}
             
        </Box>
    )
}

export default Input