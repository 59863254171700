import React from 'react'
import { Input, RAForm } from 'Components'
import { getResponseErrors } from 'helpers/getResponseError'
import { useDispatch } from 'react-redux'
import { closeModal } from 'Store/slices/modalSlice'
import { toast } from 'react-toastify'

const ChangePin = () => {
    const dispatch = useDispatch()
    return (
        <RAForm btnText={'Change Pin'} btnClass={'w-full mt-[30px]'} url={'/change-pin'} version='v1' onError={(err) => {
            getResponseErrors({ error: err, useToast: true })
        }} onSuccess={() => {
            toast.success('Successful')
            dispatch(closeModal())
        }}>

            <div className=' flex flex-col gap-[10px] w-[100%]'>
                <Input placeholder={'Current pin'} required type='password' name='current' />
                <Input placeholder={'New pin'} required type='password' name='pin' />
                <Input placeholder={'Confirm pin'} required type='password' name='pin_confirmation' />
            </div>

        </RAForm>
    )
}

export default ChangePin