import FullPageLayout from 'Components/layout/FullPageLayout'
import React, { useMemo } from 'react'
import styles from './styles.module.scss'
import assets from 'Assets'
import { Link } from 'react-router-dom'
import Text from 'Components/Text'
import { useDispatch } from 'react-redux'
import { openModal } from 'Store/slices/modalSlice'
import SelectAirtimePurhase from './components/SelectAirtimePurchase'
// import { useFetch } from 'hooks'
import { useGetShopProductsQuery } from 'Store/slices/getSlice'

const Shop = () => {
    const dispatch = useDispatch()
    // const { data } = useFetch({ url: '/shop/products?lang=en' })
    const { data } = useGetShopProductsQuery()
    // console.log(s.data?.data?.products)
    // console.log(data?.products)
    const shops = useMemo(() => {
        return [
            {
                title: 'Airtime', img: assets.images.shop.airtime,
                path: '', name: 'airtime',
                onClick: () => dispatch(openModal({ title: '', component: <SelectAirtimePurhase /> }))
            },
            {
                title: 'Data', img: assets.images.shop.data,
                name: 'data_bundle', path: '/data', onClick: () => null
            },
            {
                title: 'Cable TV', img: assets.images.shop.cabletv,
                name: 'cable_tv', path: '/cable-tv', onClick: () => null
            },
            {
                title: 'Power', img: assets.images.shop.power,
                name: 'power', path: '/power', onClick: () => null
            },
            {
                title: 'Movies', img: assets.images.shop.movies,
                name: 'movies', path: '/movies', onClick: () => null
            },
            {
                title: 'Education', img: assets.images.shop.education,
                name: 'education', path: '/education', onClick: () => null
            },
            {
                title: 'Broadband', img: assets.images.shop.broadband,
                name: 'broadband', path: '/broadband', onClick: () => null
            },
            {
                title: 'TollBooth', img: assets.images.shop.tollbooth,
                name: 'tollbooth', path: '/tollbooth', onClick: () => null
            },
            {
                title: 'Airline', img: assets.images.shop.airline,
                name: 'airlines', path: '/airline', onClick: () => null
            },
            {
                title: 'Corporate Data', img: assets.images.shop.corporatedata,
                name: 'corporate', path: '/corporate-data', onClick: () => null
            },
        ]
    }, [dispatch])
    return (
        <FullPageLayout headerText={'Shop'} backPath='/'>
            <div className={styles.shopContainer}>
                {
                    shops.filter(shop => data?.data?.products?.map(product => product?.name).includes(shop.name)).map(({ title, img, path, onClick }) => (
                        <Link to={`/shop${path}`} className='flex justify-center' onClick={onClick}>
                            <div className={styles.shopCard}>
                                <div className={styles.shopIconCircle}>
                                    <img src={img} alt={title} />
                                </div>
                                <Text size={20} weight={600} > {title} </Text>
                            </div>
                        </Link>
                    ))
                }
            </div>
        </FullPageLayout>
    )
}

export default Shop