import React from 'react'
import { Input } from 'Components'
import Text from 'Components/Text'
import assets from 'Assets'
import { useWalletInfo } from 'hooks'

const UseCoins = ({ required, name = "usecoins", onChange = () => null }) => {
    const { walletInfo } = useWalletInfo()
    return (
        <div className='border p-3 grid grid-cols-12 rounded-[12px] !h-[100px]'>
            <div className='col-span-2 self-start h-[38px] w-[38px] flex items-center self-center justify-center !bg-[#F2F3FC] rounded'>
                <img src={assets.images.buymorecoins} width={24} height={24} alt="" />
            </div>
            <div className='col-span-8 flex flex-col justify-between'>
                <Text color={'#181C21'} weight={600} size={16} className={'opacity-[.7]'}>Use coins ({walletInfo.reward.coins}) </Text>
                <Text color={'#414752'} weight={400} size={12} className={'opacity-[.7]'}>Coins are used to reduce your expenses</Text>
                <div className='flex gap-2'>
                    <img src={assets.images.coinstack} width={10} height={10} alt="" />
                    <Text color={'#414752'} weight={400} size={12} className={'opacity-[.7] flex gap-2'}>
                        100 coins = NGN 1.00
                    </Text>
                </div>

            </div>
            <div className='col-span-2 self-center'>
                <Input type='switch' required={required} name={name} disabled={!walletInfo.reward.coins} onChange={(e)=>{ }} defaultChecked={false} />
                {/* <input type='hidden' value={walletInfo.reward.balance} name='coins'/> */}
            </div>

        </div>
    )
}

export default UseCoins