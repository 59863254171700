import { useEffect, useState } from 'react'
import { apiRequest } from 'Services'

const useBeneficiaries = (product = '') => {
    const [beneficiaries, setBeneficiaries] = useState([])
    useEffect(() => {
        const getbeneficiaries = async () => {
            try {
                const { data } = await apiRequest({ url: `/shop/beneficiaries?product=${product}` })
                // console.log(data)
                setBeneficiaries(data)
            } catch (error) {

            }
        }
        getbeneficiaries()
    }, [product])
    return {
        beneficiaries
    }
}

export default useBeneficiaries