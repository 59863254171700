import Text from 'Components/Text'
import React from 'react'
import { BsChevronRight, BsCreditCard } from 'react-icons/bs'
import { PiDeviceMobileCamera } from "react-icons/pi";
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { closeModal } from 'Store/slices/modalSlice';

const SelectPaymentMethod = ({ onMobileMoney = () => null, onBankTransfer = () => null, onComplete = () => null, onError = () => null }) => {
    const dispatch = useDispatch()
    const closeModal_ = () => {
        dispatch(closeModal())
    }
    const bankTransfer = () => {
        onBankTransfer()
        closeModal_()
        onComplete()
    }
    const mobileMoney = () => {
        onMobileMoney()
        closeModal_()
        // onComplete()
        onError()
    }
    return (
        <div className='xs:w-[95%] sm:w-[100%] mx-auto lg:w-[100%]'>
            <div className='flex flex-col items-center gap-4 mb-[40px]'>
                <Text size={20} weight={600}>Select Payment Method</Text>
                <Text size={12} weight={400} display={'block'} className={'w-[80%]'} align={'center'}>
                    Choose your preferred payment method from the options below.</Text>
            </div>
            <div className='flex flex-col gap-4 pb-4'>
                <Link onClick={mobileMoney} className='border grid grid-cols-12 w-[90%] mx-auto items-center rounded-[12px] gap-3 h-[94px] cursor-pointer'>
                    <PiDeviceMobileCamera size={24} className='justify-self-center col-span-2' />
                    <div className=' col-span-8'>
                        <Text display={'block'} weight={600} size={16}>
                            Mobile Money Transfer
                        </Text>
                        <Text display={'block'} weight={400} size={12}>
                            Pay with your mobile money account.
                        </Text>
                    </div>
                    <BsChevronRight className='justify-self-center col-span-2' />
                </Link>
                <Link onClick={bankTransfer} className='border grid grid-cols-12 w-[90%] mx-auto items-center rounded-[12px] gap-3 h-[94px] cursor-pointer'>
                    <BsCreditCard size={24} className='justify-self-center col-span-2' />
                    <div className=' col-span-8'>
                        <Text display={'block'} weight={600} size={16}>
                            Bank Card
                        </Text>
                        <Text display={'block'} weight={400} size={12}>
                            Use your debit or credit card.
                        </Text>
                    </div>
                    <BsChevronRight className='justify-self-center col-span-2' />
                </Link>
            </div>
        </div>
    )
}

export default SelectPaymentMethod