import React from 'react'
import styles from './payvendor.module.scss'
import assets from 'Assets'
import { Text } from 'Components'
import { Link } from 'react-router-dom'


const approval = {
    "data": [
        {
            "id": 3,
            "amount": 67,
            "formatted_amount": "GH₵ 67.00",
            "status": "pending",
            "currency": "GHS",
            "pos": {
                "name": "Madina Pos #3234",
                "code": "93515656",
                "id": 1,
                "merchant": {
                    "name": "Frank Building Materials"
                },
                "branch": {
                    "name": "Kumasi Mall Branch"
                }
            },
            "created_at": "Feb 14, 2022",
            "human_date": "1 month ago"
        },
        {
            "id": 2,
            "amount": 24,
            "formatted_amount": "GH₵ 24.00",
            "status": "pending",
            "currency": "GHS",
            "pos": {
                "name": "Madina Pos #3234",
                "code": "93515656",
                "id": 1,
                "merchant": {
                    "name": "Frank Building Materials"
                },
                "branch": {
                    "name": "Kumasi Mall Branch"
                }
            },
            "created_at": "Feb 14, 2022",
            "human_date": "1 month ago"
        },
        {
            "id": 1,
            "amount": 78,
            "formatted_amount": "GH₵ 78.00",
            "status": "pending",
            "currency": "GHS",
            "pos": {
                "name": "Madina Pos #3234",
                "code": "93515656",
                "id": 1,
                "merchant": {
                    "name": "Frank Building Materials"
                },
                "branch": {
                    "name": "Kumasi Mall Branch"
                }
            },
            "created_at": "Feb 14, 2022",
            "human_date": "1 month ago"
        }
    ]
}
const MyApproval = () => {
    return (
        <Link to={'/pay-vendors/approval-details'} className={styles.myApproval}>
            <div className='flex flex-col justify-between h-full'>
                <div className='flex gap-3'>
                <Text display={'flex'} className={styles.status} weight={600}>Pending</Text>   <Text weight={600}>Aug 11, 2024</Text>
                </div>
                <div>

                    <Text weight={600} size={24} display={'block'}>   Total: ₦ 1000</Text>
                    <Text>Make payment to [Mr Price] for your transactions</Text>
                </div>

            </div>

            <img src={assets.images.testvendorimage} width={97} height={97} alt="" />
        </Link>
    )
}

export default MyApproval