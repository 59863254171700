import { Input, RAForm, Text } from 'Components'
import FullPageLayout from 'Components/layout/FullPageLayout'

import React from 'react'

// const Screens = Object.freeze({ "OTP": 0, "CHOOSE_RECIPIENT": 1, "ADD_RECIPIENT": 2, "FAILED": 3 });
const MobileMoney = () => {

    return (
        <FullPageLayout headerText={'Mobile Money Transfer'}>
            <RAForm className='mx-auto w-[300px] flex gap-[100px] mt-[200px] flex-col items-center ' btnText={'Proceed'} btnClass={'w-full'}>
                <Text display={'block'} weight={600} size={36}>Enter Pin</Text>
                <Input type='otp' />
            </RAForm>
        </FullPageLayout>
    )
}

export default MobileMoney