import Assets from 'Assets'
import { Button, Input, RAForm } from 'Components'
import AuthLayout from 'Components/layout/AuthLayout'
import Text from 'Components/Text'
import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { clearSignupData, setCurrentSignupForm, setSignupData } from 'Store/slices/auth'
import { BsArrowLeft } from "react-icons/bs";
import CircularProgressWithLabel from './CircularProgress'
import CheckInput from 'Components/Input/CheckInput'
import { apiRequest } from 'Services'
import * as yup from 'yup';
import { toast } from 'react-toastify'
import { Alert } from '@mui/material'
import { useFetch } from 'hooks/useFetch'
import { useNavigate } from 'react-router-dom'

const Navigation = ({ onBack = () => null, progress = 0 }) => {
  return <div className='flex justify-between !w-[100%]'>
    <div onClick={onBack}
      className='flex justify-center border !w-[40px] !h-[40px] rounded-full p-1 items-center cursor-pointer ' style={{ backgroundColor: 'rgba(195, 217, 255, 1)' }}>
      <BsArrowLeft size={16} />
    </div>

    <div>
      <CircularProgressWithLabel progress={progress} />
    </div>
  </div>
}
const ErrorTab = ({ message }) => {
  <Alert sx={{ width: '100%' }} hidden={!message} severity='error'  > {message}</Alert>
}
const StepOne = () => {
  const dispatch = useDispatch()
  const { signupData, currentSignupForm } = useSelector(state => state.auth)

  const schema = yup.object().shape({
    email: yup.string().email().required(),
    password: yup.string().required().min(6),
    confirmPassword: yup.string().required('Confirm password is required').oneOf([yup.ref('password'), null], 'Passwords must match'),
  });
  const submit = async (fields) => {
    try {
      await schema.validate(signupData)
      const { data } = await apiRequest({ url: '/valid-email?lang=en', version: 'v1', method: 'post', payload: { email: signupData?.email } })
      if (data?.status === false) throw new Error('This email is taken !')
      dispatch(setCurrentSignupForm(currentSignupForm + 1))
    } catch (error) {
      console.log(error)
      if (error.message) toast.error(error.message)
    }
  }
  const handleChange = (e) => {
    const field = e.target.name
    const value = e.target.value
    dispatch(setSignupData({ [field]: value }))
  }

  return (
    <>
      <Text size={'40px'} weight={600}>
        Create An Account
      </Text>
      <Text size={'14px'} weight={400}>
        Create an account and move one step closer to making your life easier
      </Text>
      <ErrorTab message={'Details missing'} />
      <RAForm className={'mt-4 gap-[40px] flex flex-col !w-[100%] overflow-y-scroll max-h-[400px]'} btnClass={'h-[56px] !rounded-full'} btnText={'Continue'} onSubmit={submit}>
        <div className='!w-[100%] flex flex-col gap-4'>
          <Input label={'Email'} defaultValue={signupData?.email} name={'email'} placeholder="example@company.com" onChange={handleChange} />
          <Input label={'Password'} name='password' defaultValue={signupData?.password} placeholder="*******" onChange={handleChange} type='password' />
          <Input label={'Confirm Password'} name='confirmPassword' placeholder="*******" defaultValue={signupData?.confirmPassword} onChange={handleChange} type='password'/>
        </div>
      </RAForm>

      <div className='flex mt-3 gap-4'>
        <Text isLink weight={600} href='/signin'>
          Already have an account?
        </Text>
        <Text isLink weight={600} color={Assets.colors.primary} href='/signin'>
          Sign in
        </Text>
      </div>
    </>
  )
}
const StepTwo = () => {
  const dispatch = useDispatch()

  const { signupData, currentSignupForm } = useSelector(state => state.auth)
  const { data: profiles } = useFetch({ url: '/auth/prerequisites' })


  const handleChange = (e) => {
    const isCheckbox = (e.target.type === 'checkbox')
    const field = e.target.name
    const value = isCheckbox ? e.target.checked : e.target.value
    dispatch(setSignupData({ [field]: value }))
  }
  const countries = useMemo(() => {
    return [
      'Nigeria', 'Ghana', 'Togo'
    ].map(country => ({ label: country, value: country }))
  }, [])

  const schema = yup.object().shape({
    firstname: yup.string().required('First name is required'),
    lastname: yup.string().required('Last name is required').min(3),
    country: yup.string().required('Country is required'),
    iagree: yup.boolean().isTrue('Agree to terms and condition before proceeding')
  });
  const submit = async (fields) => {
    try {
      await schema.validate(signupData)
      dispatch(setCurrentSignupForm(currentSignupForm + 1))
    } catch (error) {
      if (error.message) toast.error(error.message)
    }
  }
  return <>
    <Navigation onBack={() => dispatch(setCurrentSignupForm(currentSignupForm - 1))} progress={50} />

    <Text size={'28px'} weight={600}>
      Let’s get started by filling this account information
    </Text>

    <RAForm className={'mt-4 gap-[40px] flex flex-col !w-[100%]'} btnClass={'h-[56px] !rounded-full'} btnText={'Continue'} onSubmit={submit}>
      <div className='!w-[100%] flex flex-col gap-4'>
        <Input label={'First Name'} name={'firstname'} defaultValue={signupData?.firstname} placeholder="John" onChange={handleChange} />
        <Input label={'Last Name'} name={'lastname'} defaultValue={signupData?.lastname} placeholder="Doe" onChange={handleChange} />
        {/* <Input label={'phone'} name={'phone'} defaultValue={signupData?.phone} placeholder="+234*****" type='email' onChange={handleChange} /> */}
        <Input label={'Country'} name={'country'} defaultValue={signupData?.country} type="select" options={countries} placeholder="Select your country" onChange={(e) => {
          handleChange(e)
          const country = e.target.value
          // console.log((profiles?.profiles))
          const profile = profiles?.profiles?.find(profile => profile.description === country)
          dispatch(setSignupData({ 'profile_id': profile?.id || 2 }))
        }} />
        <CheckInput onChange={handleChange} defaultChecked={signupData?.iagree} name={'iagree'} label={<>I agree to <Text isLink weight={600} color={Assets.colors.primary}>ReachAfrika’s Terms & Conditions</Text></>} />
      </div>
    </RAForm>
  </>
}
const EnterPin = () => {
  const dispatch = useDispatch()
  const handleChange = (e) => {
    const field = e.target.name
    const value = parseInt(e.target.value)
    dispatch(setSignupData({ [field]: value }))
  }
  const { signupData, currentSignupForm } = useSelector(state => state.auth)
  const schema = yup.object().shape({
    pin: yup.string().required('Pin is required'),
  });
  const submit = async () => {
    try {
      await schema.validate(signupData)
      dispatch(setCurrentSignupForm(currentSignupForm + 1))
    } catch (error) {
      console.log(error)
      toast.error(error?.message)
    }
  }
  return <>
    <Navigation onBack={() => dispatch(setCurrentSignupForm(currentSignupForm - 1))} progress={100} />

    <Text size={'28px'} className={'w-[100%]'} weight={600}>
      Login Pin
    </Text>
    <Text size={'14px'} weight={400}>Enter a 6 digit pin you can use to login
    </Text>

    <RAForm className={'mt-4 gap-[40px] flex flex-col !w-[100%]'} btnClass={'h-[56px] !rounded-full'} btnText={'Continue'} onSubmit={submit}>
      <div className=' m-auto  '>
        <Input type='otp' name='pin' numInputs={6} onChange={handleChange} />

      </div>
    </RAForm>

  </>
}

const StepThree = () => {
  const dispatch = useDispatch()
  const { signupData, currentSignupForm } = useSelector(state => state.auth)
  const schema = yup.object().shape({
    phone: yup.string().required('Phone number is required'),
  });
  const handleChange = (e) => {
    const field = e.target.name
    const value = e.target.value
    dispatch(setSignupData({ [field]: value }))
  }
  const submit = async (e) => {
    try {
      await schema.validate(signupData)
      const phone = signupData?.phone?.includes('+') ? signupData?.phone : "+" + signupData?.phone
      const { data: isPhone } = await apiRequest({ method: 'post', payload: { phone }, url: '/valid-phone?lang=en', version: 'v1' })
      if (isPhone?.status === false) throw new Error('This phone is taken !')
      const { data: sendOtp } = await apiRequest({ method: 'post', payload: { phone }, url: '/send-otp?lang=en', version: 'v1' })
      console.log(sendOtp)
      dispatch(setCurrentSignupForm(currentSignupForm + 1))

    } catch (error) {
      console.log(error)
      toast.error(error?.message)
    }
  }
  return <>
    <Navigation onBack={() => dispatch(setCurrentSignupForm(currentSignupForm - 1))} progress={100} />

    <Text className={'w-[100%]'} size={'28px'} weight={600}>
      Verification
    </Text>
    <Text size={'14px'} weight={400}>
      We’ll send you a code via SMS and Whatsapp. Make sure your phone number is registered on Whatsapp.
    </Text>
    <RAForm className={'mt-4 gap-[40px] flex flex-col !w-[100%]'} btnClass={'h-[56px] !rounded-full'} btnText={'Continue'} onSubmit={submit}>
      <Input type='phone' name='phone' defaultValue={signupData.phone} onChange={handleChange} />
    </RAForm>

  </>
}

const StepFour = () => {
  const dispatch = useDispatch()
  const handleChange = (e) => {
    const field = e.target.name
    const value = e.target.value
    dispatch(setSignupData({ [field]: value }))
  }
  const { signupData, currentSignupForm } = useSelector(state => state.auth)
  const submit = async () => {
    try {
      const phone = signupData?.phone?.includes('+') ? signupData?.phone : "+" + signupData?.phone
      const payload = { ...signupData, phone }
      console.log(payload)
      await apiRequest({ method: 'post', payload, url: '/auth/o-sign-up' })
      dispatch(setCurrentSignupForm(currentSignupForm + 1))
    } catch (error) {
      console.log(error)
      toast.error(error.message)
    }
  }
  const resendOtp = async () => {
    try {
      const phone = signupData?.phone?.includes('+') ? signupData?.phone : "+" + signupData?.phone
      await apiRequest({ method: 'post', payload: { phone }, url: '/send-otp?lang=en', version: 'v1' })
      toast.success('OTP Sent successfully')
    } catch (error) {

    }
  }
  return <>
    <Navigation onBack={() => dispatch(setCurrentSignupForm(currentSignupForm - 1))} progress={100} />

    <Text size={'28px'} className={'w-[100%]'} weight={600}>
      What’s your phone number?
    </Text>
    <Text size={'14px'} weight={400}>Enter verification code sent to your phone number {signupData?.phone} via SMS and Whatsapp
    </Text>

    <RAForm className={'mt-4 gap-[40px] flex flex-col !w-[100%]'} btnClass={'h-[56px] !rounded-full'} btnText={'Continue'} onSubmit={submit}>
      <div className='w-[70%] m-auto  '>
        <Input type='otp' name='otp' onChange={handleChange} />

      </div>
      <div className='w-100 flex gap-3 justify-center mt-[50px]'>
        <Text weight={600} >
          Didn’t recieve the code?
        </Text>
        <Text isLink weight={600} color={Assets.colors.primary} onClick={resendOtp}>
          Resend
        </Text>
      </div>
    </RAForm>

  </>
}
const StepFive = () => {

  const navigate = useNavigate()
  return <div className='text-center'>
    <img src={Assets.images.email} width={96} alt='email' className='mx-auto mb-4 ' />

    <Text size={'40px'} lH={1} weight={600}>
      Account successfully created.
    </Text> <br /> <br /> <br />

    <Text size={'14px'} className={' !mt-[50px] block'} weight={400}>
      Confirm your email address using the verification link we sent to emai@gmail.com. Check your inbox.
    </Text>


    <Button className={'mt-[50px]'} width={'100%'}  onClick={() => navigate('/login')} r={20}>Dismiss</Button>

  </div>
}
const Signup = () => {
  const { currentSignupForm, } = useSelector(state => state.auth)

  const dispatch = useDispatch()
  useEffect(() => {
    return () => {
      dispatch(clearSignupData())
      dispatch(setCurrentSignupForm(0))
    }
  }, [dispatch])
  const steps = [
    {
      Component: <StepOne />,
    },
    {
      Component: <StepTwo />,
    },
    {
      Component: <EnterPin />,
    },
    {
      Component: <StepThree />,
    },
    {
      Component: <StepFour />,
    },
    {
      Component: <StepFive />,
    },
  ]
  return (
    <AuthLayout>
      <div className='flex flex-col gap-4 items-center lg:w-[450px] xs:w-[90%]'>
        {
          steps[currentSignupForm].Component
        }
      </div>
    </AuthLayout>
  )
}
export default Signup
