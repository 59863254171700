import Text from 'Components/Text'
import React from 'react'
import { BsChevronRight } from 'react-icons/bs'
import { PiGlobeHemisphereEast, PiMapPinLine } from "react-icons/pi";
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { closeModal } from 'Store/slices/modalSlice';

const SelectAirtimePurhase = () => {
    const dispatch = useDispatch()
    const closeModal_ = ()=>{
        dispatch(closeModal())
    }
    return (
        <div className='xs:w-[95%] sm:w-[450px] lg:w-[500px] bg-[white] mx-auto'>
            <div className='flex flex-col items-center gap-4 mb-[40px]'>
                <Text size={20} weight={600}>Select Airtime Purchase Type</Text>
                <Text size={12} weight={400} display={'block'} className={'w-[80%]'} align={'center'}>Choose the type of airtime you want to purchase. You can either buy airtime for local use or send airtime internationally. Please select an option below to continue.</Text>
            </div>
            <div className='flex flex-col gap-4 pb-4'>
                <Link to={'/shop/airtime'} onClick={closeModal_} className='border grid grid-cols-12 w-[90%] mx-auto items-center rounded-[12px] gap-3 h-[94px] cursor-pointer'>
                    <PiMapPinLine size={24} className='justify-self-center col-span-2' />
                    <div className=' col-span-8'>
                        <Text display={'block'} weight={600} size={16}>
                            Buy Local Airtime
                        </Text>
                        <Text display={'block'} weight={400} size={12}>
                            Purchase or send airtime within your country.
                        </Text>
                    </div>
                    <BsChevronRight className='justify-self-center col-span-2' />
                </Link>
                <Link to={'/shop/airtime/international'}  onClick={closeModal_} className='border grid grid-cols-12 w-[90%] mx-auto items-center rounded-[12px] gap-3 h-[94px] cursor-pointer'>
                    <PiGlobeHemisphereEast size={24} className='justify-self-center col-span-2' />
                    <div className=' col-span-8'>
                        <Text display={'block'} weight={600} size={16}>
                            Buy International Airtime
                        </Text>
                        <Text display={'block'} weight={400} size={12}>
                            Send airtime to other countries.
                        </Text>
                    </div>
                    <BsChevronRight className='justify-self-center col-span-2' />
                </Link>
            </div>
        </div>
    )
}

export default SelectAirtimePurhase