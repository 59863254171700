import React from 'react'
import { Input, RAForm } from 'Components'
import { getResponseErrors } from 'helpers/getResponseError'
import { useDispatch } from 'react-redux'
import { closeModal } from 'Store/slices/modalSlice'
import { toast } from 'react-toastify'

const ChangePassword = () => {
    const dispatch = useDispatch()
    return (
        <RAForm btnText={'Change Password'} btnClass={'w-full mt-[30px]'} url={'/change-password'} version='v1' onError={(err) => {
            // console.log() 
            getResponseErrors({ error: err, useToast: true })
            // console.log(errs)
        }} onSuccess={() => {
            toast.success('Successful')
            dispatch(closeModal())
        }}>

            <div className=' flex flex-col gap-[10px] w-[100%]'>
                <Input placeholder={'Current password'} required type='password' name='current' />
                <Input placeholder={'New password'} required type='password' name='password' />
                <Input placeholder={'Confirm password'} required type='password' name='password_confirmation' />
            </div>

        </RAForm>
    )
}

export default ChangePassword