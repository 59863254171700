import React, { useEffect, useState } from 'react';
import { useDispatch,  } from 'react-redux';
import { useTable } from 'react-table';
// import { FaTimes } from 'react-icons/fa';
import { GrNext } from 'react-icons/gr';
// import { BsSearch, BsSortUpAlt } from 'react-icons/bs';
import { Pagination, TablePagination } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { openModal,  } from 'Store/slices/modalSlice';
import styles from './table.module.scss';

function Component({ columns, data }) {
    const dispatch = useDispatch();
    // const { title } = useSelector(state => state.modal);

    const handleOpenModal = (transaction) => {
        dispatch(openModal({
            component: (
                <div className="relative p-4 w-full bg-white rounded-lg">
                   
                    
                    <div className="space-y-2">
                        {[
                            { label: 'Date', value: transaction.Date },
                            { label: 'Status', value: transaction.status },
                            { label: 'Description', value: transaction.description },
                            { label: 'Network', value: transaction.network },
                            { label: 'Recipient', value: transaction.recipient },
                            { label: 'Reference', value: transaction.reference },
                            { label: 'Amount', value: transaction.Amount },
                            { label: 'Discount', value: transaction.discount },
                            { label: 'Coins Used', value: transaction['Coins Used'] },
                            { label: 'Coins Earned', value: transaction['Coins Earned'] },
                            { label: 'Total', value: transaction.total }
                        ].map(({ label, value }) => (
                            <div key={label} className="flex justify-between w-[400px]">
                                <span className={label === 'Total' ? 'font-bold' : ''}>{label}</span>
                                <span>{value}</span>
                            </div>
                        ))}
                    </div>
                </div>
            ),
            title: 'Transaction Details'
        }));
    };

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data });

    return (
        <table className={styles.table} {...getTableProps()}>
            <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                        ))}
                    </tr> 
                ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {rows.map(row => {
                    prepareRow(row);
                    return (
                        <tr {...row.getRowProps()}>
                            {row.cells.map(cell => (
                                <td {...cell.getCellProps()}>
                                    {cell.column.id === 'status' ? (
                                        <div className="flex items-center gap-2">
                                            <span>{cell.render('Cell')}</span>
                                            <GrNext
                                                onClick={() => handleOpenModal(row.original)}
                                                style={{ cursor: 'pointer', color: 'black' }}
                                            />
                                        </div>
                                    ) : (
                                        cell.render('Cell')
                                    )}
                                </td>
                            ))}
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
}

const precolumns = [
    {
        Header: 'Transaction Date',
        accessor: 'firstName',
    },
    {
        Header: 'Learner',
        accessor: 'lastName',
    },
];

function Table({
    columns = precolumns,
    data = [],
    handleChangePage,
    page,
    count = 10,
    size = 5,
    handleChangeRowsPerPage,
    onSearch = () => null,
    searchValue,
    placeholder = 'Search'
}) {
    const [processedData, setProcessedData] = useState(data);

    useEffect(() => {
        setProcessedData(data);
    }, [data]);

    return (
        <div className={styles.container}>
         
            {data ? (
                processedData.length ? (
                    <Component columns={columns} data={processedData} />
                ) : (
                    <Skeleton variant="rectangular" width={'auto'} height={700} />
                )
            ) : (
                'Loading...'
            )}
            <div className={styles.pagination}>
                <TablePagination
                    className={styles.rowPerpage}
                    component="div"
                    count={count}
                    page={page}
                    rowsPerPageOptions={[5, 10, 15, 20]}
                    onPageChange={(event, value) => handleChangePage(value - 1)}
                    rowsPerPage={size}
                    onRowsPerPageChange={(event) => handleChangeRowsPerPage(event.target.value)}
                    backIconButtonProps={{ style: { display: 'none' } }}
                    nextIconButtonProps={{ style: { display: 'none' } }}
                />
                <Pagination
                    count={Math.floor(count / size) + 1}
                    page={page + 1}
                    onChange={(event, value) => handleChangePage(value - 1)}
                />
            </div>
        </div>
    );
}

export default Table;
