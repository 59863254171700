


import assets from 'Assets'
import { Input, RAForm } from 'Components'
import FullPageLayout from 'Components/layout/FullPageLayout'
import RAStepper from 'Components/Stepper'
import { formatPrice } from 'helpers'
import { calculateCoins, purchaseShopProduct, shopTransactionHash } from 'helpers/shop'
import { useWalletInfo } from 'hooks'
import { useShopItems } from 'hooks/useShopItems'
import BlueScreen from 'Partials/BlueScreen'
import InvoiceCard from 'Partials/InvoiceCard'
// import SelectPaymentMethod from 'Partials/SelectPaymentMethod'
// import Text from 'Components/Text'
import UseCoins from 'Partials/UseCoins'
import React, { useState } from 'react'
// import { useDispatch } from 'react-redux'
import { apiRequest } from 'Services'
// import { useNavigate, useParams } from 'react-router-dom'
// import { openModal } from 'Store/slices/modalSlice'

const Screens = Object.freeze({ "FORM": 0, "INVOICE": 1, "SUCCESS": 2, "FAILED": 3 });


const BuyPower = () => {

    const product = useShopItems('power')
    const [payload, setPayload] = useState({})
    const [currScreen, setCurrScreen] = useState(Screens.FORM)
    const { walletInfo } = useWalletInfo()

    // const dispatch = useDispatch()
    const submit = async () => {
        const product = 'power'
        try {
            const hash = shopTransactionHash({ product, payable: payload?.payable, amount: payload?.amount })
            await purchaseShopProduct({
                product,
                beneficiaryName: payload?.beneficiaryName,
                amount: payload?.amount,
                payable: payload?.payable,
                meter_no: payload?.meter_no,
                disco_id: payload?.disco_id,
                vendor_type: payload?.vendor_type,
                provider: payload?.provider,
                reference: payload?.reference,
                hash
            })
            setCurrScreen(Screens.SUCCESS)
        } catch (error) {
            setCurrScreen(Screens.FAILED)
        }
    }
    const onPurchase = () => {
        submit()
        // dispatch(openModal({ title: '', component: <SelectPaymentMethod onComplete={submit} onError={() => setCurrScreen(Screens.FAILED)} /> }))
    }

    const [invoicelist, setInvoiceList] = useState([])
    const previewInvoice = async (params) => {
        try {
            const coins = calculateCoins({ userCoins: walletInfo.reward.coins, amount: params?.amount, useCoins: params?.usecoins })
            const disco = product.items.find(item => item?.id === parseInt(params?.disco_id))?.alias
            const { orderId, minVendAmount, maxVendAmount } = await apiRequest({
                method: 'post', url: '/shop/products/meter-check', useLoadingScreen: true,
                payload: {
                    "disco_id": params?.disco_id, "meter_no": params?.meter_no, "vendor_type": params?.vendor_type
                }
            })
            if (params?.amount > minVendAmount) {

            }
            if (params?.amount < maxVendAmount) {

            }
            setInvoiceList([
                { field: 'Dis. Co', value: disco },
                { field: 'Beneficiary Name', value: params?.beneficiaryName },
                { field: 'Vendor Type', value: params?.vendor_type },
                { field: 'Meter Number', value: params?.meter_no },
                { field: 'Amount', value: formatPrice(params?.amount) },
                { field: 'Coins Used', value: coins.coinsUsed },
                { field: 'Discount', value: formatPrice(coins.discount) }
            ])
            setPayload({
                amount: params?.amount,
                payable: coins.payable,
                meter_no: params?.meter_no,
                disco_id: params?.disco_id,
                vendor_type: params?.vendor_type,
                beneficiaryName: params?.beneficiaryName,
                coins: coins.coinsUsed,
                reference: orderId
            })
            setCurrScreen(Screens.INVOICE)
        } catch (error) {

        }

    }
    return (
        <>
            <BlueScreen
                title='Purchase Successful!'
                bodyText='You have successfully bought ₦2000 airtime from MTN for John Doe. Thank you for your purchase!'
                onClickFillBtn={console.log}
                show={currScreen === Screens.SUCCESS}
                icon={assets.images.success}
                onDismiss={() => { setCurrScreen(Screens.FORM) }}
            />
            <BlueScreen
                title='Transaction was not successful. Please try again '
                onClickFillBtn={() => {
                    setCurrScreen(Screens.FORM)
                }}
                fillBtnText='Try again'
                show={currScreen === Screens.FAILED}
                icon={assets.images.failiure}
            />
            <FullPageLayout headerText={`Buy Power`} className='w-full' >


                <div className='w-[510px] !mt-[20px] mx-auto'>
                    <RAStepper noLabel steps={[0, 1, 2]} activeStep={currScreen} />
                </div>

                <div style={currScreen === Screens.INVOICE ? { marginTop: 50 } : { visibility: 'hidden', height: 0 }} className='h-[100%] !'>

                    <InvoiceCard
                        onBack={() => setCurrScreen(Screens.FORM)}
                        onClick={onPurchase} list={invoicelist}
                        rightText={`Purchase ${payload?.coins ? `(${payload?.coins} Coins)` : ''}`}
                        total={payload?.payable}
                    />
                </div>
                <RAForm
                    className={'mx-auto max-w-[400px] '}
                    inputsContainer='flex flex-col gap-[25px]'
                    btnText={'Proceed to checkout'}
                    btnClass={'!rounded-[20px] mt-[60px] w-full'}
                    onSubmit={previewInvoice}
                    style={currScreen === Screens.FORM ? {} : { visibility: 'hidden', height: 0 }}
                >

                    <Input label={'Amount (₦)'} name='amount' required />
                    <Input label={'Dis. Co'} type='select' name='disco_id'
                        options={product.items.map(item => ({ label: item?.alias, value: item?.id }))} required />
                    {/* <Input label={'Packages'} type='select' /> */}
                    <Input label={'Vendor type'} type='select' name='vendor_type'
                        options={['PREPAID', 'POSTPAID'].map(vendor_type => ({ label: vendor_type, value: vendor_type }))} required />
                    <Input label={'Meter number'} placeholder="Eg 345 5678 543456 44" name='meter_no' required />
                    <Input label={'Beneficiary Name'} name='beneficiaryName' required />
                    <UseCoins />

                </RAForm>



            </FullPageLayout></>
    )
}

export default BuyPower