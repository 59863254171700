import React from 'react'
import Text from 'Components/Text'
import styles from '../styles/home.module.scss'
import { Button } from 'Components'
import { PiArrowSquareUpBold } from "react-icons/pi";
import assets from 'Assets';
import { useWalletInfo } from 'hooks';
import { useDispatch } from 'react-redux';
import { openModal } from 'Store/slices/modalSlice';
import Topup from 'Partials/Topup';

const Balances = () => {
    const { walletInfo } = useWalletInfo()
    console.log(walletInfo)
    const dispatch = useDispatch()
    return (
        <div className={styles.right} >
            <div className={styles.balances}>
                <Text className={styles.headerText}>
                    Account Balance
                </Text>

                <div className={styles.balancecard}>
                    <div className='flex items-center gap-[12px]'>
                        <img src={assets.images.buymorecoins} alt="" />
                        <Text weight={600}>Balance</Text>
                    </div>
                    <Text size={24} weight={600}>₦{walletInfo.balance}</Text>
                </div>
                <div className={styles.balancecard}>
                    <div className='flex items-center gap-[12px]'>
                        <img src={assets.images.buymorecoins} alt="" />
                        <Text weight={600}>Rewards</Text>
                    </div>
                    <Text size={24} weight={600}>{walletInfo?.reward?.coins}</Text>
                </div>
                <Button width={'100%'} r={24} onClick={() => dispatch(openModal({ title: 'Select a method', component: <Topup /> }))}>
                    <PiArrowSquareUpBold />   Top up
                </Button>
            </div>
        </div>
    )
}

export default Balances