import { store } from 'Store';
import { logout, reuse } from 'Store/slices/auth';
import { closeLoadingScreen, openLoadingScreen } from 'Store/slices/loadingScreenSlice';
import axios from 'axios'
import { toast } from 'react-toastify';

const baseURL = {
    v2: process.env.REACT_APP_BASE_URL_V2,
    v1: process.env.REACT_APP_BASE_URL_V1
}

const apiRequest = async ({
    method = 'get',
    url,
    payload,
    params,  // pass url search parameters as object 
    contentType = "application/json", noToken,
    version = 'v2',
    headers = {},
    useLoadingScreen = false
}) => {
    const state = store.getState().auth.user
    const token = noToken ? '' : state.token
    // const hash = CryptoJS.HmacSHA1([])
    // console.log(token)

    const axiosInstance = axios.create({
        baseURL: baseURL[version],
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": contentType,
            "Accept": "application/json",
            "api-user-agent": "reachafrika.com",
            "salt": "reachafrika",
            ...headers
        }
    });
    store.dispatch(reuse())
    if (useLoadingScreen) store.dispatch(openLoadingScreen())
    try {
        const { data } = await axiosInstance({
            method,
            url,
            data: payload,//
            params
        })
        return data
    } catch (error) {
        const data = error?.response?.data
        const code = data?.code
        if (code === 401) {
            toast.info('Session Expired!')
            store.dispatch(logout())
        }
        // console.error(error);
        // const err_code = error?.response?.status
        // const data = error?.response?.data
        // console.log(err_code)
        // const is_403 = err_code === 403
        // if (is_403) toast.error('Unauthorized')
        // if (error?.message && !is_403) toast.error(error.message)
        throw error

    } finally {

        if (useLoadingScreen) store.dispatch(closeLoadingScreen())
    }
}



export { baseURL, apiRequest }