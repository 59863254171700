import { ArrowBack } from '@mui/icons-material'
import { Button, Input, Text } from 'Components'
import { formatPrice } from 'helpers'
import { useFetch, useMe } from 'hooks'
import { Card, Copy } from 'iconsax-react'
import React, { useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { useFlutterwave, closePaymentModal, } from "flutterwave-react-v3";
const EnterAmountToTopup = ({ onBack = () => null, onProceed = () => null }) => {
    const amounts = [500, 1000, 2000, 5000, 10000, 15000, 20000, 25000, 30000]
    const [amount, setAmount] = useState(0)
    const { me } = useMe()
    const config = useMemo(() => ({
        public_key: "FLWPUBK_TEST-e7c8f332b9d34b01b958cf4f4f643018-X",
        tx_ref: Date.now(),
        amount: amount,
        currency: "NGN",
        payment_options: "card,mobilemoney,ussd",
        customer: {
            email: me?.email,
            phone_number: me?.phone,
            name: me?.firstname + " " + me?.lastname,
        },
        customizations: {
            title: "ReachAfrika Wallet Top",
            description: `Wallet top up ${amount}`,
            logo: "https://st2.depositphotos.com/4403291/7418/v/450/depositphotos_74189661-stock-illustration-online-shop-log.jpg",
        },
    }), [me, amount]);
    const handleFlutterPayment = useFlutterwave(config);
    
    const proceed = () => {
        if (amount < 500) {
            toast.error('Minimum of 500!')
            return;
        }
        handleFlutterPayment({
            callback: (response) => {
                console.log(response);
                onProceed()
                closePaymentModal();
            },
            onClose: () => {

            },
        })
    }

    return (
        <>
            <div className='w-full '>
                <ArrowBack className='float-left cursor-pointer' onClick={onBack} />

                <Text size={17} weight={600} display={'block'} align={'center'}>Enter amount</Text>
                {/* <hr /> */}
                <Input containerClass={'w-full'} type='number' onChange={(e) => setAmount(e?.target.value)} value={amount} />
                {(amount < 500) && <Text color={'red'} size={10}>Minimum of 500</Text>}
                <div className='flex flex-wrap border mt-4 gap-2 justify-between p-3 rounded'>
                    {
                        amounts.map(amount_ => <div
                            onClick={() => setAmount(amount_)}
                            className='py-2 rounded border w-[100px] text-center cursor-pointer'>
                            {formatPrice(amount_)}
                        </div>)
                    }

                </div>
                <Button className={'my-2 w-full'} disabled={amount < 500} onClick={proceed}>Proceed</Button>
            </div>
        </>
    )
}


const Topup = () => {
    const { data: account } = useFetch({ url: '/bank-account-details', version: 'v1', useLoadingScreen: true })
    const { me } = useMe()
    const [showAmountInput, setShowAmountInput] = useState(false)
    const onProceed = (config) => {
        setShowAmountInput(false)
        toast.success('Payment successful!')
    }

    return (
        <div className='flex flex-col gap-4 w-full !w-[350px] mx-auto'>
            {!showAmountInput ? <>
                <div className='border flex gap-2  w-[100%] p-3 rounded cursor-pointer' onClick={() => {
                    try {
                        navigator.clipboard.writeText(account?.account_no)
                        toast.success('Copied')
                    } catch (error) {
                        toast.error('Your browser can\'t copy to clipboard')
                    }
                }}>
                    <Copy className='cursor-pointer' />
                    <div>
                        <Text size={17} weight={600} display={'block'}>
                            {account?.account_no} - {account?.bank_name} <br />
                            Reachafrika-{me?.firstname}{me?.lastname}
                        </Text>
                        <Text size={15} weight={400}>
                            Fund your wallet by sending money to this account from your bank app or USSD
                        </Text>
                    </div>
                </div>
                <div className='border w-[100%] p-3 rounded flex gap-2 cursor-pointer' onClick={() => setShowAmountInput(true)}>
                    <Card />
                    <div>
                        <Text size={17} weight={600} display={'block'}>
                            Debit/Credit Card
                        </Text>
                        <Text size={15} weight={400}>
                            Fund wallet throught debit/credit card
                        </Text>
                    </div>
                </div>
            </> :
                <EnterAmountToTopup onBack={() => setShowAmountInput(false)} onProceed={onProceed} />}
        </div>
    )
}

export default Topup