import React from 'react'
import { Input, RAForm, Text } from 'Components'
import { useSearchParams } from 'react-router-dom'
import UseCoins from 'Partials/UseCoins'

const AddGiftCardForBeneficiary = ({ onProceed = () => null }) => {
    const [searchParams, setSearchParams] = useSearchParams()
    const onSubmit = (params) => {
        onProceed(params)
    }
    return (
        <div className='w-full'>

            <Text display={'block'} align={'center'} size={20} className={'w-[480px] !mt-[50px] mx-auto'} weight={600}>
                Who would you like to buy gift card for?</Text>

            <RAForm btnText={'Proceed to checkout'} onSubmit={onSubmit} btnClass={'w-full mt-[50px] !rounded-[100px]'} className={'w-full mt-[50px]'}>
                <div className='flex flex-col gap-5'>
                    <Input label={'Amount'} name={'amount'} required />
                    <Input label={'Beneficiary Phone Number'} type='phone' name='phone' required defaultValue={searchParams.get('phone')} />
                    <Input label={'Beneficiary Name'} name='beneficiaryName' required defaultValue={searchParams.get('name')} />
                    <Input label={'Gift Card Message'} placeholder='Leave a message for beneficiary…' name='message' required />
                    <UseCoins />
                </div>
            </RAForm>
        </div>
    )
}

export default AddGiftCardForBeneficiary