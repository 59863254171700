import { Text } from 'Components'
import React from 'react'
import { PiArrowArcRight } from 'react-icons/pi'

const NearYou = () => {
    return (
        <div className='w-full flex flex-col'>
            <div className='border px-5 py-3 flex items-center justify-between w-full h-[144px] rounded-[12px]'>
                <div>
                    <Text size={24} weight={600}>Melcom Spintex</Text>
                    <Text size={14} color={'#414752'} display={'block'} >In-store shopping • Delivery</Text>
                    <Text size={14} color={'#414752'} display={'block'} >84.3 km away</Text>
                    <Text size={14} color={'#414752'} display={'block'} ><span className='text-[#BA1A1A]'>Closed</span> ⋅ Opens 10AM</Text>
                </div>
                <div className='border rounded-[12px] flex-col h-[76px] w-[102px] flex items-center justify-center '>
                    <PiArrowArcRight color='#1976D2' />
                    <Text color='#1976D2'>
                        Direction
                    </Text>
                </div>
            </div>
        </div>
    )
}

export default NearYou