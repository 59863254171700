import React, { useEffect, useState } from 'react'
import styles from './partials.module.scss'
import assets from 'Assets'
import Text from 'Components/Text'
import { Button } from 'Components'
const BlueScreen = ({
    icon = assets.images.rewardstars,
    title = 'Title',
    bodyText ,
    onClickFillBtn,
    fillBtnText = "Rate Transaction",
    onDismiss,
    dismissText = 'Dismiss',
    show
}) => {
    const [hidden, setHidden] = useState(show)
    useEffect(() => {
        setHidden(show)
    }, [show])
    return (

        hidden ? <div className={styles.blueScreen}>
            <div className={styles.blueScreenContent}>
                {icon && <img src={icon} width={100} height={100} alt="" />}
                {
                    title && <Text display={'block'} align='center' size={28} weight={600}>
                        {title}
                    </Text>
                }
                {
                    bodyText && <Text display={'block'} align={'center'} className={'w-[350px]'}>
                        {bodyText}
                    </Text>
                }

                <div className='mt-5 w-full'>
                    {
                        onClickFillBtn && <Button r={24} width={'100%'} onClick={onClickFillBtn}>
                            {fillBtnText}
                        </Button>
                    }
                    {
                        onDismiss && <Button color={assets.colors.primary} bgcolor='white' className={'mt-3'} width={'100%'} onClick={onDismiss}>
                            {dismissText}
                        </Button>
                    }
                </div>

            </div>
            <img src={assets.images.logo} alt="logo" className={styles.logo} width={192} />
        </div> : ''

    )
}

export default BlueScreen