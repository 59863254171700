import { Box } from '@mui/material'
import assets from 'Assets'
import { Button, Text } from 'Components'
import FullPageLayout from 'Components/layout/FullPageLayout'
import { formatPrice } from 'helpers'
import React, { useState } from 'react'
import { BsPlus } from 'react-icons/bs'
import styles from './giftcard.module.scss'
import { Link } from 'react-router-dom'
import { useFetch } from 'hooks'

const GiftCard = () => {
    const transactions = [1, 2, 3, 4, 5]
    const giftcards = [
        { name: 'green', gradient: 'linear-gradient(276.1deg, #36C402 0.31%, #83AE0F 100%)', boxImage: assets.images.giftcardimages.greenbox },//
        { name: 'blue', gradient: 'linear-gradient(270.31deg, #1976D2 10.29%, #009AF1 71.93%)', boxImage: assets.images.giftcardimages.bluebox },//
        { name: 'violet', gradient: 'linear-gradient(270.31deg, #3A41C8 10.29%, #935FE5 71.93%)', boxImage: assets.images.giftcardimages.violetbox },//
        { name: 'pink', gradient: 'linear-gradient(270.31deg, #C83AB1 10.29%, #E55F5F 71.93%)', boxImage: assets.images.giftcardimages.pinkbox },//
        { name: 'orange', gradient: 'linear-gradient(270.31deg, #D54710 10.29%, #F2C94C 71.93%)', boxImage: assets.images.giftcardimages.orangebox },//
    ]
    const [selectedTab, setSelectedTab] = useState('giftcards')
    const tabs = [
        { name: "Gift Cards", value: "giftcards" },
        { name: "Transactions", value: "transactions" },
    ]
    const { data: cards } = useFetch({ url: '/gift-cards/my-created-gift-cards' })

    return (
        <FullPageLayout headerText={'Gift Card'} rightComponent={
            <Link to={'/giftcard/add'}><Button className={'pr-3'} r={100}><BsPlus size={30} />New Card </Button></Link>
        }>

            <div className='mx-auto flex flex-col gap-[30px] !w-[400px]'>
                <div className='bg-[#C3D9FF]  flex flex-col items-center py-5 rounded-[16px] w-full text-[#2F486A]'>
                    <Text size={24} display={'block'} weight={600}>{formatPrice(2000, 'NGN ')}</Text>
                    <Text size={14} display={'block'} weight={600}>Total Gift Cards Balance</Text>
                </div>
                <div className='flex gap-3'>
                    {
                        tabs.map(tab => <Button onClick={() => setSelectedTab(tab.value)} width={'50%'} bgcolor={selectedTab !== tab.value ? '#E6E8F0' : '#8FD9FF'}
                            color={selectedTab !== tab.value ? '#181C21' : '#004158'} r={'100px'}>{tab.name}</Button>)
                    }
                </div>
                {selectedTab === 'transactions' && <div className=' flex flex-col items-center'>
                    <div className='bg-[#D4E3FF] flex items-center justify-center h-[96px] w-[96px] rounded-full'>
                        <img src={assets.images.giftcard} width={40} alt="" />
                    </div>
                    <Text>
                        You have not made any transactions yet
                    </Text>
                </div>}
                <div className=' flex flex-col items-center gap-4'>
                    {selectedTab === 'transactions' &&
                        transactions.map((item, i) => <div className={`flex w-full justify-between ${i !== giftcards.length - 1 ? 'border-b' : ''} pb-3`}>
                            <div className='flex flex-col gap-2'>
                                <Text display={'block'} color={'#181C21'} size={16}>Sent by James Gun</Text>
                                <Text display={'block'} color={'#414752'} size={12}>This is the note right here and there…</Text>
                            </div>
                            <div className='flex flex-col gap-2'>
                                <Text display={'block'} color={'#181C21'} align={'right'} size={16} weight={600}>{formatPrice(4000.00)}</Text>
                                <Text display={'block'} align={'right'} color={'#181C21'} size={12} weight={400}>07/07/2023</Text>
                            </div>
                        </div>)
                    }
                    {selectedTab === 'giftcards' &&
                        (cards?.data?.length ?
                            <>
                                {cards?.data?.map(card => {
                                    const theme = giftcards.find(giftcard => giftcard.name === card?.theme)
                                    return (<Box key={card?.id} sx={{ background: theme.gradient }} className={styles.giftcardReceived}>
                                        <img className={styles.oval} src={assets.images.giftcardimages.oval} alt="" />
                                        <img className={styles.curvy} src={assets.images.giftcardimages.curvy} alt="" />

                                        <div className='flex justify-between'>
                                            <div className='flex flex-col gap-3'>
                                                <div className='flex items-center gap-2'>
                                                    <img src={assets.images.giftcardimages.africamap} width={11} className='h-[13px]' height={8} alt="" />
                                                    <Text size={10} color='white' display={'block'} bgcolor='rgba(255, 255, 255, 0.2)' className={'px-[4px] rounded py-[2px]'} weight={600}>RECEIVED</Text>
                                                </div>
                                                <Text color={'white'} size={10} weight={400}>{card?.message}</Text>
                                                <Text color={'white'} size={10} weight={700}>Amount left</Text>
                                                <Text color={'white'} size={24} weight={700}>{card?.formattedAmount}</Text>

                                            </div>
                                            <div className='flex flex-col items-end justify-between'>

                                                <div className={styles.boxImage} >  <img src={theme.boxImage} alt="" /></div>
                                                <div>
                                                    <Text size={13} display={'block'} align={'right'} weight={'700'} color={'rgba(255, 255, 255, 0.7)'} className={'capitalize'}>Created by</Text>
                                                    <Text size={13} display={'block'} align={'right'} weight={'700'} color={'rgba(255, 255, 255, 1)'} className={'capitalize'}>{card?.createdBy}</Text>
                                                </div>
                                            </div>
                                        </div>
                                    </Box>)
                                })}

                            </> : <>
                                <div className=' flex flex-col items-center'>
                                    <div className='bg-[#D4E3FF] flex items-center justify-center h-[96px] w-[96px] rounded-full'>
                                        <img src={assets.images.giftcard} width={40} alt="" />
                                    </div>
                                    <Text>
                                        You have no Giftcards
                                    </Text>
                                </div>
                            </>
                        )
                    }
                </div>
            </div>

        </FullPageLayout>
    )
}

export default GiftCard