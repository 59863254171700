import { Button, Input, Text } from 'Components'
import { useFetch } from 'hooks'
import React, { useState } from 'react'

const ChangeLanguage = () => {

    const [confirm, setCofirm] = useState(false)
    const { data: languages } = useFetch({ url: '/settings/languages', version: 'v1' })
    console.log({languages})
    return (
        <div className=' flex flex-col gap-5'>
            {
                confirm ?
                    <div className='text-center flex flex-col gap-[60px] '>
                        <Text display={'block'} align={'center'}>Are you sure you want to change the language in this app? All the system language will change into your choice</Text>

                        <Button width={'100%'} onClick={() => setCofirm(true)} r={100}> Confirm change</Button>
                    </div>
                    :
                    <>
                        <Text>Changing the language in this app will convert all texts into the selected language.</Text>
                        <Input type='select' placeholder={'Language'} options={languages?.map(language => ({ label: language?.display_name, value: language?.id }))} />
                        <Button width={'100%'} onClick={() => setCofirm(true)} r={100}> Change Language</Button>
                    </>
            }
        </div>
    )
}

export default ChangeLanguage