

// import { Alert } from '@mui/material'
import assets from 'Assets'
import { Input, RAForm } from 'Components'
import FullPageLayout from 'Components/layout/FullPageLayout'
import RAStepper from 'Components/Stepper'
import { formatPrice } from 'helpers'
import { calculateCoins, purchaseShopProduct, shopTransactionHash } from 'helpers/shop'
import { useShopItems } from 'hooks/useShopItems'
import { useShopProductItemsPackages } from 'hooks/useShopProductItemsPackages'
import BlueScreen from 'Partials/BlueScreen'
import InvoiceCard from 'Partials/InvoiceCard'
// import SelectPaymentMethod from 'Partials/SelectPaymentMethod'
// import Text from 'Components/Text'
import UseCoins from 'Partials/UseCoins'
import React, { useState } from 'react'
// import { useDispatch } from 'react-redux'
// import { useNavigate, useParams } from 'react-router-dom'
// import { openModal } from 'Store/slices/modalSlice'
import { useWalletInfo } from 'hooks'
const Screens = Object.freeze({ "FORM": 0, "INVOICE": 1, "SUCCESS": 2, "FAILED": 3 });


const BuyData = () => {
    const [currScreen, setCurrScreen] = useState(Screens.FORM)
    const product = useShopItems('data_bundle')
    const [item_id, setItem_id] = useState(null)
    const data_plans = useShopProductItemsPackages({ product: 'data_bundle', item_id })
    const [payload, setPayload] = useState({})
    const { walletInfo } = useWalletInfo()
    // const dispatch = useDispatch()

    const submit = async () => {
        const product = 'data_bundle'
        try {
            const hash = shopTransactionHash({ product, amount: payload?.amount, payable: payload?.payable })
            await purchaseShopProduct({
                product,
                package_id: payload?.package_id,
                beneficiaryName: payload?.beneficiaryName,
                amount: payload?.amount,
                payable: payload?.payable,
                phone: payload?.phone,
                network: payload?.provider,
                hash
            })
            setCurrScreen(Screens.SUCCESS)

        } catch (error) {

            setCurrScreen(Screens.FAILED)
        }
    }

    const onPurchase = () => {
        submit()
        // dispatch(openModal({ title: '', component: <SelectPaymentMethod onComplete={submit} onError={() => setCurrScreen(Screens.FAILED)} /> }))
    }

    const [invoicelist, setInvoiceList] = useState([])
    const previewInvoice = async (e) => {

        const provider = product.items.find(item => item?.id === parseInt(e?.network))?.alias
        const package_ = data_plans.find(plan => plan?.id === parseInt(e?.package_id))
        const amount = package_?.price
        const coins = calculateCoins({ userCoins: walletInfo.reward.coins, amount, useCoins: e?.usecoins })
// console.log(amount)
        setInvoiceList([
            { field: 'Network Provider', value: provider },
            { field: 'Package', value: `${provider} | ${package_?.name} ${package_?.validity}` },
            { field: 'Beneficiary Name', value: e?.beneficiaryName },
            { field: 'Beneficiary Phone ', value: e?.phone },
            { field: 'Amount', value: formatPrice(amount) },
            { field: 'Coins Used', value: coins.coinsUsed },
            { field: 'Discount', value: formatPrice(coins.discount) }
        ])
        setPayload({
            amount,
            payable: coins.payable,
            package_id: package_?.id,
            provider,
            beneficiaryName: e?.beneficiaryName,
            phone: e?.phone,
            coins: coins.coinsUsed,
            usecoins: e?.usecoins ? true : false
        })
        setCurrScreen(Screens.INVOICE)
    }
    return (
        <>
            <BlueScreen
                title='Purchase Successful!'
                bodyText='You have successfully bought ₦2000 airtime from MTN for John Doe. Thank you for your purchase!'
                onClickFillBtn={console.log}
                show={currScreen === Screens.SUCCESS}
                icon={assets.images.success}
                onDismiss={() => { setCurrScreen(Screens.FORM) }}
            />
            <BlueScreen
                title='Transaction was not successful. Please try again '
                onClickFillBtn={() => {
                    setCurrScreen(Screens.FORM)
                }}
                fillBtnText='Try again'
                show={currScreen === Screens.FAILED}
                icon={assets.images.failiure}
            />
            <FullPageLayout headerText={`Buy Data`} className='w-full' >
                <div className='w-[510px] !mt-[20px] mx-auto'>
                    <RAStepper noLabel steps={[0, 1, 2]} activeStep={currScreen} />
                </div>

                <div style={currScreen === Screens.INVOICE ? { marginTop: 50 } : { visibility: 'hidden', height: 0 }} className='h-[100%] !'>
                    <InvoiceCard
                        onBack={() => setCurrScreen(Screens.FORM)}
                        onClick={onPurchase} list={invoicelist}
                        rightText={`Purchase ${payload?.coins ? `(${payload?.coins} Coins)` : ''}`}
                        total={payload?.payable}
                    />
                </div>
                <RAForm
                    className={'mx-auto max-w-[400px] '}
                    inputsContainer='flex flex-col gap-[25px]'
                    btnText={'Proceed to checkout'}
                    btnClass={'!rounded-[20px] mt-[60px] w-full'}
                    onSubmit={previewInvoice}
                    style={currScreen === Screens.FORM ? {} : { visibility: 'hidden', height: 0 }}
                >
                    {/* <Alert variant='standard' severity='info' className='p-0' >
                        Info message
                    </Alert> */}
                    {/* <Input label={'Amount (₦)'} type='number' placeholder={400} /> */}
                    <Input label={'Network Provider'} type='select' name='network' required onChange={(e) => setItem_id(e.target.value)}
                        options={product.items.map(item => ({ label: item?.alias, value: item?.id }))} placeholder='Select network provider' />
                    <Input label={'Package'} placeholder='Select package' name='package_id' required key={item_id} options={data_plans.map(plan => {
                        return {
                            value: plan?.id,
                            label: `${plan?.name} | NGN${plan?.price} | ${plan?.validity}`
                        }
                    })} type='select' />
                    <Input label={'Recipient Phone Number'} type='phone' name='phone' required />
                    <Input label={'Beneficiary Name'} name='beneficiaryName' required />
                    <UseCoins />

                </RAForm>



            </FullPageLayout></>
    )
}

export default BuyData