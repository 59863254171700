import FullPageLayout from 'Components/layout/FullPageLayout'
import React from 'react'

const PrivacyPolicy = () => {
  return (
    <FullPageLayout headerText={'Privacy Policy'}>

    </FullPageLayout>
  )
}

export default PrivacyPolicy