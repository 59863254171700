import { useEffect, useState } from 'react'
import { apiRequest } from 'Services'

const useTransactions = () => {
    const [transactions, setTransactions] = useState([])
    useEffect(() => {
        const getTransactions = async () => {
            try {
                const { data } = await apiRequest({ url: '/shop/user-transactions'  })
                setTransactions(data)
            } catch (error) {

            }
        }
        getTransactions()
    }, [])
    return {
        transactions
    }
}

export { useTransactions }