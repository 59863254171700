import { apiRequest } from "Services"

// const { getShopProductsItem } = require("helpers/shop")
const { useState, useEffect } = require("react")
export const useShopProductItemsPackages = ({ product, item_id }) => {

    const [items, setItems] = useState([])
    // const [country, setCountry] = useState({})

    useEffect(() => {

        const getItems = async () => {
            try {
                if(item_id && product){
                    const { data } = await apiRequest({ method: 'post', url: '/shop/products/items/packages', payload: { product, item_id } })
                    // console.log(data)
                    setItems(data)
                }

            } catch (error) {
                console.log(error)
                // return []
                setItems([])
            }
        }
        getItems()

    }, [product,item_id])
    return items
}