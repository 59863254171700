import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import authSlice from './slices/auth';
import modalSlice from './slices/modalSlice';
import setupSlice from './slices/setupSlice';
import componentSlice from './slices/components';
import loadingScreenSlice from './slices/loadingScreenSlice';
import { getRequestSlice } from './slices/getSlice';
import { setupListeners } from '@reduxjs/toolkit/query'

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['modal','loadingScreen']
  // Specify the reducers you want to persist
  // whitelist: ['user'], // In this example, we persist the 'user' reducer
};

const reducers = combineReducers({
  auth: authSlice,
  modal: modalSlice,
  setup: setupSlice,
  component: componentSlice,
  loadingScreen: loadingScreenSlice,
  [getRequestSlice.reducerPath]: getRequestSlice.reducer
})
const middleWare = (getDefaultMiddleware) => getDefaultMiddleware().concat(getRequestSlice.middleware);
const persistedReducer = persistReducer(persistConfig, reducers);
export const store = configureStore({
  reducer: persistedReducer,
  middleware: middleWare
});
setupListeners(store.dispatch)
export const persistor = persistStore(store);