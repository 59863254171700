import React from 'react'
import { useSelector } from 'react-redux'
import styles from './spinner.module.scss'
import assets from 'Assets'

const LoadingScreen = () => {
    const state = useSelector(state => state?.loadingScreen)

    return (
        <>{state?.isOpen &&
            <> <div className='!w-[100vw] !h-[100vh] !bg-[black] !opacity-[.5] flex items-center justify-center  fixed top-0 left-0 text-[black] z-[10000]'>

            </div>
                <div> <span className={styles.loader}>
                </span>
                    <img src={assets.images.logo2} className={styles.logo} alt='logo' />
                </div></>}
        </>
    )
}

export default LoadingScreen