
import { formatPrice } from 'helpers'
import EnterPin from 'Partials/EnterPin'
import InvoiceCard from 'Partials/InvoiceCard'
import React from 'react'
import { useDispatch } from 'react-redux'
import { openModal } from 'Store/slices/modalSlice'

const ReviewAndConfirmGiftCard = ({
  beneficiaryName,
  amount,
  // name,
  phone,
  message,
  coinUsed = 0,
  discount = 0.00,
  payable,
  onSuccess = () => null,
  onBack = () => null
}) => {
  const dispatch = useDispatch()

  return (
    // <FullPageLayout headerText={'Review & Confirm'} className='mx-auto'>
    <div className='mt-[50px]'>
      <InvoiceCard onBack={onBack}
        onClick={onSuccess}
        total={payable}
        backText='Back'
        rightText={`Buy (₦${parseFloat(payable).toLocaleString()})`}
        list={[
          { field: 'Beneficiary Name', value: beneficiaryName },
          { field: 'Beneficiary Phone Number', value: phone },
          { field: 'Gift Card Message', value: message },
          { field: 'Amount', value: formatPrice(amount) },
          { field: 'Coins Used', value: coinUsed },
          { field: 'Discount', value: formatPrice(discount) }
        ]} />
    </div>
    // </FullPageLayout>
  )
}

export default ReviewAndConfirmGiftCard