import Text from 'Components/Text'
import React from 'react'
import styles from './partials.module.scss'
import { Button } from 'Components'
import dayjs from 'dayjs'
import { formatPrice } from 'helpers'
import assets from 'Assets'

const InvoiceCard = ({
    onClick = () => null,
    onBack=()=>null,
    datetime, list = [
        { field: "", value: "" }
    ],
    backText = 'Back', style,
    rightText='Purchase (16,000 Coins)',
    total=0
}) => {

    return (
        <div className={styles.invoiceBody} style={style}>
            <div className={`${styles.invoiceHeader}`}>
                <Text weight={600} size={20}>INVOICE</Text>
                <div className=' ' >
                    <Text display={'block'} align={'right'} weight={600} size={16}>{dayjs(datetime).format('DD MMM YYYY')}</Text>
                    <Text display={'block'} align={'right'} weight={600} size={16}>{dayjs(datetime).format('hh:mm a')}</Text>
                </div>
            </div>
            <div className={styles.invoiceMiddle}>
                {
                    list.map(item => <div className={styles.invoiceMiddleItem}>
                        <p>{item.field}</p>
                        <p>{item.value}</p>
                    </div>)
                }

            </div>
            <div className={styles.invoiceFooter}>
                <Text weight={600} size={20}>TOTAL</Text>
                <Text weight={600} size={20}>{formatPrice(total)} </Text>


            </div>

            <div className='flex gap-2'>
                {
                    typeof onBack === 'function' && <Button width={'100%'} bgcolor={assets.colors.primary_light2} color={assets.colors.primary} className={'mt-4'} r={20} onClick={onBack}>
                        {backText}
                    </Button>
                }
                <Button width={'100%'} className={'mt-4'} r={20} onClick={onClick}>{rightText}</Button>
            </div>
        </div>
    )
}

export default InvoiceCard