

import { Alert } from '@mui/material'
import assets from 'Assets'
import { Input, RAForm } from 'Components'
import FullPageLayout from 'Components/layout/FullPageLayout'
import RAStepper from 'Components/Stepper'
import { formatPrice } from 'helpers'
import BlueScreen from 'Partials/BlueScreen'
import InvoiceCard from 'Partials/InvoiceCard'
import SelectPaymentMethod from 'Partials/SelectPaymentMethod'
// import Text from 'Components/Text'
import UseCoins from 'Partials/UseCoins'
import React, { useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
// import { useNavigate, useParams } from 'react-router-dom'
import { openModal } from 'Store/slices/modalSlice'

const Screens = Object.freeze({ "FORM": 0, "INVOICE": 1, "SUCCESS": 2, "FAILED": 3 });


const Movies = () => {
    // const { type = "Local" } = useParams()
    // const navigate = useNavigate()
    const [currScreen, setCurrScreen] = useState(Screens.FORM)
    // console.log({type})
    const dispatch = useDispatch()
    // useEffect(() => {
    //     const allowed_types = ['', 'Local', 'International']
    //     if (!allowed_types.map(allowed_type => allowed_type.toLowerCase()).includes(type.toLowerCase())) navigate('*')
    // }, [type, navigate])
    const onPurchase = () => {
        dispatch(openModal({ title: '', component: <SelectPaymentMethod onComplete={() => setCurrScreen(Screens.SUCCESS)} onError={() => setCurrScreen(Screens.FAILED)} /> }))
    }

    const invoicelist = useMemo(() => {
        return [
            { field: 'Network Provider', value:'MTN' },
            { field: 'Packages', value:'Monthly Roko Premium | NGN4000' },
            { field: 'Beneficiary Name', value:'Frank Ozorkor' },
            { field: 'Beneficiary Phone ', value:'55 555 5555' },
            { field: 'Amount', value:formatPrice(2000)},
            { field: 'Coins Used', value:'15,000' },
            { field: 'Discount', value:formatPrice(0.00) },
        ]
    }, [])
    const submit = () => {
        setCurrScreen(Screens.INVOICE)
    }
    return (
        <>
            <BlueScreen
                title='Purchase Successful!'
                bodyText='You have successfully bought ₦2000 airtime from MTN for John Doe. Thank you for your purchase!'
                onClickFillBtn={console.log}
                show={currScreen === Screens.SUCCESS}
                icon={assets.images.success}
                onDismiss={() => { setCurrScreen(Screens.FORM) }}
            />
            <BlueScreen
                title='Transaction was not successful. Please try again '
                onClickFillBtn={() => {
                    setCurrScreen(Screens.FORM)
                }}
                fillBtnText='Try again'
                show={currScreen === Screens.FAILED}
                icon={assets.images.failiure}
            />
            <FullPageLayout headerText={`Movies`} className='w-full' >


                <div className='w-[510px] !mt-[20px] mx-auto'>
                    <RAStepper noLabel steps={[0,1,2]} activeStep={currScreen} />
                </div>

                <div style={currScreen === Screens.INVOICE ? { marginTop: 50 } : { visibility: 'hidden', height: 0 }} className='h-[100%] !'>

                    <InvoiceCard
                        onBack={() => setCurrScreen(Screens.FORM)}
                        onClick={onPurchase} list={invoicelist}

                    />
                </div>
                <RAForm
                    className={'mx-auto max-w-[400px] '}
                    inputsContainer='flex flex-col gap-[25px]'
                    btnText={'Proceed to checkout'}
                    btnClass={'!rounded-[20px] mt-[60px] w-full'}
                    onSubmit={submit}
                    style={currScreen === Screens.FORM ? {} : { visibility: 'hidden', height: 0 }}
                >

                    <Alert variant='standard' severity='info' className='p-0' >
                        Info message
                    </Alert>

                    {/* <Input label={'Amount (₦)'} type='number' placeholder={400} /> */}
                    <Input label={'Network Provider'} type='select' placeholder='Select network provider' />
                    <Input label={'Package'} type='select' placeholder='Select package' />
                    <Input label={'Recipient Phone Number'} type='phone' />
                    <Input label={'Beneficiary Name'} />
                    <UseCoins />

                </RAForm>



            </FullPageLayout></>
    )
}

export default Movies