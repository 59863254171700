import { createSlice } from '@reduxjs/toolkit';

export const loadingScreenSlice = createSlice({
    name: 'loadingScreen',
    initialState:
    {
        message: "",
        isOpen: false,

    }
    ,
    reducers: {
        openLoadingScreen: (state, { payload }) => {
            state.message = payload?.message
            state.isOpen = true

        },
        closeLoadingScreen: (state, payload) => {
            state.message = ''
            state.isOpen = false
        }

    }
});

// this is for dispatch
export const { openLoadingScreen, closeLoadingScreen } = loadingScreenSlice.actions;

// this is for configureStore
export default loadingScreenSlice.reducer;
