import React from 'react'
import styles from './authlayout.module.scss'
import Assets from 'Assets'

const AuthLayout = ({ children }) => {
    return (
        <div className={styles.container}>
            <div className={styles.leftContainer}>
                <div className={styles.childerContainer}>
            <img src={Assets.images.logo} width={"228.44px"} alt='logo' height={"32px"} className='lg:mt-[50px] sm:mt-[20px]  !relative top-0 ' />
            <div className={styles.space}></div>
                    {children}
                </div>
            </div>
            <div className={styles.rightContainer}>

            </div>
        </div>
    )
}

export default AuthLayout