
import { Alert } from '@mui/material'
import Text from 'Components/Text'
import { useNetworkStatus } from 'hooks'
import React from 'react'

function HomeMessageBox() {
    const { isOnline } = useNetworkStatus()
    
    return (
        // <div  className='px-24 py-4 rounded'>HomeMessageBox</div>

        isOnline ? <Alert variant='filled' severity="info" className='font-bold'>
            < Text size={16} weight={600} > 100 COINS = NGN 1.00</Text >

        </Alert > : <Alert variant='filled' severity="error" className='font-bold'>
            < Text size={16} weight={600} > No Internet Connection</Text >
        </Alert >

    )
}

export default HomeMessageBox