

import { Alert } from '@mui/material'
import assets from 'Assets'
import { Input, RAForm } from 'Components'
import FullPageLayout from 'Components/layout/FullPageLayout'
import RAStepper from 'Components/Stepper'
import { formatPrice } from 'helpers'
import { useShopItems } from 'hooks/useShopItems'
import BlueScreen from 'Partials/BlueScreen'
import InvoiceCard from 'Partials/InvoiceCard'
// import SelectPaymentMethod from 'Partials/SelectPaymentMethod'
import UseCoins from 'Partials/UseCoins'
import React, { useEffect, useState } from 'react'
// import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
// import { openModal } from 'Store/slices/modalSlice'
import { calculateCoins, purchaseShopProduct, shopTransactionHash } from 'helpers/shop'
import { useWalletInfo } from 'hooks'

const Screens = Object.freeze({ "FORM": 0, "INVOICE": 1, "SUCCESS": 2, "FAILED": 3 });

const BuyAirtime = () => {
    const { type = "Local" } = useParams()
    const navigate = useNavigate()
    const [currScreen, setCurrScreen] = useState(Screens.FORM)
    const [payload, setPayload] = useState({})
    const { walletInfo } = useWalletInfo()
    useEffect(() => {
        const allowed_types = ['', 'Local', 'International']
        if (!allowed_types.map(allowed_type => allowed_type.toLowerCase()).includes(type.toLowerCase())) navigate('*')
    }, [type, navigate])
    const [invoicelist, setInvoiceList] = useState([])
    const product = useShopItems('airtime')
    const submit = async (e) => {
        const product = "local_airtime"
        try {
            const hash = shopTransactionHash({ product, amount: payload?.amount, payable: payload?.payable })
            await purchaseShopProduct({
                product,
                phone: payload?.phone,
                payable: payload?.payable,
                amount: payload?.amount,
                package_id: payload?.package_id,
                network: payload?.package_id,
                beneficiaryName: payload?.beneficiaryName,
                hash
            })
            setCurrScreen(Screens.SUCCESS)
        } catch (error) {
            console.log(error)
            setCurrScreen(Screens.FAILED)
        }
    }
    const onPurchase = () => {
        submit()
        // dispatch(openModal({ title: '', component: <SelectPaymentMethod price={2000} onComplete={submit} onError={() => setCurrScreen(Screens.FAILED)} /> }))
    }

    const previewInvoice = async (e) => {
        const coins = calculateCoins({ userCoins: walletInfo.reward.coins, amount: e?.amount, useCoins: e?.usecoins })
        const network = product.items.find(item => item?.id === parseInt(e?.package_id))?.alias
        setPayload({
            phone: e?.phone,
            payable: coins.payable,
            amount: e?.amount,
            package_id: e?.package_id,
            network: e?.package_id,
            beneficiaryName: e?.beneficiaryName,
            coins:coins.coinsUsed,
            usecoins: e?.usecoins ? true : false,
        })

        setInvoiceList([
            { field: 'Network Operator', value: network },
            { field: 'Beneficiary', value: e?.beneficiaryName },
            { field: 'Amount', value: formatPrice(e?.amount) },
            { field: 'Coins Used', value: coins.coinsUsed },
            { field: 'Discount', value: formatPrice(coins.coinsBalanceUsed) }
        ])
        setCurrScreen(Screens.INVOICE)
    }
    return (
        <>
            <BlueScreen
                title='Purchase Successful!'
                bodyText='You have successfully bought ₦2000 airtime from MTN for John Doe. Thank you for your purchase!'
                onClickFillBtn={console.log}
                show={currScreen === Screens.SUCCESS}
                icon={assets.images.success}
                onDismiss={() => { setCurrScreen(Screens.FORM) }}
            />
            <BlueScreen
                title='Transaction was not successful. Please try again '
                onClickFillBtn={() => {
                    setCurrScreen(Screens.FORM)
                }}
                fillBtnText='Try again'
                show={currScreen === Screens.FAILED}
                icon={assets.images.failiure}
            />
            <FullPageLayout headerText={`Buy ${type[0].toUpperCase() + type.substring(1) || "Local"} Airtime`} className='w-full' >


                <div className='w-[510px] !mt-[20px] mx-auto'>
                    <RAStepper noLabel steps={[0, 1, 2]} activeStep={currScreen} />
                </div>

                <div style={currScreen === Screens.INVOICE ? { marginTop: 50 } : { visibility: 'hidden', height: 0 }} className='h-[100%] !'>
                    <InvoiceCard
                        onBack={() => setCurrScreen(Screens.FORM)}
                        onClick={onPurchase} list={invoicelist}
                        total={payload?.payable}
                        rightText={`Purchase ${payload?.usecoins ? `(${payload?.coins} Coins)` : ''}`}
                    />
                </div>
                <RAForm
                    className={'mx-auto max-w-[400px] '}
                    inputsContainer='flex flex-col gap-[25px]'
                    btnText={'Proceed to checkout'}
                    btnClass={'!rounded-[20px] mt-[60px] w-full'} x
                    onSubmit={previewInvoice}
                    style={currScreen === Screens.FORM ? {} : { visibility: 'hidden', height: 0 }}
                >

                    <Alert variant='standard' severity='info' className='p-0' >
                        Info message
                    </Alert>

                    <Input name='amount' label={'Amount (₦)'} type='number' placeholder={400} required />
                    <Input name='package_id' label={'Network Provider'} type='select' required
                        options={product.items.map(item => ({ label: item?.alias, value: item?.id }))} placeholder='Select network provider' />
                    <Input name='phone' label={'Recipient Phone Number'} type='phone' required />
                    <Input name='beneficiaryName' label={'Beneficiary Name'} required />
                    <UseCoins />
                </RAForm>
            </FullPageLayout></>
    )
}

export default BuyAirtime