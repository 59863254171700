// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidenav_body__xeQHf {
  width: 250px;
  height: 100vh;
  background-color: rgb(249, 249, 255);
  border-right: 1px solid var(--Schemes-Outline-Variant, rgb(230, 232, 240));
}
.sidenav_body__xeQHf header {
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sidenav_body__xeQHf .sidenav_listbody__Lap1\\+ {
  height: 100%;
  width: 100%;
  padding: 20px;
  font-family: inter;
}
.sidenav_body__xeQHf .sidenav_listbody__Lap1\\+ .sidenav_upperList__hwz0P {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}
.sidenav_body__xeQHf .sidenav_listbody__Lap1\\+ .sidenav_upperList__hwz0P .sidenav_listitem__AtqQp {
  width: 85%;
  height: 44px;
  font-size: 18px;
  display: flex;
  gap: 20px;
  align-items: center;
  padding-left: 15px;
}
.sidenav_body__xeQHf .sidenav_listbody__Lap1\\+ .sidenav_upperList__hwz0P .sidenav_listitem__AtqQp .sidenav_icon__u4xe\\+ {
  font-size: 24px;
}
.sidenav_body__xeQHf .sidenav_listbody__Lap1\\+ .sidenav_upperList__hwz0P .sidenav_listitem__AtqQp:hover {
  background-color: rgb(195, 217, 255);
  border-radius: 12px;
}
.sidenav_body__xeQHf .sidenav_listbody__Lap1\\+ .sidenav_upperList__hwz0P .sidenav_selected__QJn4M {
  border-radius: 12px;
  background-color: rgb(195, 217, 255);
  color: rgb(0, 95, 175);
  font-weight: 600;
}`, "",{"version":3,"sources":["webpack://./src/Components/sidenav/sidenav.module.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,aAAA;EACA,oCAAA;EACA,0EAAA;AACJ;AACI;EACI,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AACR;AAEI;EACI,YAAA;EACA,WAAA;EAEA,aAAA;EACA,kBAAA;AADR;AAGQ;EACI,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,SAAA;AADZ;AAGY;EACI,UAAA;EACA,YAAA;EACA,eAAA;EACA,aAAA;EACA,SAAA;EACA,mBAAA;EAEA,kBAAA;AAFhB;AAIgB;EACI,eAAA;AAFpB;AAKgB;EACI,oCAAA;EACA,mBAAA;AAHpB;AAQY;EACI,mBAAA;EACA,oCAAA;EACA,sBAAA;EACA,gBAAA;AANhB","sourcesContent":[".body {\n    width: 250px;\n    height: 100vh;\n    background-color: rgba(249, 249, 255, 1);\n    border-right: 1px solid var(--Schemes-Outline-Variant, rgba(230, 232, 240, 1));\n\n    header {\n        height: 70px;\n        display: flex;\n        align-items: center;\n        justify-content: center;\n    }\n\n    .listbody {\n        height: 100%;\n        width: 100%;\n        // background-color: red;\n        padding: 20px;\n        font-family: inter;\n\n        .upperList {\n            display: flex;\n            flex-direction: column;\n            align-items: center;\n            gap: 15px;\n\n            .listitem {\n                width: 85%;\n                height: 44px;\n                font-size: 18px;\n                display: flex;\n                gap: 20px;\n                align-items: center;\n                // justify-content: center;\n                padding-left: 15px;\n\n                .icon {\n                    font-size: 24px;\n                }\n\n                &:hover {\n                    background-color: rgba(195, 217, 255, 1);\n                    border-radius: 12px;\n                }\n\n               \n            }\n            .selected {\n                border-radius: 12px;\n                background-color: rgba(195, 217, 255, 1);\n                color: rgba(0, 95, 175, 1);\n                font-weight: 600;\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body": `sidenav_body__xeQHf`,
	"listbody": `sidenav_listbody__Lap1+`,
	"upperList": `sidenav_upperList__hwz0P`,
	"listitem": `sidenav_listitem__AtqQp`,
	"icon": `sidenav_icon__u4xe+`,
	"selected": `sidenav_selected__QJn4M`
};
export default ___CSS_LOADER_EXPORT___;
