// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.giftcard_giftcardSelectImg__6OTqA {
  height: 210px;
  width: 30%;
  cursor: pointer;
  border-radius: 8px;
  padding: 4px;
  position: relative;
}
.giftcard_giftcardSelectImg__6OTqA .giftcard_selectedIcon__acpLA {
  position: absolute;
  right: 10px;
  top: 10px;
}
.giftcard_giftcardSelectImg__6OTqA img {
  height: 100%;
  width: 100%;
}
.giftcard_giftcardSelectImg__6OTqA:hover {
  border: 4px #1976D2 solid;
}

.giftcard_selectedBorder__bW64U {
  border: 4px #1976D2 solid;
}

.giftcard_giftcardReceived__nb2ml {
  width: 100%;
  padding: 20px;
  border-radius: 24px;
  position: relative;
}
.giftcard_giftcardReceived__nb2ml .giftcard_curvy__1Y0y0 {
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: inherit;
}
.giftcard_giftcardReceived__nb2ml .giftcard_oval__1-n67 {
  position: absolute;
  top: 0px;
  right: 0;
  z-index: 1;
  border-radius: inherit;
  height: 70px;
  width: 200px;
}
.giftcard_giftcardReceived__nb2ml .giftcard_boxImage__Jt6pY {
  height: 58px !important;
  width: 50px !important;
  display: block;
}
.giftcard_giftcardReceived__nb2ml .giftcard_boxImage__Jt6pY img {
  width: 100% !important;
  position: relative;
  z-index: 1000;
  height: 100% !important;
  object-fit: cover;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Home/GiftCard/giftcard.module.scss"],"names":[],"mappings":"AAAA;EAEI,aAAA;EACA,UAAA;EACA,eAAA;EACA,kBAAA;EAEA,YAAA;EAGA,kBAAA;AAHJ;AAMI;EACI,kBAAA;EACA,WAAA;EACA,SAAA;AAJR;AAOI;EACI,YAAA;EACA,WAAA;AALR;AAQI;EACI,yBAAA;AANR;;AAWA;EACI,yBAAA;AARJ;;AAWA;EACI,WAAA;EACA,aAAA;EAEA,mBAAA;EACA,kBAAA;AATJ;AAWI;EACI,kBAAA;EACA,SAAA;EACA,QAAA;EACA,sBAAA;AATR;AAYI;EACI,kBAAA;EACA,QAAA;EACA,QAAA;EACA,UAAA;EACA,sBAAA;EACA,YAAA;EACA,YAAA;AAVR;AAaI;EACI,uBAAA;EACA,sBAAA;EAEA,cAAA;AAZR;AAaQ;EACI,sBAAA;EACA,kBAAA;EACA,aAAA;EACA,uBAAA;EAAwB,iBAAA;AAVpC","sourcesContent":[".giftcardSelectImg {\n    // h-[210px] cursor-pointer rounded w-[30%]\n    height: 210px;\n    width: 30%;\n    cursor: pointer;\n    border-radius: 8px;\n\n    padding: 4px;\n\n\n    position: relative;\n\n\n    .selectedIcon {\n        position: absolute;\n        right: 10px;\n        top: 10px;\n    }\n\n    img {\n        height: 100%;\n        width: 100%;\n    }\n\n    &:hover {\n        border: 4px #1976D2 solid;\n    }\n\n}\n\n.selectedBorder {\n    border: 4px #1976D2 solid;\n}\n\n.giftcardReceived {\n    width: 100%;\n    padding: 20px;\n    // height: 100px;\n    border-radius: 24px;\n    position: relative;\n\n    .curvy {\n        position: absolute;\n        bottom: 0;\n        right: 0;\n        border-radius: inherit;\n    }\n\n    .oval {\n        position: absolute;\n        top: 0px;\n        right: 0;\n        z-index: 1;\n        border-radius: inherit;\n        height: 70px;\n        width:200px;\n        // object-fit: ;\n    }\n    .boxImage{\n        height: 58px !important;\n        width: 50px !important;\n        // border: 1px red solid;\n        display: block;\n        img{\n            width: 100% !important;\n            position: relative;\n            z-index: 1000;\n            height: 100% !important;object-fit: cover;\n\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"giftcardSelectImg": `giftcard_giftcardSelectImg__6OTqA`,
	"selectedIcon": `giftcard_selectedIcon__acpLA`,
	"selectedBorder": `giftcard_selectedBorder__bW64U`,
	"giftcardReceived": `giftcard_giftcardReceived__nb2ml`,
	"curvy": `giftcard_curvy__1Y0y0`,
	"oval": `giftcard_oval__1-n67`,
	"boxImage": `giftcard_boxImage__Jt6pY`
};
export default ___CSS_LOADER_EXPORT___;
