

import assets from 'Assets'
import { Input, RAForm } from 'Components'
import FullPageLayout from 'Components/layout/FullPageLayout'
import RAStepper from 'Components/Stepper'
import { formatPrice } from 'helpers'
import { calculateCoins, purchaseShopProduct, shopTransactionHash } from 'helpers/shop'
import { useWalletInfo } from 'hooks'
import { useShopItems } from 'hooks/useShopItems'
import { useShopProductItemsPackages } from 'hooks/useShopProductItemsPackages'
import BlueScreen from 'Partials/BlueScreen'
import InvoiceCard from 'Partials/InvoiceCard'
// import SelectPaymentMethod from 'Partials/SelectPaymentMethod'
// import Text from 'Components/Text'
import UseCoins from 'Partials/UseCoins'
import React, { useState } from 'react'
// import { useDispatch } from 'react-redux'
import { apiRequest } from 'Services'
// import { useNavigate, useParams } from 'react-router-dom'
// import { openModal } from 'Store/slices/modalSlice'

const Screens = Object.freeze({ "FORM": 0, "INVOICE": 1, "SUCCESS": 2, "FAILED": 3 });

const BuyCableTV = () => {
    const product = useShopItems('cable_tv')
    const [item_id, setItem_id] = useState(null)
    const cable_tv_plans = useShopProductItemsPackages({ product: 'cable_tv', item_id })
    const [currScreen, setCurrScreen] = useState(Screens.FORM)
    // const dispatch = useDispatch()
    const [payload, setPayload] = useState({})


    const { walletInfo } = useWalletInfo()
    const [invoicelist, setInvoiceList] = useState([])
    const submit = async () => {
        const product = 'cable_tv'
        try {
            const hash = shopTransactionHash({ product, payable: payload?.payable, amount: payload?.amount })
            await purchaseShopProduct({
                product,
                package_id: payload?.package_id,
                beneficiaryName: payload?.beneficiaryName,
                amount: payload?.amount,
                payable: payload?.payable,
                phone: payload?.phone,
                smart_no: payload?.smart_no,
                provider: payload?.provider,
                hash
            })
            setCurrScreen(Screens.SUCCESS)
        } catch (error) {
            setCurrScreen(Screens.FAILED)
        }
    }
    const onPurchase = () => {
        submit()
        // dispatch(openModal({ title: '', component: <SelectPaymentMethod onComplete={submit} onError={() => setCurrScreen(Screens.FAILED)} /> }))
    }
    const previewInvoice = async (params) => {

        try {
            const tvProvider = product.items.find(item => item?.id === parseInt(params?.provider))?.alias
            const package_ = cable_tv_plans.find(cable_tv_plan => cable_tv_plan?.id === parseInt(params?.package_id))
            const { data: cardDetails } = await apiRequest({ method: 'post', url: '/shop/products/cable-tv-check', payload: { "provider": params?.provider, "smart_no": params?.smart_no }, useLoadingScreen: true })
            const { customerName, customerNumber } = cardDetails

            const coins = calculateCoins({ userCoins: walletInfo.reward.coins, amount: package_?.price, useCoins: params?.usecoins })
            setInvoiceList([
                { field: 'Name', value: customerName },
                { field: 'Phone', value: customerNumber },
                // { field: 'Beneficiary name', value: params?.beneficiaryName },
                { field: 'TV Provider', value: tvProvider },
                { field: 'Packages', value: `${package_?.name}` },
                { field: 'Amount', value: formatPrice(package_?.price) },
                { field: 'Smart Card No.', value: params?.smart_no },
                { field: 'Coins Used', value: coins.coinsUsed },
                { field: 'Discount', value: formatPrice(coins.discount) }
            ])
            setPayload({
                provider: params?.provider,
                package_id: params?.package_id,
                amount: package_?.price,
                payable: coins.payable,
                smart_no: params?.smart_no,
                beneficiaryName: params?.beneficiaryName
            })
            setCurrScreen(Screens.INVOICE)
        } catch (error) {

        }
    }
    return (
        <>
            <BlueScreen
                title='Purchase Successful!'
                bodyText='You have successfully bought ₦2000 airtime from MTN for John Doe. Thank you for your purchase!'
                onClickFillBtn={console.log}
                show={currScreen === Screens.SUCCESS}
                icon={assets.images.success}
                onDismiss={() => { setCurrScreen(Screens.FORM) }}
            />
            <BlueScreen
                title='Transaction was not successful. Please try again '
                onClickFillBtn={() => {
                    setCurrScreen(Screens.FORM)
                }}
                fillBtnText='Try again'
                show={currScreen === Screens.FAILED}
                icon={assets.images.failiure}
            />
            <FullPageLayout headerText={`Buy Cable TV`} className='w-full' >


                <div className='w-[510px] !mt-[20px] mx-auto'>
                    <RAStepper noLabel steps={[0, 1, 2]} activeStep={currScreen} />
                </div>

                <div style={currScreen === Screens.INVOICE ? { marginTop: 50 } : { visibility: 'hidden', height: 0 }} className='h-[100%] !'>

                    <InvoiceCard
                        onBack={() => setCurrScreen(Screens.FORM)}
                        onClick={onPurchase} list={invoicelist}
                        rightText={`Purchase ${payload?.coins ? `(${payload?.coins?.toLocaleString()} Coins)` : ''}`}
                        total={payload?.payable}
                    />
                </div>
                <RAForm
                    className={'mx-auto max-w-[400px] '}
                    inputsContainer='flex flex-col gap-[25px]'
                    btnText={'Proceed to checkout'}
                    btnClass={'!rounded-[20px] mt-[60px] w-full'}
                    onSubmit={previewInvoice}
                    style={currScreen === Screens.FORM ? {} : { visibility: 'hidden', height: 0 }}
                >

                    {/* <Alert variant='standard' severity='info' className='p-0' >
                        Info message
                    </Alert> */}

                    {/* <Input label={'Amount (₦)'} type='number' placeholder={400} /> */}
                    <Input label={'TV Provider'} type='select'
                        name={'provider'} required
                        placeholder='Select TV provider' onChange={(e) => setItem_id(e.target.value)}
                        options={product.items.map(item => ({ label: item?.alias, value: item?.id }))} />
                    <Input label={'Package'} type='select' placeholder='Select package' name='package_id'
                        key={item_id} options={cable_tv_plans.map(plan => {
                            return {
                                value: plan?.id,
                                label: `${plan?.name} | ${formatPrice(plan?.price)}`
                            }
                        })}
                        required
                    />

                    <Input label={'Beneficiary name'} placeholder='John Doe' name='beneficiaryName' required />
                    <Input label={'Smart Card'} placeholder='Eg. 1234567890' name='smart_no' required />
                    <UseCoins />

                </RAForm>



            </FullPageLayout></>
    )
}

export default BuyCableTV