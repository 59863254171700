// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.clickaway_clickaway__jFdzB {
  position: fixed !important;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
}`, "",{"version":3,"sources":["webpack://./src/Components/clickaway/clickaway.module.scss"],"names":[],"mappings":"AACA;EACI,0BAAA;EACA,OAAA;EACA,MAAA;EACA,aAAA;EACA,YAAA;AAAJ","sourcesContent":["\n.clickaway{\n    position: fixed !important;\n    left: 0;\n    top: 0;\n    height: 100vh;\n    width: 100vw;\n    // z-index: 1;\n    // background-color: green;\n    // display: none;\n}\n// .shadow{\n//     // background-color: rgba($color: #000000, $alpha: 0.4);\n// }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"clickaway": `clickaway_clickaway__jFdzB`
};
export default ___CSS_LOADER_EXPORT___;
