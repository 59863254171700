import { Button, Text } from 'Components'
import dayjs from 'dayjs'
import { formatPrice } from 'helpers'
import UseCoins from 'Partials/UseCoins'
import UseGiftCards from 'Partials/UseGiftCards'
import React from 'react'

const ApprovalDetails = () => {
    return (
        <div className='flex flex-col gap-[30px] '>
            <div className='flex flex-col w-full bg-[#C3D9FF] items-center justify-center rounded-[12px] text-[#2F486A] h-[120px] p-5'>
                <Text size={14} weight={600}>
                    Total Amount
                </Text>
                <Text size={24} weight={600}>
                    {formatPrice(2000, 'NGN ')}
                </Text>
            </div>

            <div className='flex flex-col gap-[40px]'>
                <div className=' flex justify-between'>
                    <Text size={16}>Shop</Text>
                    <Text size={16}>Mr Price</Text>
                </div>
                <div className=' flex justify-between'>
                    <Text size={16}>Branch</Text>
                    <Text size={16}>Branch name will here and…</Text>
                </div>
                <div className=' flex justify-between'>
                    <Text size={16}>Transaction ID</Text>
                    <Text size={16}>ID#839843984</Text>
                </div>
                <div className=' flex justify-between'>
                    <Text size={16}>Teller ID</Text>
                    <Text size={16}>ID#839843984</Text>
                </div>
                <div className=' flex justify-between'>
                    <Text size={16}>Date</Text>
                    <Text size={16}>{dayjs().format("DD MMM YYYY")}</Text>
                </div>
            </div>

            <UseCoins></UseCoins>
            <UseGiftCards />
            <div className='flex gap-4 w-full mb-[40px]'>
            <Button width={'50%'} r={100} bgcolor='#FFDAD6' color={'#BA1A1A'}>Reject</Button>
            <Button width={'50%'} r={100}>Approve </Button>
            </div>
        </div>
    )
}

export default ApprovalDetails