import  { useEffect, useState } from 'react'
import { apiRequest } from 'Services'

const useMe = () => {
    const [me, setMe] = useState({})
    useEffect(() => {
        const getMe = async () => {
            try {
                const { data } = await apiRequest({ url: '/user', version: 'v1' })
                setMe(data)
            } catch (error) {

            }
        }
        getMe()
    }, [])
    return {
        me
    }
}

export default useMe