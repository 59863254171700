import React from 'react';
import { Input, RAForm, Text } from 'Components';
import UseCoins from 'Partials/UseCoins';
// import { useNavigate } from 'react-router-dom'

const AddGiftCardForMySelf = ({ onProceed = () => null }) => {
    const onSubmit = (params) => {
        onProceed(params)
    }
    return (
        <div className='w-full'>

            <Text display={'block'} align={'center'} size={20} className={'w-[480px] !mt-[50px] mx-auto'} weight={600}>Create a personalized gift card by adding a message and selecting the amount.</Text>

            <RAForm btnText={'Proceed to checkout'} onSubmit={onSubmit} btnClass={'w-full mt-[50px] !rounded-[100px]'} className={'w-full mt-[50px]'}>
                <div className='flex flex-col gap-5'>
                    <Input label={'Amount'} type='number' name='amount' required />
                    <Input label={'Gift Card Message'} placeholder='Leave a message for beneficiary…' name='message' required/>
                    <UseCoins />
                </div>
            </RAForm>
        </div>
    )
}

export default AddGiftCardForMySelf