import React from 'react'

const DoubleTab = ({
    tabs = [{ name: 'Btn 1', onClick: () => null, key: '0' }, { name: 'Btn 2', onClick: () => null, key: '1' }], active='0'
}) => {
    const activeClass = 'bg-[white] font-bold'
    return (
        <div className={'w-[100%] rounded rounded-[8px] flex gap-1 p-1 bg-[#ECEDF6]'}>
            {
                tabs.map(tab => <div onClick={tab.onClick}
                 className={` ${active === tab.key ? activeClass : ''} h-[38px] cursor-pointer rounded text-center flex items-center justify-center w-[50%]`}>
                    {tab.name}
                </div>)
            }
        </div>
    )
}

export default DoubleTab