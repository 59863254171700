import React from 'react'
import { Input, RAForm } from 'Components'
// import { getResponseErrors } from 'helpers/getResponseError'
import { useDispatch } from 'react-redux'
import { closeModal } from 'Store/slices/modalSlice'
import { toast } from 'react-toastify'
import { logout } from 'Store/slices/auth'

const DeleteAccount = () => {
    const dispatch = useDispatch()
    return (
        <RAForm btnText={'Delete'} btnClass={'w-full mt-[30px]'} url={'/auth/delete-account'} onError={(err) => {
            const y = (err?.response?.data?.error)
            toast.error(y)
        }}
            onSuccess={() => {
                toast.success('Successful')
                dispatch(closeModal())
                dispatch(logout())

            }}>

            <div className='flex flex-col gap-[10px] w-[100%]'>
                <Input placeholder={'Password'} required type='password' name='current' />
            </div>

        </RAForm>
    )
}

export default DeleteAccount