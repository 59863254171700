import assets from 'Assets'
import { Text } from 'Components'
import React from 'react'

const QRPopup = () => {
    return (
        <div className='h-[350px] flex flex-col items-center'>

            <img src={assets.images.qr} alt='qr' className='mx-auto w-[70%]' />

            <Text>Scan QR code with your device to proceed</Text>
        </div>
    )
}

export default QRPopup