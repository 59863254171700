import CryptoJS from 'crypto-js'
import { getResponseErrors } from './getResponseError'
import { toast } from 'react-toastify'
const { apiRequest } = require("Services")
const getTransactionHash = async ({ amount, package_ = null, value = 0, phone, provider, payable, payment_mode, currency = 'NGN', product = 'coins' }) => {
    const payload = {
        //    "beneficiary":{
        //       "name":"My Dstv",
        //       "phone":"+2348170533018",
        //       "smart_no":"7043368243"
        //    },
        "params": {
            amount,
            "package": package_,
            payable: amount + value,
            value,
            phone
            //   "provider":11,
            //   "smart_no":"7043368243"
        },
        "payment_details": {
            amount,
            //   "beneficiary":{
            //      "name":"My Test"
            //   },
            currency,
            "payment_mode": 3,
            phone,
            provider
        },
        product
    }
    // b35ce22f5562bd71fcb685cbdf333522f6f54f182d6e7f8ae253db8c2b7a50e04315ad4d98483529bf5a18749f01710591f2ef016a6b146fd97e0c1481a59838

    try {
        const { data } = await apiRequest({ payload, url: '/shop/get-hash', method: 'post' })
        return data
    } catch (error) {

    }
}
function sha1(data) {
    const hash = CryptoJS.SHA1(data).toString()
    return hash
}
function sha512(data) {
    const hash = CryptoJS.SHA512(data).toString()
    return hash
}

const salt = 'reachafrika'
const agent = 'reachafrika.com'
const shopTransactionHash = ({ product, amount, payable, coin }) => {
    try {
        if (!amount || !product || !payable) throw new Error('Please provide amount, payable and product!')
        const finalHash =
            sha1(salt)
            + sha1(product)
            + sha1(parseInt(amount?.replaceAll(",", '')).toFixed(2))
            + sha1(parseFloat(payable).toFixed(2))
            + sha1(agent)
            + sha1(salt)
        const totalHash = (sha512(finalHash))
        return totalHash
    } catch (error) {
        throw error
    }
}

const purchaseShopProduct = async ({
    product, beneficiaryName, phone, package_id, amount, payable,
    value = 0,
    network, discount = 0, hash, provider, smart_no, disco_id,
    meter_no, vendor_type, reference,
    product_id, type
}) => {

    const payload = {
        // SHop products only
        "beneficiary": {
            "name": beneficiaryName,
            "phone": phone // stven "+2349030255894"
        },
        // ./Shop products only
        "params": {
            // General
            "amount": parseFloat(amount).toFixed(2), // or price of package
            "payable": parseFloat(payable).toFixed(2), // amount after use coins for non nigerian user
            "phone": phone ? "+" + phone : "", // if payment is momo, it is required
            "package": package_id, // coins packackage_id

            // ./General
            // Coins only
            "value": value, // null if package == null, should be the coins amount of the package
            // ./Coins only
            // Shop products only
            "network": network,
            "discount": discount || parseFloat(amount - payable).toFixed(2),
            // ./Shop Products only

            //cable_tv
            provider,
            smart_no,

            //power
            disco_id,
            meter_no,
            vendor_type,
            reference,

            //education
            product_id,
            type,

        },
        product
    }
    try {
        // const totalHash = shopTransactionHash({ product, amount: e?.amount, payable: e?.amount })
        await apiRequest({ method: 'post', url: '/shop?lang=en', payload, headers: { hash }, useLoadingScreen: true })
        // setCurrScreen(Screens.INVOICE)
        return {
            status: 'success'
        }
    } catch (error) {
        console.log(error)
        if (error?.response?.status === 500) {
            toast.error('Internal server error.')
            throw new Error({
                status: 'failed', error: 'Internal server error.'
            })
        }
        getResponseErrors({ error, useToast: true })
        throw new Error({
            status: 'failed', error
        })
    } 
}
const calculateCoins = ({ userCoins, amount, useCoins = false }) => {

    if (!useCoins) {
        return {
            coinsBalanceUsed: 0,
            discount: 0,
            coinsUsed: 0,
            userCoins,
            payable: amount
        }
    }
    const coinsBalance = parseFloat(userCoins?.replaceAll(',', '')) / 100
    let coinsBalanceUsed = amount
    let payable = 0
    // let coinsUsed = amount * 100

    if (parseFloat(amount) > coinsBalance) {
        coinsBalanceUsed = coinsBalance
        payable = amount - coinsBalance
    }
    return {
        coinsBalanceUsed,
        discount: coinsBalanceUsed,
        coinsUsed: (coinsBalanceUsed * 100).toLocaleString(),
        userCoins,
        payable
    }
}
export { getTransactionHash, salt, sha1, sha512, shopTransactionHash, purchaseShopProduct, calculateCoins }