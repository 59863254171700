import { toast } from "react-toastify"

export const getResponseErrors = ({ error, useToast }) => {
    const list = error?.response?.data?.error
    const errors = Object.values(list || {}).flatMap(item => item)
    // console.log(list, errors)
    if (useToast) errors.forEach(err => {
        toast.error(err)
    })
    return {
        error: list, errors
    }
}