import FullPageLayout from 'Components/layout/FullPageLayout'
import React from 'react'
import Balances from '../components/Balances'
// import ButtonStack from 'Components/Button/ButtonStack'
import styles from './styles.module.scss'
import Text from 'Components/Text'
import assets from 'Assets'
import { FaPlay } from 'react-icons/fa'

import LinearProgress from '@mui/material/LinearProgress'
import { useDispatch } from 'react-redux'
import { openModal } from 'Store/slices/modalSlice'
import { Button } from 'Components'
import { useFetch } from 'hooks'
// import { Outlet } from 'react-router-dom'

const Freebies = () => {
    const dispatch = useDispatch()
    // const [activeBtn, setActiveBtns] = useState('all')
    // const btns = useMemo(() =>
    //     [
    //         { text: 'All', onClick: setActiveBtns, value: "all" },
    //         { text: 'CategoryName 1', onClick: setActiveBtns, value: "1" },
    //         { text: 'CategoryName 2', onClick: setActiveBtns, value: "2" },
    //         { text: 'CategoryName 3', onClick: setActiveBtns, value: "3" },
    //         { text: 'CategoryName 4', onClick: setActiveBtns, value: "4" },
        // ], [])

    const { data: freebies } = useFetch({ url: '/shop/freebies', version: 'v1' })
    const ConfirmModal = () => {
        return (
            <div className='flex flex-col gap-3 items-center justify-center text-center'>

                <img src={assets.images.buymorecoins} alt="" />
                <Text size={24} weight={600}>
                    Confirm Purchase
                </Text>
                <Text align={'center'} weight={400} className={'w-[400px]'} size={16}>
                    Are you sure you want to purchase 2.5 GB (Direct) - Monthly | 30 days (16000 coins)
                </Text>
                <Button width={'100%'} r={20}>
                    Purchase (16,000 Coins)
                </Button>

            </div>
        )

    }
    return (<>


        <FullPageLayout className='' headerText={"Get Freebies"} backPath='/'>
            {/* <div className='col-span-9 '>
                <ButtonStack btns={btns} active={activeBtn} />
            </div> */}
            <div className={styles.container}>
                <div className='flex flex-wrap gap-2 !w-[65%]  justify-evenly'>
                    {
                        freebies?.map(freebie => {
                            return <div key={freebie?.id} className={`${styles.mobiledataCard} flex flex-col justify-between`} >
                                <div>
                                    <Text weight={700} size={12} className={'uppercase'}>{freebie?.product_item}</Text>
                                </div>
                                <div className='flex justify-between items-center'>
                                    <Text size={16} weight={600} className={'w-[150px]'}>
                                        {/* 2.5GB (Direct) - Monthly | 30 days */}
                                        {freebie?.description}
                                        {/* {freebie?.name} */}
                                    </Text>
                                    {freebie?.can_claim && <FaPlay className='cursor-pointer' color={assets.colors.primary} onClick={() => dispatch(openModal({ title: "", component: <ConfirmModal /> }))} />}
                                    <div className='flex gap-2'>
                                        <img src={assets.images.coinstack} alt="" width={20} height={20} />  <Text weight={600}>{freebie?.amount_coins}</Text>
                                    </div>
                                </div>
                                <div className='w-[100%]'>
                                    <div className='flex w-[100%] justify-between'>
                                        <Text weight={600} size={14}>{!freebie?.can_claim && "LOCKED"}</Text>
                                        <Text weight={600} size={14}>{freebie?.active_percentage}/{freebie?.total_percentage}%</Text>
                                    </div>

                                    <LinearProgress variant="determinate" value={freebie?.active_percentage} sx={{ Radius: '12px', height: '8px', mt: 1 }} />

                                </div>
                            </div>
                        })
                    }
                </div>
                <div className='!w-[35%]'>
                    <Balances />
                </div>
            </div>
        </FullPageLayout>
    </>
    )
}

export default Freebies