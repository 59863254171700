// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.emptystate_emptystate__Mao90 {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.emptystate_emptystate__Mao90 .emptystate_center__bk8Yx {
  padding-top: 96px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.emptystate_emptystate__Mao90 .emptystate_center__bk8Yx .emptystate_iconCircle__ePnU4 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  color: #FF0030;
  gap: 10px;
  width: 96px;
  height: 96px;
  background: #EFEFEF;
  border-radius: 188px;
  margin-bottom: 20px;
}
.emptystate_emptystate__Mao90 .emptystate_center__bk8Yx .emptystate_children__ulAQ5 {
  margin-top: 16px;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/Components/EmptyState/emptystate.module.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,WAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AACJ;AACI;EACI,iBAAA;EACA,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,sBAAA;AACR;AACQ;EACI,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,uBAAA;EACA,eAAA;EACA,cAAA;EAEA,SAAA;EACA,WAAA;EACA,YAAA;EACA,mBAAA;EACA,oBAAA;EACA,mBAAA;AAAZ;AAGQ;EACI,gBAAA;EAGA,uBAAA;AAHZ","sourcesContent":[".emptystate {\n    height: 100%;\n    width: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n\n    .center {\n        padding-top: 96px;\n        text-align: center;\n        display: flex;\n        align-items: center;\n        justify-content: center;\n        flex-direction: column;\n\n        .iconCircle {\n            display: flex;\n            flex-direction: row;\n            align-items: center;\n            justify-content: center;\n            font-size: 30px;\n            color: #FF0030;\n            // padding: 16px;\n            gap: 10px;\n            width: 96px;\n            height: 96px;\n            background: #EFEFEF;\n            border-radius: 188px;\n            margin-bottom: 20px;\n\n        }\n        .children {\n            margin-top: 16px; \n            // width: 100%; \n            // display: flex;\n            justify-content: center;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"emptystate": `emptystate_emptystate__Mao90`,
	"center": `emptystate_center__bk8Yx`,
	"iconCircle": `emptystate_iconCircle__ePnU4`,
	"children": `emptystate_children__ulAQ5`
};
export default ___CSS_LOADER_EXPORT___;
