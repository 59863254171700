import { Stack } from '@mui/material'
import assets from 'Assets'
import { Button } from 'Components'
import React from 'react'

const ButtonStack = ({ btns = [1, 2, 3], active = 2, className='' }) => {
    return (
        <Stack className={`${className} w-full`} direction="row" spacing={2}>
            {
                btns.map(({ text, value, onClick = () => null }, i) => <Button
                    bgcolor={active === value ? assets.colors.primary_light : assets.colors.grey}
                    color={'black'} className={'font-bold w-full px-5'} r={20} onClick={()=>onClick(value)}>
                    {text}
                </Button>)
            }
        </Stack>
    )
}

export default ButtonStack