import { Button } from 'Components'
// import { firebaseFunctions } from 'Services'

import React, { useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify';
import { apiRequest } from 'Services';

const RAForm = ({
    btnText,
    btnClass,
    className, children, url, method = 'post',
    extraFields = {}, successMessage,
    validationSchema, noToken,
    inputsContainer,
    onSuccess = () => null,
    onSubmit = () => null,
    onError = () => null,
    modifyPayload = () => null, btnDisabled = false, style, version = 'v2',
    onInput = () => null

}) => {
    const formRef = useRef(null)
    // console.log(formRef.current)
    const [loading, setLoading] = useState(false)
    const [formIsDirty, setFormIsDirty] = useState(false)
    const callApi = async (payload) => {

        let params = {}
        if (method === 'get' && payload) params = { ...payload }
        setLoading(true)
        try {
            let final_payload = modifyPayload(payload) || payload
            // const res = ''
            const res = await apiRequest({ method, url, payload: final_payload, params, noToken, version })
            // console.log(res, 'ddd')
            // if (successMessage) { toast.success(successMessage) } else { toast.success(res?.message || 'Successful') }
            onSuccess(res, payload)
        }
        catch (error) {
            console.log(error)
            onError(error)
            // throw error
        } finally {
            setLoading(false)
        }
    }
    // const []
    const findValidateInputs = () => {
        const inputElements = (formRef.current?.getElementsByTagName('input'))
        // console.log(typeof inputElements)
        const formState = {}
        Array.from(inputElements).forEach(element => {
            if (element.name) {
                // console.log(element.name, element.required, element.value, element.type, element.checked)
                // if (element?.required && element.value)
                // console.log(element.value !== "" && element.value !== null && element.value !== undefined)
                formState[element.name] = {
                    value: element.type === 'checkbox' ?  element.checked : element.value,
                    required: element.required,
                    isValid: element.required ? element.value !== "" && element.value !== null && element.value !== undefined : true
                }
            }
        });

        const isValid = Object.values(formState).every(field => field.required ? field.isValid : true)
        // console.log(formState, isValid)
        setFormIsDirty(!isValid)
        return {
            isValid,
            formState
        }
    }

    useEffect(() => {
        findValidateInputs()
    }, [])
    const onFormChange = (e) => {
        // console.log(e, '---')
        findValidateInputs()
        if (e.type === 'input') onInput({ [e.target.name]: e.target.value })
    }
    const submit = async (event) => {
        event.preventDefault()
        findValidateInputs()
        const formData = new FormData(event.target)
        let formValues = {}
        for (let [key, value] of formData.entries()) {
            formValues[key] = value
            if (String(value).includes('select-array-list')) {
                formValues[key] = value?.replace('select-array-list', '').split("-sal-,-sal-")
            }
        }
        formValues = { ...formValues, ...extraFields }
        onSubmit(formValues)
        if (validationSchema) {
            try {
                await validationSchema.validate(formValues, { abortEarly: false })
                if (url) callApi(formValues)
            } catch (error) {
                const errs = (error.errors)
                errs.forEach(err => {
                    toast.error(err)
                });
            }
        } else {
            if (url) callApi(formValues)
        }
    }

    return (
        <form onSubmit={submit} className={className} ref={formRef} style={style} onInvalid={console.log} onMouseMove={onFormChange} onInput={onFormChange}>
            <div className={`${inputsContainer}`}>
                {children}
            </div>
            {btnText
                ?
                <>
                    <Button className={`${btnClass} font-bold p-3 `} disabled={btnDisabled || formIsDirty} type='submit' loading={loading}>{btnText}</Button>
                </>

                : <button hidden type='submit'></button>
            }
        </form>
    )
}

export { RAForm }